<template>
  <div>
    <template v-for="social in accounts">
      <b-button
        :variant="prefix ? 'btn-' + social.toLowerCase() : 'outline-primary'"
        :size="prefix ? 'sm' : 'lg'"
        @click="connectSocial(social)"
        :class="[`${prefix ? 'btn-' + social.toLowerCase() : 'py-4 mb-3'}`]"
        :disabled="loading"
        :block="true"
      >
        <b-spinner v-if="loadingStatus(social)" class="mr-1" small />
        <svg-icon v-else :name="social.toLowerCase()" size="md" class="mr-2" />
        {{ prefix }} {{ social.toUpperCase() }}
      </b-button>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    prefix: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'social',
    },
    accounts: {
      type: Array,
      default: () => [],
    }
  },

  data: function() {
    return {
      apiUrl: process.env.VUE_APP_API_ROOT,

      loading: false,
      currentlyLoading: '',
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  methods: {

    hasExceeded(type) {
      if (!['blog', 'social'].includes(type)) {
        return false;
      }

      const limitKey = type === 'blog' ? 'blogs' : 'socialAccounts';
      const limit = parseInt(this.workspace.limits[limitKey] || '') || Infinity;
      const usedCount = parseInt(this.workspace.limitsUsage[limitKey] || '');

      if (usedCount >= limit) {
        const accountType = type === 'blog' ? 'blog accounts' : 'social accounts';
        this.$notify({
          group: 'main',
          title: 'Please Upgrade',
          type: 'native-error',
          text: `You have reached the maximum number of ${accountType} allowed in your current plan. Please upgrade to create more ${accountType}.`,
        });
        return true;
      }

      return false;
    },
    
    connectSocial(platform) {
      this.currentlyLoading = platform;
      this.loading = true;

      if (!this.hasExceeded(this.type)) {

        if (platform === 'Instagram' && !this.$route.query.ig) {
          this.$router.push({ query: { ig: 'instagram' } });
          setTimeout(() => this.$router.go(0), 50); // Reload the current route after a short delay
        } else if (['Pinterest', 'Wordpress', 'Medium', 'Spotify'].includes(platform)) {
          this.$root.$emit('bv::show::modal', `${platform}Modal`);
        } else {
          const connectEndpoint = `ConnectWith${platform}`;
          this.redirectToSocialConnect(connectEndpoint, platform === 'Tumblr');
        }
      
       this.currentlyLoading = null;
        this.loading = false;
      }
    },

    loadingStatus(platform) {
      return this.currentlyLoading === platform;
    },

    redirectToSocialConnect(connectEndpoint, hasHref = false) {
      const location = hasHref ? window.location.href : window.location;

      window.location = `${this.apiUrl}/account/${connectEndpoint}?workspace=${
        this.workspace.slug
      }&next=${encodeURIComponent(location)}`;

    },
  },
};
</script>
