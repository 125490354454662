<template>
  <transition name="report-slide">
    <b-col cols="2" class="report-sidebar shadow-sm">
      <div class="report-sidebar-wrapper">
        <router-link class="link" :to="{ name: 'workspaces' }" active-class="active">
          <svg-icon name="dashboard-alt" />
          <span class="name">Homepage</span>
        </router-link>
        <router-link class="link" :to="{ name: 'dashboard' }" active-class="active">
          <svg-icon name="dashboard" />
          <span class="name">Dashboard</span>
        </router-link>
        <div class="title">
          Analytics Hubs
        </div>
        <router-link class="link" :to="{ name: 'report.overview' }" active-class="active">
          <svg-icon name="dashboard-outline" size="md" />
          <span class="name">Overview</span>
        </router-link>
        <router-link class="link" :to="{ name: 'report.facebook' }" active-class="active">
          <svg-icon name="facebook-outline" size="md" />
          <span class="name">Facebook</span>
        </router-link>
        <router-link class="link" :to="{ name: 'report.twitter' }" active-class="active">
          <svg-icon name="twitter-outline" size="md" />
          <span class="name">Twitter</span>
        </router-link>
        <router-link class="link disabled" :to="{ name: 'report.instagram' }" active-class="active">
          <svg-icon name="instagram-outline" size="md" />
          <span class="name">Instagram</span>
        </router-link>
        <router-link class="link disabled" :to="{ name: 'report.linkedin' }" active-class="active">
          <svg-icon name="linkedin-outline" size="md" />
          <span class="name">LinkedIn</span>
        </router-link>
        <div class="title">
          Manage Reports
        </div>
        <router-link class="link" :to="{ name: 'report.schedule' }" active-class="active">
          <svg-icon name="report-outline" size="md" />
          <span class="name">Scheduled Reports</span>
        </router-link>
        <router-link class="link" :to="{ name: 'report.download' }" active-class="active">
          <svg-icon name="download-circle-outline" size="md" />
          <span class="name">Download Reports</span>
        </router-link>
        <div class="trial-box">
          <!-- TODO: change url -->
          <a href="#" target="_blank" class="px-3 py-1 btn btn-sm btn-secondary rounded-pill">
            Help center
          </a>
        </div>
      </div>
    </b-col>
  </transition>
</template>

<script>
export default {
  name: 'ReportSidebar',
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.report-sidebar {
  position: sticky;
  top: $navbar-height;
  z-index: 100;
  min-height: calc(100vh - #{$navbar-height});
  padding: 0 15px;

  ::v-deep {
    &,
    & * {
      font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif !important;
    }

    & > .report-sidebar-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      background: $primary;
      color: #ffffff;
      border-radius: 10px;
      padding: 14px 6px;

      > * {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 20px;
        margin: 10px 0 0 0;
      }

      > .title {
        margin: 6px 0;
        position: relative;
        color: #52b788;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;

        &::before {
          content: '';
          display: inline-flex;
          width: 18px;
          height: 3px;
          border-radius: 1px;
          background-color: #52b788;
          margin: 0 20px 0 0;
        }
      }

      > .link {
        border-radius: 10px;
        border: none;
        outline: initial;
        background-color: initial;
        align-items: center;
        // justify-content: flex-end;
        text-decoration: initial;
        transition: all 0.3s ease-in-out;
        margin: 2px;
        padding: 6px 12px;

        > * {
          transition: all 0.3s ease-in-out;
        }

        > .icon {
          // width: 18px;
          // height: 18px;
          fill: #868686;
          margin: 0 auto 0 0;
        }

        > .name {
          width: calc(100% - 38px);
          font-size: 14px;
          text-transform: capitalize;
          font-weight: 400;
          color: #868686;
        }

        &:not(.disabled) {
          &.active,
          &:not(.active):hover {
            background-color: #2d6a4f;

            > .icon {
              fill: #ffffff;
            }

            > .name {
              color: #ffffff;
            }
          }
        }

        &.disabled {
          pointer-events: none;
        }
      }
    }
  }

  .trial-box {
    padding: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;

    margin-left: auto;
    margin-right: auto;

    h5 {
      font-weight: bold;
      color: $primary;
      font-size: 0.85rem;
    }

    .btn {
      align-self: center;
      font-weight: bold;
    }
  }
}

.report-slide-enter-active {
  animation: report-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.report-slide-leave-active {
  animation: report-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes report-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes report-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
