<template>
  <div v-if="errors.length" class="alert alert-danger alert-dismissible" role="alert">
    <button v-if="dismissible" type="button" class="close" aria-label="Close" @click="dismiss">
      <span aria-hidden="true">&times;</span>
    </button>

    <div v-if="message" v-html="message" />

    <ul>
      <li v-for="(error, i) in errors" :key="`msg-${i + 1}`" v-html="error" />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'alert-errors',

  props: {
    message: {
      type: String,
      default: 'There were some problems with your input.',
    },

    form: {
      type: Object,
      required: true,
    },

    dismissible: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    errors() {
      const errors = this.form.errors.all();
      const errorKeys = Object.keys(errors);
      const fields = this.form.data();

      return errorKeys.reduce((alertErrors, errorKey) => {
        if (!fields[errorKey]) {
          errors[errorKey].forEach(er => alertErrors.push(er));
        }

        return alertErrors;
      }, []);
    },
  },

  methods: {
    dismiss() {
      if (this.dismissible) {
        this.form.clear();
      }
    },
  },
};
</script>

<style>
.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: white;
  cursor: pointer;
}
</style>
