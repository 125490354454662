// state
export const state = {
  invokedMeme: null,
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_INVOKED_MEME(state, invokedMeme) {
    state.invokedMeme = invokedMeme;
  },
};

// actions
export const actions = {
  invokeMeme({ commit }, meme) {
    commit('UPDATE_INVOKED_MEME', meme);
  },
};
