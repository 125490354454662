<template>
  <svg :width="width" :height="height" class="icon" viewBox="0 0 41.999 41.999">
    <path
      d="M36.068,20.176l-29-20C6.761-0.035,6.363-0.057,6.035,0.114C5.706,0.287,5.5,0.627,5.5,0.999v40
  c0,0.372,0.206,0.713,0.535,0.886c0.146,0.076,0.306,0.114,0.465,0.114c0.199,0,0.397-0.06,0.568-0.177l29-20
  c0.271-0.187,0.432-0.494,0.432-0.823S36.338,20.363,36.068,20.176z"
    />
  </svg>
</template>

<script>
export default {
  name: 'play-video-icon',

  props: {
    width: {
      type: String,
      default: '22px',
    },
    height: {
      type: String,
      default: '18px',
    },
  },
};
</script>
