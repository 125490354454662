<template>
  <b-modal id="MediumPublicationsModal" centered no-close-on-backdrop no-close-on-esc hide-header hide-footer>
    <div v-if="loading" class="bg-white text-center p-5"><spinner /></div>
    <div v-else-if="publications && publications.length > 0" class="p-3">
      <h4>Medium publications</h4>
      <p>Please only choose publication you own or you contributor to.</p>
      <table class="table table-borderless striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Connect</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="publication in publications" :key="publication.id">
            <td class="d-flex align-items-center">
              <img class="mr-2" :src="publication.imageUrl" alt="" style="width: 40px; height: 40px;" />
              {{ publication.name }}
            </td>
            <td class="text-right">
              <div v-if="!publication.connected" class="custom-control custom-switch">
                <input
                  v-model="selectedPublications"
                  :value="publication.id"
                  type="checkbox"
                  class="custom-control-input"
                  :id="`pcheck#${publication.id}`"
                />
                <label class="custom-control-label" :for="`pcheck#${publication.id}`"></label>
              </div>
              <b-badge v-else variant="success" class="px-2">Connected</b-badge>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-3 text-right">
        <b-button variant="clear" size="sm" @click="cancel" class="px-3 mr-2"> Cancel </b-button>
        <b-button variant="primary" size="sm" @click="saveSelectedMediumPublications" class="px-3 mr-4">
          <b-spinner v-if="savingPublications" small />
          Save
        </b-button>
      </div>
    </div>
    <div v-else class="p-5 pb-2">
      <h3>Your Medium account has been connected successfully!</h3>
      <h5>But you don't seem to have any Medium "Publications".</h5>
      <p>
        For now SocialMediaAgency360 can post directly to your Medium account. When you add publications, click on the
        "Medium" button again to connect them to SocialMediaAgency360.
      </p>
      <div class="text-right mt-3">
        <b-button variant="primary" size="sm" @click="finish" class="px-4 mr-2"> Ok </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { SAVE_SELECTED_MEDIUM_PUBLICATIONS_MUTATION } from '~/graphql/mutations';

export default {
  name: 'connect-medium-publications-modal',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    publications: {
      type: Array,
      default: () => [],
    },

    accountId: {
      required: true,
    },

    queryType: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      selectedPublications: [],
      savingPublications: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  methods: {
    saveSelectedMediumPublications() {
      const limit = parseInt(this.workspace.limits.blogs || '');
      const limitCount = isNaN(limit) ? Infinity : limit;
      const usedCount = parseInt(this.workspace.limitsUsage.blogs || '');
      const remainder = limitCount - usedCount;
      const total = this.selectedPublications.length;

      if (total > remainder) {
        return this.$notify({
          group: 'main',
          title: 'Please Upgrade',
          type: 'native-error',
          text: `You have reached the maximum number of blog accounts allowed in your current plan. You can only add ${remainder} more blog accounts.`,
        });
      }

      this.savingPublications = true;
      return this.$apollo
        .mutate({
          mutation: SAVE_SELECTED_MEDIUM_PUBLICATIONS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            accountId: String(this.accountId),
            publicationIds: this.selectedPublications.map(id => String(id)),
          },
          update: (store, { data: { savedAccounts } }) => {
            if (savedAccounts) {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: { workspace: this.workspace.id, type: this.queryType },
              });

              data.publishAccounts.push(...savedAccounts);

              // Write our data back to the cache.
              store.writeQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: { workspace: this.workspace.id, type: this.queryType },
                data,
              });
            }
          },
        })
        .then(() => {
          const count = parseInt(this.workspace.limitsUsage.blogs);
          this.$store.dispatch('workspace/updateCurrent', {
            ...this.workspace,
            limitsUsage: {
              ...this.workspace.limitsUsage,
              blogs: count + total,
            },
          });

          this.savingPublications = false;

          this.finish();
        })
        .catch(() => {
          this.savingPublications = false;

          Swal.fire({
            type: 'error',
            title: 'OOPS',
            text: 'An error occurred. Please try again.',
          });
        });
    },

    cancel() {
      this.$emit('cancel');
      this.$root.$emit('bv::hide::modal', 'MediumPublicationsModal');
    },

    hide() {
      this.$root.$emit('bv::hide::modal', 'MediumPublicationsModal');
    },

    finish() {
      this.hide();
      this.$emit('done');
    },
  },
};
</script>
