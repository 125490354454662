// state
export const state = {
  current: null,
  workspaces: [],
  roles: [
    {
      id: 'super_admin',
      name: 'Super Admin',
      description: 'Supper admin',
    },
    {
      id: 'admin',
      name: 'Admin',
      description: 'Admin have all the privileges except they cannot view the billing and plan details',
    },
    {
      id: 'collaborator',
      name: 'Collaborator',
      description: 'Collaborator can view and manage their data in this workspace.',
    },
    {
      id: 'approver',
      name: 'Approver',
      description: 'Can review posts and comment on them, has access to the planner/calendar only.',
    },
    {
      id: 'none',
      name: 'No Access',
      description: 'User does not have access to this workspace.',
    },
  ],
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_CURRENT(state, current) {
    state.current = current;
  },
  UPDATE_WORKSPACES(state, workspaces) {
    state.workspaces = workspaces;
  },
};

// actions
export const actions = {
  updateCurrent({ commit }, current) {
    commit('UPDATE_CURRENT', current);
  },
  updateWorkspaces({ commit }, workspaces) {
    commit('UPDATE_WORKSPACES', workspaces);
  },
};
