<template>
  <div class="col-2">
    <transition name="sidebar-slide">
      <b-col cols="2" class="sidebar shadow-sm">
        <div class="sidebar-wrapper">
          <simplebar class="side-nav-wrap mt-3">
            <div class="mb-3" v-if="isDashboard">
              <BrandMenu class="w-100" />
            </div>
            <ul class="side-nav" :class="{ 'mt-1': isDashboard, 'mt-5': !isDashboard }">
              <!-- <li>
                <router-link :to="{ name: 'workspaces' }" active-class="active" v-if="isDashboard">
                  <svg-icon name="dashboard-alt" />
                  Homepage
                </router-link>
              </li> -->
              <li>
                <router-link :to="{ name: 'dashboard' }" active-class="active">
                  <svg-icon name="dashboard" />
                  Dashboard
                </router-link>
              </li>
            </ul>
            <div class="sidebar-subheader mt-2">
              Compose
            </div>
            <ul class="side-nav">
              <li>
                <router-link :to="{ name: 'compose', params: { type: 'social', id: 0 } }">
                  <svg-icon name="post-alt" />
                  Social Post
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'compose', params: { type: 'blog', id: 0 } }">
                  <svg-icon name="globe" />
                  Blog/Website post
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'designer' }" class="position-relative" active-class="active">
                  <svg-icon name="image" />
                  Image designer
                  <star-icon class="star-icon" />
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'compose-audio', params: { id: 0 } }">
                  <svg-icon name="headphone" />
                  Text to Audio
                </router-link>
              </li>
            </ul>
            <div class="sidebar-subheader">
              Content types
            </div>
            <ul class="side-nav">
              <li>
                <router-link :to="{ name: 'find.articles' }" active-class="active">
                  <svg-icon name="popular" />
                  Articles
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'find.quotes' }" active-class="active">
                  <svg-icon name="quote-alt" />
                  Quotes
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'find.memes' }" active-class="active">
                  <svg-icon name="wink" />
                  Memes
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'find.videos' }" active-class="active">
                  <svg-icon name="video-alt" />
                  Videos
                </router-link>
              </li>
            </ul>

            <div class="sidebar-subheader">
              My Content
            </div>
            <ul class="side-nav">
              <!-- <li>
                <router-link :to="{ name: 'campaigns' }" active-class="active">
                  <svg-icon name="campaign" />
                  Campaign
                </router-link>
              </li> -->
              <li>
                <router-link :to="{ name: 'draft.overview' }" active-class="active">
                  <svg-icon name="edit" />
                  Drafts
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'posts' }" active-class="active">
                  <svg-icon name="layers" />
                  My posts
                </router-link>
              </li>
              <li>
                <div @click="toggleChatBar" class="nav-link" id="toggleChatBtn">
                  <svg-icon name="mail" />
                  Chat
                </div>
              </li>
            </ul>
          </simplebar>

          <!-- TODO: update url -->
          <div class="trial-box">
            <a href="https://support.socialagency360.com/" target="_blank" class="px-3 py-1 trial-box__link">
              Help center
            </a>
          </div>
        </div>
        <!--
          <router-link :to="{ name: 'workspaces' }" class="list-link d-flex align-items-center">
            <div class="ml-2 text-center">
              <img src="@/assets/images/logo/logo-block-black-n.png" width="130" alt="" />
            </div>
          </router-link>
        -->
      </b-col>
    </transition>
  </div>
  <!-- edit -->
</template>

<script>
import BrandMenu from './General/Menus/BrandMenu.vue';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'Sidebar',

  components: { BrandMenu, simplebar },

  computed: {
    isDashboard() {
      return this.$route.name === 'dashboard';
    },
  },

  methods: {
    toggleChatBar() {
      this.$emit('toggleChatBar');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.placeholder {
  position: static;
}
.sidebar {
  position: fixed;
  padding-left: 0px !important ;
  padding-right: 0px !important ;
  // top: $navbar-height;
  top: 0;
  // transform: translateY(-90px);
  z-index: 100;
  height: 100%;
  padding-top: 60px;
  max-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // background: $primary;
  background: #fff;
  color: #fff;
  // border-radius: 0 20px 20px 0;
  overflow: visible;

  &-wrapper {
    height: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 15px !important ;
    padding-right: 15px !important ;
  }

  .side-nav-wrap {
    .simplebar-scrollbar {
      width: 5px;
    }

    .simplebar-scrollbar:before {
      border-radius: 5px;
      background: rgba($primary, 0.7);
    }

    .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
      /* When hovered, remove all transitions from drag handle */
      opacity: 1;
      transition: opacity 0s linear;
    }
  }

  .side-nav {
    list-style: none;
    padding: 0;
    &__divider {
      background: #52b788;
      padding: 0;
      margin: 0 18px 5px 18px;
    }

    li {
      .nav-link,
      a {
        margin-bottom: 5px;
        display: block;
        padding: 10px 15px;
        color: $primary;
        // font-size: 12px;
        font-weight: 400;
        border-left: 4px solid transparent;
        cursor: pointer;

        &:hover {
          text-decoration: none;
          background-color: rgba($primary, 0.2);
        }
        &.active {
          border-left: 8px solid #42c589;
          box-shadow: 0 0 7px rgba($primary, 0.1);
        }
        &:hover,
        &.active {
          border-radius: 5px;

          // font-size: 13px;
        }

        .star-icon {
          position: absolute;
          margin-left: 5px;
        }

        .icon {
          margin-right: 4px;
        }
      }
    }
  }

  .sidebar-subheader {
    padding: 0 20px;
    margin-top: 1rem;
    margin-bottom: 5px;
    font-weight: bold;
    color: #52b788;
    cursor: default;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
  }

  .trial-box {
    background-color: #d49f4e;
    padding: 4px 8px;
    width: 65%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 10px 10px 0 0;
    margin: 0 auto;
    font-size: 0.9rem;
    &__link {
      text-align: center;
      color: #ffffff;
      text-decoration: none;
      font-weight: bold;
      text-transform: capitalize;
      &:hover {
        text-decoration: none;
        color: #ffffff;
      }
    }

    h5 {
      font-weight: bold;
      color: #ffffff;
      // font-size: 0.85rem;
      margin-bottom: 15px;
    }

    .btn {
      align-self: center;
      font-weight: bold;
    }
  }
}
.sidebar-slide-enter-active {
  animation: sidebar-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.sidebar-slide-leave-active {
  animation: sidebar-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes sidebar-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sidebar-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
