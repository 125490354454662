<template>
  <svg :width="width" :height="height" viewBox="0 0 60 64.9">
    <path d="M25,0C11.2,0,0,11.2,0,25c0,13.8,11.2,25,25,25h19.5c8.3,0,15.1,6.7,15.5,14.9h0V0H25z" />
  </svg>
</template>

<script>
export default {
  name: 'fab-first-curve-icon',

  props: {
    width: {
      type: String,
      default: '22px',
    },
    height: {
      type: String,
      default: '18px',
    },
  },
};
</script>
