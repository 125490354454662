// state
export const state = {
  openFabPanel: null,
  isDragging: false,
  linkPreview: {},
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_OPEN_FAB_PANEL(state, openFabPanel) {
    state.openFabPanel = openFabPanel;
  },
  UPDATE_IS_DRAGGING(state, isDragging) {
    state.isDragging = isDragging;
  },
  UPDATE_LINK_PREVIEW(state, linkPreview) {
    state.linkPreview = linkPreview;
  },
};

// actions
export const actions = {
  updateOpenFabPanel({ commit }, openFabPanel) {
    commit('UPDATE_OPEN_FAB_PANEL', openFabPanel);
  },
  updateIsDragging({ commit }, isDragging) {
    commit('UPDATE_IS_DRAGGING', isDragging);
  },
  updateLinkPreview({ commit }, linkPreview) {
    commit('UPDATE_LINK_PREVIEW', linkPreview);
  },
};
