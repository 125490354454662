// state
export const state = {
  accounts: [],
  data: {},
  queryType: 'all',
  platformTypeToAdd: 'social',
};

// getters
export const getters = {
  accountData: state => accountId => {
    return state.data[accountId];
  },
};

// mutations
export const mutations = {
  UPDATE_ACCOUNTS(state, accounts) {
    accounts.forEach(account => {
      const index = state.accounts.findIndex(a => a.id === account.id);
      if (index !== -1) {
        state.accounts = [...state.accounts.slice(0, index), account, ...state.accounts.slice(index + 1)];
      } else {
        state.accounts = [...state.accounts, account];
      }
    });
  },

  UPDATE_ACCOUNT_DATA(state, { id, data }) {
    state.data = {
      ...state.data,
      [id]: data,
    };
  },

  UPDATE_PLATFORM_TYPE_TO_ADD(state, platformType) {
    state.platformTypeToAdd = platformType;
  },

  UPDATE_QUERY_TYPE(state, queryType) {
    state.queryType = queryType;
  },
};

// actions
export const actions = {
  updateAccounts({ commit }, accounts) {
    commit('UPDATE_ACCOUNTS', accounts);
  },

  updateAccountData({ commit }, accountData) {
    commit('UPDATE_ACCOUNT_DATA', accountData);
  },

  updateQueryType({ commit }, queryType) {
    commit('UPDATE_QUERY_TYPE', queryType);
  },

  updatePlatformTypeToAdd({ commit }, platformType) {
    commit('UPDATE_PLATFORM_TYPE_TO_ADD', platformType);
  },
};
