<template>
  <div
    class="lazy-bg-img"
    :class="[imageClass, imageState]"
    :style="computedStyle"
    :data-width="imageWidth"
    :data-height="imageHeight"
    :data-state="imageState"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'lazy-background-image',

  props: {
    src: {
      type: String,
    },
    imageClass: {
      type: String,
      required: false,
      default: '',
    },
    loadingImage: {
      type: String,
      default: require('@/assets/images/background_loading.jpg'),
    },
    errorImage: {
      type: String,
      default: require('@/assets/images/image-unavailable.png'),
    },
    imageErrorCallback: {
      type: Function,
      required: false,
      default: function() {},
    },
    imageSuccessCallback: {
      type: Function,
      required: false,
      default: function() {},
    },
    backgroundSize: {
      type: String,
      required: false,
      default: 'cover',
    },
  },

  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
      imageState: 'loading',
      asyncImage: new Image(),
    };
  },

  computed: {
    computedStyle() {
      if (this.imageState === 'loading') {
        return { backgroundImage: `url('${this.loadingImage}')` };
      }

      if (this.imageState === 'error') {
        return { backgroundImage: `url('${this.errorImage}')` };
      }

      if (this.imageState === 'loaded') {
        return { backgroundImage: `url('${this.asyncImage.src}')`, backgroundSize: this.backgroundSize };
      }

      if (this.imageState === 'unavailable') {
        return { backgroundImage: `url('${this.errorImage}')` };
      }

      return '';
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchImage();
    });
  },

  methods: {
    // eslint-disable-next-line
    fetchImage() {
      if (!this.src) {
        this.imageState = 'unavailable';
        return;
      }

      this.imageState = 'loading';
      this.asyncImage.onload = this.imageOnLoad;
      this.asyncImage.onerror = this.imageOnError;
      this.asyncImage.src = this.src;
    },
    // eslint-disable-next-line
    imageOnLoad(success) {
      this.imageState = 'loaded';
      this.imageWidth = this.asyncImage.naturalWidth;
      this.imageHeight = this.asyncImage.naturalHeight;
      this.imageSuccessCallback();
    },
    // eslint-disable-next-line
    imageOnError(error) {
      this.imageState = 'error';
      this.imageErrorCallback();
    },
  },
};
</script>

<style lang="scss">
.lazy-bg-img {
  &.loading {
    filter: blur(10px);
  }
  &.error,
  &.unavailable {
  }
}
</style>
