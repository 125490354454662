import urlRegex from './urlregex';

export const stripTags = html => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

export const omitDeep = (omit, omitKey) => {
  const keys = Object.keys(omit);
  const omitted = {};

  keys.forEach(key => {
    if (key !== omitKey) {
      const value = omit[key];

      const skip = [Date, File];
      const isObject = typeof value === 'object' && !skip.some(o => value instanceof o);

      if (Array.isArray(value)) {
        omitted[key] = omitDeepArrayWalk(value, omitKey);
      } else if (isObject && value !== null) {
        omitted[key] = omitDeep(value, omitKey);
      } else {
        omitted[key] = value;
      }
    }
  });

  return omitted;
};

function omitDeepArrayWalk(arr, key) {
  return arr.map(value => {
    if (Array.isArray(value)) {
      return omitDeepArrayWalk(value, key);
    } else if (typeof value === 'object') {
      return omitDeep(value, key);
    }

    return value;
  });
}

export const numFormat = (num, digits = 1) => {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const trim = (s, mask) => {
  while (~mask.indexOf(s[0])) {
    s = s.slice(1);
  }
  while (~mask.indexOf(s[s.length - 1])) {
    s = s.slice(0, -1);
  }
  return s;
};

export const isValidUrl = url => {
  return typeof url === 'string' && urlRegex.test(url);
};

export const can = (user, permission) => {
  const permissions = user && user.permissions ? user.permissions : [];

  return permissions.includes(permission);
};

export const formatCurrency = (number, decimals = undefined, currency = 'USD', locale = 'en-US') => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
  });

  return formatter.format(number);
};

export const truncate = (str, n, useWordBoundary) => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1);
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '&hellip;';
};

export const staticPublicPrefix = process.env.VUE_APP_PUBLIC_PATH || '/';
