<template>
  <b-popover :target="target" custom-class="article-shares-popover" triggers="hover" placement="topright" offset="70">
    <div class="d-flex">
      <div class="stat-itm border-right border-light text-center">
        <svg-icon name="facebook-colored" />
        <div>{{ numFormat(article.facebookShares) }}</div>
      </div>
      <div class="stat-itm border-right border-light text-center">
        <svg-icon name="twitter-colored" />
        <div>{{ numFormat(article.twitterShares) }}</div>
      </div>
      <div class="stat-itm border-right border-light text-center">
        <svg-icon name="linkedin-colored" />
        <div>{{ numFormat(article.linkedinShares) }}</div>
      </div>
      <div class="stat-itm border-right border-light text-center">
        <svg-icon name="pinterest-colored" />
        <div>{{ numFormat(article.pinterestShares) }}</div>
      </div>
      <div class="stat-itm text-center">
        <svg-icon name="reddit-colored" />
        <div>{{ numFormat(article.redditShares) }}</div>
      </div>
    </div>
  </b-popover>
</template>

<script>
import { numFormat } from '~/utils/helpers';

export default {
  props: {
    target: {
      type: String,
      required: true,
    },
    article: {
      type: Object,
      required: true,
    },
  },

  methods: {
    numFormat,
  },
};
</script>

<style lang="scss">
.article-shares-popover {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(184, 189, 209, 0.67);
  border: 1px solid rgba(152, 158, 181, 0.13);
  min-width: 320px;

  .stat-itm {
    min-width: 60px;
  }
}
</style>
