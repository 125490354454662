import { camelCase, upperFirst } from 'lodash';

// state
export const state = {
  ready: false,
  restless: false,
  current: 'page-loading',
  showSidebar: true,
};

// getters
export const getters = {
  currentLayoutComponentName(state) {
    const current = state.current;
    const name = upperFirst(camelCase(current.replace('.', '-')));
    return `Layout${name}`;
  },
};

// mutations
export const mutations = {
  SET_READY_STATE(state, ready) {
    state.ready = ready;
  },
  SET_CURRENT_LAYOUT(state, current) {
    state.current = current;
  },
  UPDATE_SHOW_SIDEBAR(state, showSidebar) {
    state.showSidebar = showSidebar;
  },
  UPDATE_RESTLESS(state, isRestless) {
    state.restless = isRestless;
  },
};

// actions
export const actions = {
  updateReadyState({ commit }, isReady) {
    commit('SET_READY_STATE', isReady);
  },
  setCurrent({ commit }, current) {
    commit('SET_CURRENT_LAYOUT', current);
  },
  updateShowSidebar({ commit }, showSidebar) {
    commit('UPDATE_SHOW_SIDEBAR', showSidebar);
  },
  updateRestless({ commit }, isRestless) {
    commit('UPDATE_RESTLESS', isRestless);
  },
};
