import Vue from 'vue';

import '~/polyfills';
import '~/scss/main.scss';

import '~/plugins';
import '~/directives';
import '~/components';

import App from '~/App';
import store from '~/store';
import router from '~/router';
import { apolloProvider } from '~/plugins/apollo';
import 'classlist-polyfill';
import ClickOutside from 'vue-click-outside';

Vue.config.productionTip = false;

Vue.directive('clicked-outside', ClickOutside);

import registerLayoutComponents from './layouts/register';
registerLayoutComponents();

const SocialMediaAgency360 = new Vue({
  router,
  store,
  apolloProvider,
  ...App,
}).$mount('#app');

export default SocialMediaAgency360;
