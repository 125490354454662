<template>
  <svg width="20" height="20" viewBox="0 0 20 20">
    <rect width="20" height="20" fill="#FFF" rx="6"></rect>
    <path fill="#ee5951" fill-rule="evenodd" d="M3.57 17.99L18.42 3.15 17.26 2 2.42 16.84l1.15 1.15z"></path>
    <path
      fill="#d8e1e8"
      fill-rule="evenodd"
      d="M14 19c2.76 0 5-2.24 5-5V6c0-2.76-2.24-5-5-5H6C3.24 1 1 3.24 1 6v8c0 2.76 2.24 5 5 5h8m0 1H6c-3.31 0-6-2.69-6-6V6c0-3.31 2.69-6 6-6h8c3.31 0 6 2.69 6 6v8c0 3.31-2.69 6-6 6z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'text-list-none',
};
</script>
