<template>
  <div class="workspace-menu" :class="{ 'workspace-menu--active': showUserMenu }">
    <div id="dropDown" class="workspace-menu__button" @click="showUserMenu = !showUserMenu">
      <div class="workspace-menu-info">
        <b-avatar class="avatar" size="2rem" :src="user.photo"></b-avatar>
        <div>
          <span class="name text-capitalize">{{ user.firstName | truncate(10, '...') }}</span>
        </div>
        <svg-icon name="chevron-down" class="ml-2 mt-1" size="sm" />
      </div>
    </div>

    <div class="workspace-menu__menu-box">
      <div class="workspace-menu__menu-box-title mb-3">
        <div>
          <b-avatar class="avatar" size="5rem" :src="user.photo"></b-avatar>
        </div>
        <div>
          <div class="my-2">
            <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
          </div>
          <div>
            <b-button variant="default" class="title-logout" @click.prevent="logout" title="logout">
              <b-spinner v-if="authBusy" class="mr-1" small />
              <svg-icon name="power" />
              <span class="title-text">Logout</span>
            </b-button>
            <router-link
              class="title-action"
              title="Settings"
              :to="{ name: 'settings.brand' }"
              exact
              @click.native="hide()"
            >
              <div>
                <svg-icon name="settings" />
              </div>
              <span class="title-text">Settings</span>
            </router-link>
          </div>
        </div>
      </div>
      <b-row class="menus" no-gutters>
        <b-col cols="12" class="workspace-menu__menu-box-left">
          <ul class="workspace-menu__menu">
            <li data-name="team" class="workspace-menu__item">
              <router-link
                class="item-link"
                title="Team Members"
                :to="{ name: 'settings.team' }"
                exact
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="team-alt" class="" size="md" />
                </div>
                Team Members
              </router-link>
            </li>
            <li data-name="crm" class="workspace-menu__item">
              <router-link
                class="item-link"
                title="Blogs &amp; Websites"
                :to="{ name: 'settings.blogs' }"
                exact
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="globe" class="" size="md" />
                </div>
                Blogs &amp; Websites
              </router-link>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="workspace-menu__menu-box-center">
          <ul class="workspace-menu__menu">
            <li data-name="social" class="workspace-menu__item">
              <router-link
                class="item-link"
                title="Social Accounts"
                :to="{ name: 'settings.social' }"
                exact
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="share-alt" size="md" />
                </div>
                Social Accounts
              </router-link>
            </li>

            <li data-name="Integration" class="workspace-menu__item mb-3">
              <router-link
                class="item-link"
                :to="{ name: 'settings.integrations' }"
                exact
                title="Other Integrations"
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="integration" size="md" />
                </div>
                Other Integrations
              </router-link>
            </li>
          </ul>

          <a class="sm-item-link fs-12" href="https://socialagency360.com/video-tutorial/">
            <div class="item-icon">
              <svg-icon name="archive" size="sm" />
            </div>
            Webinars & Tutorials
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    authBusy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showUserMenu: false,
    };
  },

  created() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.showUserMenu = false;
      }
    });
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  methods: {
    async logout() {
      // Log out the user.
      await this.$store.dispatch('auth/logout');
      // Redirect to login.
      this.$router.push({ name: 'signin' });
    },

    hide() {
      this.showUserMenu = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.workspace-menu {
  z-index: 9999;
  display: inline-block;
  position: relative;
  &__button {
    background: transparent;
    display: inline-block;
  }
  &-info {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 2px 10px;
    .avatar {
      margin-right: 8px;
    }
    .name {
      font-size: 16px;
    }
  }
  &__menu-box {
    position: absolute;
    width: 290px;
    right: -10px;
    background-color: #1b4332;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    margin-top: 5px;
    padding: 20px;
    &:before {
      content: '';
      background-color: transparent;
      position: absolute;
      border-bottom: 8px solid #1b4332;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid transparent;
      top: -15px;
      right: 20%;
    }
    &::after {
      content: '';
      background-color: transparent;
    }
    &-left {
      // border-right: 1px solid #e0e2e9;
    }
    &-right {
      background-color: #081c15;
      border-radius: 25px;
    }
    &-title {
      color: $secondary;
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      .title-link,
      .title-link:hover {
        color: $secondary;
        text-decoration: none;
        .name {
          font-size: 18px;
        }
      }

      .title-action,
      .title-logout {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        text-transform: uppercase;
        font-size: 14px;
        color: #8ba093;
        transition: all 0.3s;
        aspect-ratio: 1/1;
        height: 100%;
        background: #8ba09321;
        padding: 5px 8px !important;

        > span.title-text {
          max-width: 0px;
          overflow: hidden;
        }
        &:hover,
        &:focus {
          color: inherit;
          text-decoration: none;
          padding: 5px 10px !important;
          border: 1px solid #d8f3dc;
          aspect-ratio: auto;

          > span.title-text {
            max-width: 100%;
            padding-left: 10px;
          }
        }
      }
      .title-logout {
        margin-right: 10px;
        padding-bottom: 2px !important;
      }
      .title-action {
        padding: 5px 9px !important;
        padding-top: 6px !important;
      }
    }
  }
  &__menu {
    margin: 0;
    list-style: none;
  }
  &__item {
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    position: relative;
    border: 1px solid transparent;
    &:hover {
      background-color: #2d6a4f;
      border: 1px solid #d8f3dc41;
      border-radius: 5px;
    }
    .item-link {
      color: #ffffff;
      display: flex;
      gap: 10px;
      padding: 12px 15px 10px 10px;
      &:hover,
      &:focus {
        color: #ffffff;
        outline: none;
        text-decoration: none;
      }
    }
    .item-icon {
      text-align: center;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      -ms-transition: all 0.4s;
      -o-transition: all 0.4s;
    }
  }
}

.menus {
  border-top: 1px solid #ffffff15;
}

.workspace-menu--active .workspace-menu__menu-box {
  visibility: visible;
  opacity: 1;
  margin-top: 15px;
}

.fs-12 {
  font-size: 12px;
}

.sm-item-link {
  color: white !important;
  background-color: #081c15;
  padding: 8px 10px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 0.5rem;
}
</style>
