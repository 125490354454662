import Cookies from 'js-cookie';
import { apolloClient } from '~/plugins/apollo';
import { LOGOUT_MUTATION } from '~/graphql/mutations';
import { USER_DETAILS_QUERY } from '~/graphql/queries';

// state
export const state = {
  user: null,
  busy: true,
  token: Cookies.get('token'),
};

// getters
export const getters = {
  isLoggedIn: state => state.user !== null,
};

// mutations
export const mutations = {
  SAVE_TOKEN(state, { token, remember }) {
    state.token = token;

    Cookies.set('token', token, {
      domain: process.env.VUE_APP_COOKIE_TOKEN_DOMAIN,
      expires: remember ? 7 : null,
    });
  },

  FETCH_USER_SUCCESS(state, { user }) {
    state.user = user;
  },

  FETCH_USER_FAILURE(state) {
    state.token = null;
    Cookies.remove('token');
  },

  LOGOUT(state) {
    state.token = null;

    Cookies.remove('token');

    state.user = null;
  },

  UPDATE_BUSY_STATE(state, isBusy) {
    state.busy = isBusy;
  },

  UPDATE_USER(state, { user }) {
    state.user = user;
  },
};

// actions
export const actions = {
  saveToken({ commit }, payload) {
    commit('SAVE_TOKEN', payload);
  },

  async fetchUser({ commit }) {
    try {
      commit('UPDATE_BUSY_STATE', true);

      const { data } = await apolloClient.query({
        query: USER_DETAILS_QUERY,
        fetchPolicy: 'no-cache', // Very Important!
        context: {
          uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
        },
      });

      commit('FETCH_USER_SUCCESS', { user: data.userDetails });

      commit('UPDATE_BUSY_STATE', false);
    } catch (e) {
      commit('FETCH_USER_FAILURE');

      commit('UPDATE_BUSY_STATE', false);
    }
  },

  updateUser({ commit }, payload) {
    commit('UPDATE_USER', payload);
  },

  async logout({ commit }) {
    commit('UPDATE_BUSY_STATE', true);

    try {
      await apolloClient.mutate({
        mutation: LOGOUT_MUTATION,
        context: {
          uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
        },
      });
    } catch (e) {
      //
    } finally {
      commit('UPDATE_BUSY_STATE', false);
      apolloClient.clearStore();
    }

    commit('LOGOUT');
  },
};
