import { isObject, isArray, get, isNull } from 'lodash';
import { can } from '~/utils/helpers';

// state
export const state = {};

// getters
export const getters = {
  getWorkspace(state, getter, rootState) {
    return (workspaceId = null) => {
      const wkId = String(!isNull(workspaceId) ? workspaceId : get(rootState.workspace.current, 'id', 0));

      if (wkId === '0') return false;
      const workspaces = rootState.workspace.workspaces;

      const workspace = workspaces.find(({ id }) => String(id) === wkId);
      return workspace;
    };
  },

  getWorkspaceCurrentMember(state, getters, rootState, rootGetters) {
    return (workspaceId = null) => {
      const isLoggedIn = rootGetters['auth/isLoggedIn'];
      if (!isLoggedIn) return false;

      const workspace = getters.getWorkspace(workspaceId);
      if (!isObject(workspace) || !isArray(workspace.members)) return false;

      const members = workspace.members;
      const userId = String(rootState.auth.user.id);

      const member = members.find(member => String(get(member, 'user.id', 0)) === userId);
      if (!isObject(member)) return false;
      return member;
    };
  },

  isCurrentUserInWorkspace() {
    return (workspaceId = null) => {
      const member = getters.getWorkspaceCurrentMember(workspaceId);
      return !!member;
    };
  },

  isUserClientInWorkspace(state, getters) {
    return (workspaceId = null) => {
      const member = getters.getWorkspaceCurrentMember(workspaceId);
      if (!isObject(member)) return false;

      return String(member.type).toLowerCase() === 'client';
    };
  },

  isUserTeamMemberInWorkspaceAs(state, getters) {
    return (workspaceId = null, role) => {
      const member = getters.getWorkspaceCurrentMember(workspaceId);

      if (!isObject(member)) return false;

      return (
        String(member.type).toLowerCase() === 'team' && String(member.role).toLowerCase() === String(role).toLowerCase()
      );
    };
  },

  isUserAdminInWorkspace(state, getters) {
    return (workspaceId = null) => {
      return (
        getters.isUserTeamMemberInWorkspaceAs(workspaceId, 'admin') || getters.isUserSuperAdminInWorkspace(workspaceId)
      );
    };
  },

  isUserApproverInWorkspace(state, getters) {
    return (workspaceId = null) => {
      return getters.isUserTeamMemberInWorkspaceAs(workspaceId, 'approver');
    };
  },

  isUserCollaboratorInWorkspace(state, getters) {
    return (workspaceId = null) => {
      return getters.isUserTeamMemberInWorkspaceAs(workspaceId, 'collaborator');
    };
  },

  isUserSuperAdminInWorkspace(state, getters) {
    return (workspaceId = null) => {
      return getters.isUserTeamMemberInWorkspaceAs(workspaceId, 'super_admin');
    };
  },

  isUserWorkspaceOwner(state, getters, rootState) {
    return (workspaceId = null) => {
      const workspace = getters.getWorkspace(workspaceId);

      return (
        getters.isUserSuperAdminInWorkspace(workspaceId) &&
        String(get(workspace, 'user.id', 0)) === String(get(rootState.auth.user, 'id'))
      );
    };
  },

  isUserReseller(state, getters, rootState) {
    const user = rootState.auth.user;
    if (!isObject(user)) return false;
    if (!user.subscription || !user.subscribed) {
      return false;
    }

    let addons = get(user, 'subscription.addons', []);
    if (!isArray(addons)) addons = [];

    const reseller = addons.filter(
      addon => addon.slug == 'reseller_gold' || addon.slug == 'reseller_silver' || addon.slug == 'reseller_unlimited',
    );
    return reseller.length > 0;
  },

  isUserSubscribed(state, getters, rootState) {
    const user = rootState.auth.user;
    if (!isObject(user)) return false;
    const hasSubscription = !!(user.subscription || user.subscribed);

    return hasSubscription || can(user, 'bypass-subscription');
  },

  isUserClientOrApprover(state, getters) {
    return getters.isUserClientInWorkspace() || getters.isUserApproverInWorkspace();
  },
};

// mutations
export const mutations = {};

// actions
export const actions = {};
