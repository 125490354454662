<template>
  <b-modal id="FBModal" centered no-close-on-backdrop no-close-on-esc hide-header hide-footer>
    <div v-if="loading" class="bg-white text-center p-5"><spinner /></div>
    <template v-else>
      <table v-if="accounts.pages && accounts.pages.length > 0" class="table table-borderless striped ">
        <thead>
          <tr>
            <th>Name</th>
            <th>Connect</th>
          </tr>
        </thead>
        <h5>Facebook pages</h5>
        <tbody>
          <tr v-for="facebookPage in accounts.pages" :key="facebookPage.id">
            <td class="d-flex align-items-center">
              <img class="mr-2 rounded" :src="facebookPage.imageUrl" alt="" style="width: 40px; height: 40px;" />
              {{ facebookPage.name }}
            </td>
            <td class="text-right">
              <div v-if="!facebookPage.connected" class="custom-control custom-switch">
                <input
                  v-model="fbPages"
                  :value="facebookPage.id"
                  type="checkbox"
                  class="custom-control-input"
                  :id="`check#${facebookPage.id}`"
                />
                <label class="custom-control-label" :for="`check#${facebookPage.id}`"></label>
              </div>
              <b-badge v-else variant="success" class="px-2">Connected</b-badge>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="accounts.groups && accounts.groups.length > 0" class="table table-borderless striped ">
        <h5 class="mt-2">Facebook groups</h5>
        <tbody>
          <tr v-for="group in accounts.groups" :key="group.id">
            <td class="d-flex align-items-center">
              <div class="d-flex">
                <img class="mr-2 rounded" :src="group.imageUrl" alt="" style="width: 40px; height: 40px;" />
                <div class="d-flex flex-column">
                  <div>
                    {{ group.name }}
                    <span
                      v-b-tooltip
                      title="In order to publish content to your Facebook group, you must install SocialMediaAgency360 application in your group"
                      ><svg-icon name="help" class="ml-2"
                    /></span>
                  </div>
                  <a
                    :href="
                      `https://www.facebook.com/groups/${group.id}/edit/?launch_popover=app_detail&app_id=${facebookAppId}`
                    "
                    target="_blank"
                    >Install app</a
                  >
                </div>
              </div>
            </td>
            <td class="text-right">
              <div v-if="!group.connected" class="custom-control custom-switch">
                <input
                  v-model="fbGroups"
                  :value="group.id"
                  type="checkbox"
                  class="custom-control-input"
                  :id="`gcheck#${group.id}`"
                />
                <label class="custom-control-label" :for="`gcheck#${group.id}`"></label>
              </div>
              <b-badge v-else variant="success" class="px-2">Connected</b-badge>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-2 float-right">
        <b-button variant="clear" size="sm" class="px-3 mr-2" @click="cancel"> Cancel </b-button>
        <b-button variant="primary" size="sm" class="px-3 mr-4" @click="saveSelectedFbAccounts">
          <b-spinner v-if="savingFbAccounts" small />
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { SAVE_SELECTED_FB_ACCOUNTS_MUTATION } from '~/graphql/mutations';

export default {
  name: 'connect-facebook-accounts-modal',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    accountId: {
      required: true,
    },

    accounts: {
      type: Array,
      default: () => [],
    },

    queryType: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      fbPages: [],
      fbGroups: [],
      savingFbAccounts: false,
      facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  methods: {
    saveSelectedFbAccounts() {
      const limit = parseInt(this.workspace.limits.socialAccounts || '');
      const limitCount = isNaN(limit) ? Infinity : limit;
      const usedCount = parseInt(this.workspace.limitsUsage.socialAccounts || '');
      const remainder = limitCount - usedCount;
      const total = this.fbPages.length + this.fbGroups.length;

      if (total > remainder) {
        return this.$notify({
          group: 'main',
          title: 'Please Upgrade',
          type: 'native-error',
          text: `You have reached the maximum number of social accounts allowed in your current plan. You can only add ${remainder} more social accounts.`,
        });
      }

      this.savingFbAccounts = true;
      return this.$apollo
        .mutate({
          mutation: SAVE_SELECTED_FB_ACCOUNTS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            accountId: String(this.accountId),
            pageIds: this.fbPages.map(pageId => String(pageId)),
            groupIds: this.fbGroups.map(groupId => String(groupId)),
          },
          update: (store, { data: { savedAccounts } }) => {
            if (savedAccounts) {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  type: this.queryType,
                },
              });

              data.publishAccounts.push(...savedAccounts);

              // Write our data back to the cache.
              store.writeQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  type: this.queryType,
                },
                data,
              });
            }
          },
        })
        .then(() => {
          const count = parseInt(this.workspace.limitsUsage.socialAccounts);
          this.$store.dispatch('workspace/updateCurrent', {
            ...this.workspace,
            limitsUsage: {
              ...this.workspace.limitsUsage,
              socialAccounts: count + total,
            },
          });

          this.savingFbAccounts = false;
          this.hide();

          this.$emit('done');
        })
        .catch(() => {
          this.savingFbAccounts = false;

          Swal.fire({
            type: 'error',
            title: 'OOPS!',
            text: 'An error occurred. Please try again.',
          });
        });
    },

    cancel() {
      this.$emit('cancel');
      this.$root.$emit('bv::hide::modal', 'FBModal');
    },

    hide() {
      this.$root.$emit('bv::hide::modal', 'FBModal');
    },
  },
};
</script>
