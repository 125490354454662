import Vue from 'vue';
import Spinner from './Spinner';
import SvgIcon from './SvgIcon';
import HasError from './HasError';
import { kebabCase } from 'lodash';
import AlertErrors from './AlertErrors';
import PageLoading from './PageLoading';
import SpriteIcon from './SpriteIcon';
import SkeletonScreen from './SkeletonScreen';
import SkeletonScreens from './SkeletonScreens';

// Components that are registered globaly.
[AlertErrors, HasError, PageLoading, Spinner, SpriteIcon, SkeletonScreen, SkeletonScreens, SvgIcon].forEach(
  Component => {
    Vue.component(Component.name, Component);
  },
);

const components = require.context('./icons', true, /\.vue$/);
components.keys().map(component => {
  // Turn './ComponentName.vue' into 'component-name'
  const componentName = kebabCase(component.replace(/(^\.\/)|(\.vue$)/g, ''));

  // Register new component globally
  Vue.component(componentName, components(component).default);
});
