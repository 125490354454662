<template>
  <b-modal
    id="ConnectAccountsModal"
    modal-class="connect-accounts-modal"
    size="sm"
    centered
    hide-header
    hide-footer
    content-class="rounded-sm"
  >
    <div class="connect-account-buttons">
      
      <template v-if="type == 'social' || type == 'all'">
        <!-- Social Buttons -->
        <SocialButtons :type="type" :accounts="['Facebook', 'Twitter', 'LinkedIn', 'Pinterest', 'Instagram']" prefix="Connect" />
      </template>
      <template v-if="type == 'blog' || type == 'all'">
        <SocialButtons :type="type" :accounts="['Wordpress', 'Medium']" prefix="Connect" /> 
        <!-- Add Tublr to the list when needed -->
      </template>
      <template v-if="type == 'voice' || type == 'all'">
        <SocialButtons :type="type" :accounts="['Spotify', 'Podbean']" prefix="Connect" /> 
      </template>

      <b-button
        variant="outline-primary"
        @click="$bvModal.hide('ConnectAccountsModal')"
        class="text-center mt-4 mb-0"
        size="sm"
        block
      >
        Cancel
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import SocialButtons from '../components/Buttons/SocialButtons';

export default {
  components: { SocialButtons },

  props: {
    type: {
      type: String,
      default: 'all',
    },
  },

  data: function() {
    return {
      //
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  methods: {
    hasExceeded(type = '') {
      const limit = parseInt(this.workspace.limits.blogs || '');
      const limitCount = isNaN(limit) ? Infinity : limit;
      const usedCount = parseInt(this.workspace.limitsUsage.blogs || '');

      const check = usedCount >= limitCount;

      if (check) {
        this.$notify({
          group: 'main',
          title: 'Please Upgrade',
          type: 'native-error',
          text: `You have reached the maximum number of ${type} accounts allowed in your current plan. Please upgrade to create more ${type} accounts.`,
        });
      }

      return check;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.connect-accounts-modal {
  .connect-account-buttons {
    .btn {
      padding: 0.7rem;
      text-align: left;
      margin-bottom: 1.3rem;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 5px;
      color: $secondary;

      .icon {
        margin-right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .modal-dialog {
    max-width: 375px;

    .modal-body {
      padding: 2.2rem;
    }
  }
}
</style>
