<template>
  <PageLoading></PageLoading>
</template>

<script>
import PageLoading from '~/components/PageLoading.vue';

export default {
  name: 'PageLoadingLayout',
  components: { PageLoading },
};
</script>
