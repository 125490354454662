import Vue from 'vue';
import store from '~/store';
import Meta from 'vue-meta';
import routes from './routes';
import Router from 'vue-router';
import NProgress from 'nprogress';
import { can } from '~/utils/helpers';
import { sync } from 'vuex-router-sync';
import { FETCH_WORKSPACES_QUERY, FETCH_BUSINESS_QUERY } from '~/graphql/queries';
import { isObject, get, isArray, isString } from 'lodash';
import app from '~/main';

Vue.use(Meta);
Vue.use(Router);

const router = createRouter();

export default router;

sync(store, router);
/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter() {
  const router = new Router({
    scrollBehavior,
    mode: 'history',
    routes,
  });

  router.beforeEach(beforeEach);
  router.afterEach(afterEach);
  router.beforeResolve(beforeResolve);

  return router;
}

async function preloadWorkspace(to, next) {
  if (!isObject(store.state.workspace.current)) {
    const { data: { fetchWorkspaces } = {} } = await app.$apollo.query({
      query: FETCH_WORKSPACES_QUERY,
    });

    if (fetchWorkspaces) {
      const currentWorkspace = fetchWorkspaces.find(w => w.slug === to.params.workspace);
      const excludeNames = ['manage-team', 'workspaces'];
      if (!excludeNames.includes(to.name) && to.params.workspace && !currentWorkspace) {
        app.$notify({
          group: 'main',
          type: 'error',
          text: 'Workspace does not exist',
        });

        return next({ name: 'workspaces' });
      }

      await store.dispatch('workspace/updateCurrent', currentWorkspace);
      await store.dispatch('workspace/updateWorkspaces', fetchWorkspaces);
    }
  }
}

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach(to, from, next) {
  try {
    if (to.name) {
      NProgress.start();
    }

    if (to.fullPath === from.fullPath) {
      return next(false);
    }

    // Load logged in user details
    if (!store.getters['auth/isLoggedIn'] && store.state.auth.token) {
      try {
        await store.dispatch('auth/fetchUser');
      } catch (e) {
        // console.log(e.getMessage());
      }
    }

    const user = store.state.auth.user;
    const isVerifyOrUpgrade = ['upgrade', 'verify'].includes(to.name);

    // console.log('user here is ===> ', user, to);
    if (user) {
      if (to.name === 'verify' && user.hasVerifiedEmail) {
        // console.log('user is verified and heading to dashboard');

        return next({ name: 'dashboard' });
      } else if (!user.hasVerifiedEmail && to.name !== 'verify') {
        // console.log('user is not verified and heading to verify');

        return next({ name: 'verify' });
      } else if (
        !user.subscribed &&
        user.status != 'invited' &&
        !can(user, 'bypass-subscription') &&
        !isVerifyOrUpgrade
      ) {
        // console.log('user is heading to upgrade');

        return next({ name: 'upgrade' });
      } else if (to.name !== 'business.create' && !isVerifyOrUpgrade) {
        if (user.status !== 'invited') {
          const { data: { fetchBusiness } = {} } = await app.$apollo.query({
            query: FETCH_BUSINESS_QUERY,
          });
          await preloadWorkspace(to, next);

          if (fetchBusiness.length < 1) {
            // console.log('user is heading to create business');

            return next({ name: 'business.create' });
          }

          const currentBusiness = fetchBusiness.find(biz => biz.id === 1);
          await store.dispatch('business/updateCurrent', currentBusiness);
        }

        // console.log('user set default business');
      }
    }

    if (
      to.matched.some(record => record.meta.requiresAuth) &&
      !store.getters['auth/isLoggedIn'] &&
      to.name !== 'signin'
    ) {
      // console.log('user is heading t signin');

      return next({ name: 'signin' });
    }

    if (store.getters['auth/isLoggedIn']) {
      // console.log('user is logged in');

      if (to.matched.find(record => get(record, 'meta.type') === 'workspace-container') || to.name === 'manage-team') {
        await preloadWorkspace(to, next);
        const isClient = store.getters['accessControl/isUserClientInWorkspace']();
        const isApprover = store.getters['accessControl/isUserApproverInWorkspace']();
        const isAdmin = store.getters['accessControl/isUserAdminInWorkspace']();
        const isCollaborator = store.getters['accessControl/isUserCollaboratorInWorkspace']();
        const currentWorkspaceSlug = get(store.state.workspace, 'current.slug', null);

        if (to.name === 'manage-team') {
          // console.log('user is heading to manage team');

          return next();
        }

        if (!to.params.workspace && currentWorkspaceSlug) {
          // console.log('user route has no workspace but there is a worksace slug in store');

          return next({ ...to, params: { ...to.params, workspace: currentWorkspaceSlug } });
        }

        if (!currentWorkspaceSlug && to.name !== 'workspaces') {
          // console.log('user route has no workspace and is heading to workspaces');

          return next({ name: 'workspaces' });
        }

        if (String(to.name).startsWith('custom-dashboard')) {
          // console.log('user is heading to custom dashboard');

          if (isAdmin || isCollaborator) {
            // console.log('user is admin or is collaborator so go to dahboard');

            return next({ name: 'dashboard', params: { workspace: currentWorkspaceSlug } });
          }

          if (isApprover) {
            // console.log('user is approver');

            const matches = to.fullPath.match(new RegExp('^/(\\S{1,})/client/(\\S{1,})$'));
            if (isArray(matches)) {
              const path = `/${get(matches, 1)}/approver/${get(matches, 2)}`;

              if (to.fullPath !== path) {
                const { href } = router.resolve({ path });

                if (isString(href)) {
                  await next();
                  window.history.replaceState({}, null, href);
                  return;
                }
              }
            }
          }

          return next();
        }

        if (to.name === 'dashboard') {
          // console.log('user is heading to dahboard');

          if (isAdmin || isCollaborator) {
            // console.log('user is admin or collaborator so allow');

            return next();
          }

          if (isClient || isApprover) {
            // console.log('user is client or approver so redirect to custom dashboard');

            return next({ name: 'custom-dashboard', params: { workspace: currentWorkspaceSlug } });
          }

          if (from.name === 'workspaces') {
            // console.log('user is coming from workspaces so reject');

            return next(false);
          }

          // console.log('user should head to worspaces');
          return next({ name: 'workspaces' });
        }

        const globalSettings = [
          'settings',
          'settings.billing',
          'settings.business',
          'settings.notifications',
          'settings.plan',
          'settings.profile',
        ];

        const clientAllowedPaths = [
          ...globalSettings,
          'settings.audio',
          'settings.blogs',
          'settings.social',
          'planner',
          'planner.calendar',
          'planner.list',
          'reports',
          'report.overview',
          'report.download',
          'report.facebook',
          'report.instagram',
          'report.linkedin',
          'report.twitter',
          'report.scheudle',
          'post.view',
        ];

        const approverAllowedPaths = [...globalSettings, 'post.view'];
        const collaboratorAllowedPaths = [
          ...globalSettings,
          'planner',
          'planner.calendar',
          'planner.list',
          'reports',
          'draft',
          'draft.overview',
          'draft.edit',
          'draft.share',
          'campaign',
          'campaign.posts',
          'campaign.stats',
          'campaign.logs',
          'campaigns',
          'compose',
          'compose-audio',
          'designer',
          'designer.overview',
          'designer.designs',
          'designer.edit',
          'designer.share',
          'find',
          'find.articles',
          'find.memes',
          'find.quotes',
          'find.videos',
          'posts',
          'topics',
        ];

        if (isClient && clientAllowedPaths.includes(to.name)) {
          return next();
        }

        if (isApprover && approverAllowedPaths.includes(to.name)) {
          return next();
        }
        if (isCollaborator && collaboratorAllowedPaths.includes(to.name)) {
          return next();
        }

        if (isAdmin) {
          return next();
        }

        if ((isAdmin || isCollaborator) && to.name !== 'dashboard') {
          // console.log('redirect to dahboard for stray admin and collaborators');

          return next({ name: 'dashboard', params: { workspace: currentWorkspaceSlug } });
        }

        if ((isApprover || isClient) && to.name !== 'custom-dashboard') {
          // console.log('redirect to custom dahboard for stray approver and clients');

          return next({ name: 'custom-dashboard', params: { workspace: currentWorkspaceSlug } });
        }
      }

      if (to.matched.some(record => record.meta.guest) && to.name !== 'dashboard') {
        // console.log('route has meta guest and if not heading to dahboard');

        const currentWorkspaceSlug = get(store.state.workspace, 'current.slug', null);

        if (currentWorkspaceSlug) {
          // console.log('route has meta guest and force path to dahboard');

          return next({ name: 'dashboard', params: { workspace: currentWorkspaceSlug } });
        } else if (to.name !== 'workspaces') {
          // console.log('route has meta guest and force path to workspaces');

          return next({ name: 'workspaces' });
        }
      }
    }

    return next();
  } catch (e) {
    console.dir(e);
  }
}

/**
 * Global before resolve hook.
 * Called right before the navigation is confirmed,
 * after all in-component guards and async route components are resolved.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeResolve(to, from, next) {
  const currentLayout = to.meta.layout || 'default';

  await store.dispatch('layout/setCurrent', currentLayout);
  if (to.meta.showSidebar !== undefined) {
    store.dispatch('layout/updateShowSidebar', to.meta.showSidebar);
  } else {
    store.dispatch('layout/updateShowSidebar', true);
  }

  store.dispatch('layout/updateReadyState', true);
  next();
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach() {
  NProgress.done();
}

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior(to, from, savedPosition) {
  if (to.query.read || from.query.read) {
    // Do not scroll
    return false;
  }

  if (savedPosition) {
    return savedPosition;
  }

  if (to.hash) {
    return { selector: to.hash };
  }

  const [component] = router.getMatchedComponents({ ...to }).slice(-1);

  if (component && component.scrollToTop === false) {
    return {};
  }

  return new Promise(resolve => {
    setTimeout(() => {
      resolve({ x: 0, y: 0 });
    });
  });
}
