<template>
  <b-modal size="lg" id="MediumModal" centered hide-header hide-footer>
    <div class="px-4 py-3">
      <h4 class="mb-3 font-weight-bold">Connect A Medium Blog</h4>
      <h6>Please follow the following instructions to connect your medium blog.</h6>
      <ol>
        <li>
          <!-- Install and activate the eslint-disable-next-line prettier/prettier -->
          <a target="_blank" href="https://medium.com/me/settings">
            Click here to access your medium blog settings page
          </a>
        </li>
        <li>
          Click on "Security and Apps" tab, then scroll down to Integration tokens
        </li>
        <li>
          Click on Integration tokens
        </li>
        <li>
          Input a description for the token preferably "SocialAgency Integration"
        </li>
        <li>
          Then click on "Get Integration token"
        </li>
        <li>
          Copy this token without any extra space and paste in the input field below.
        </li>
      </ol>
      <p class="text-danger"><em>Please do not share this token with any one</em></p>
      <p class="font-weight-bold">
        <svg-icon name="video-player" class="mr-1 text-secondary" />
        <a href="https://www.youtube.com/watch?v=YgKlVNQs4mAzhjIV" target="_blank">Watch a step-by-step video</a>
      </p>

      <alert-errors :form="form" message="Some thing's not right" />
      <b-row>
        <b-col md="12" class="mb-3">
          <b-form-group>
            <template slot="label">
              Generated Token
            </template>
            <b-form-input
              v-model="form.token"
              name="token"
              type="text"
              placeholder="Integration token"
              autocomplete="off"
            />
            <has-error :form="form" field="token"></has-error>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="mt-4 text-right">
        <b-button variant="clear" class="px-3 mr-2" :disabled="form.busy" size="sm" @click="hide"> Cancel </b-button>
        <b-button @click="connectMedium" variant="primary" :disabled="form.busy" size="sm" class="px-3">
          <b-spinner v-if="form.busy" small />
          Connect
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Form from 'vform';
import { mapState } from 'vuex';
import { CONNECT_MEDIUM_BLOG_MUTATION } from '~/graphql/mutations';

export default {
  props: {
    queryType: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      form: new Form({
        token: '',
      }),
    };
  },

  computed: mapState({
    workspace: state => state.workspace.current,
  }),

  methods: {
    connectMedium() {
      this.form.errors.clear();

      this.form.busy = true;

      return this.$apollo
        .mutate({
          mutation: CONNECT_MEDIUM_BLOG_MUTATION,
          variables: {
            workspace: this.workspace.id,
            ...this.form.data(),
          },
        })
        .then(resp => {
          const {
            data: { savedAccount },
          } = resp;

          if (savedAccount.status) {
            window.location.href = savedAccount.url;
          }

          this.form.busy = false;
          this.hide();
        })
        .catch(({ graphQLErrors: errors }) => {
          console.log('medium connect errors', errors);
          console.dir(errors);

          let shownError = false;
          errors.forEach(error => {
            if (error.message == 'InvalidAccessToken') {
              this.form.errors.set('url', 'Invalid access token. Please try again');
              shownError = true;
            }
          });

          if (!shownError) {
            this.form.errors.set('technical', 'An error occurred while connecting medium blog');
          }

          this.form.busy = false;
        });
    },

    hide() {
      this.$root.$emit('bv::hide::modal', 'MediumModal');
    },
  },
};
</script>
