// state
export const state = {
  sayAsInterpretations: [
    {
      name: null,
      label: 'Normal',
      description: 'Pronounce as a normal word or phrase',
    },
    {
      name: 'spell-out',
      label: 'Spell out',
      description: 'Spell out each letter of the text, as in a-b-c.',
    },
    {
      name: 'cardinal',
      label: 'Cardinal',
      description: 'Pronounce as a cardinal number, as in 1,234.',
    },
    {
      name: 'ordinal',
      label: 'Ordinal',
      description: 'Pronounce as an ordinal number, as in 1,234th.',
    },
    {
      name: 'digits',
      label: 'Digits',
      description: 'Spell out each digit individually, as in 1-2-3-4.',
    },
    {
      name: 'fraction',
      label: 'Fraction',
      description: 'Pronounce as a fraction. 3+1/2 is pronounced "three and a half."',
    },
    {
      name: 'unit',
      label: 'Unit',
      description: 'Pronounce as a measurement. as in 1meter.',
    },
    {
      name: 'date',
      label: 'Date',
      description: 'Pronounce as a date.',
    },
    {
      name: 'time',
      label: 'Time',
      description: 'Pronounce as duration, in minutes and seconds, as in 1\'21".',
    },
    {
      name: 'address',
      label: 'Address',
      description: 'Pronounce as part of a street address.',
    },
    {
      name: 'expletive',
      label: 'Expletive',
      description: '"Beeps out" the text.',
    },
    {
      name: 'telephone',
      label: 'Telephone',
      description: 'Pronounce as a 7-digit or 10-digit telephone number, as in 2025551212.',
    },
  ],
  sayAsDateFormats: [
    {
      name: 'mdy',
      label: 'Month-day-year',
      description: 'Example: 12-31-2020',
    },
    {
      name: 'dmy',
      label: 'Day-month-year',
      description: 'Example: 31-12-2020',
    },
    {
      name: 'ymd',
      label: 'Year-month-day',
      description: 'Example: 2020-12-31',
    },
    {
      name: 'md',
      label: 'Month-day',
      description: 'Example: 12-31',
    },
    {
      name: 'dm',
      label: 'Day-month',
      description: 'Example: 31-12',
    },
    {
      name: 'ym',
      label: 'Year-month',
      description: 'Example: 2020-12',
    },
    {
      name: 'my',
      label: 'Month-year',
      description: 'Example: 12-2020',
    },
    {
      name: 'd',
      label: 'Day',
      description: 'Example: 31',
    },
    {
      name: 'm',
      label: 'Month',
      description: 'Example: 12',
    },
    {
      name: 'y',
      label: 'Year',
      description: 'Example: 2020',
    },
    {
      name: 'yyyymmdd',
      label: 'Year-month-day',
      description: 'Example: 2020-07-31',
    },
  ],
  langs: [
    {
      name: 'arb',
      label: 'Arabic',
    },
    {
      name: 'cmn-CN',
      label: 'Chinese, Mandarin',
    },
    {
      name: 'da-DK',
      label: 'Danish',
    },
    {
      name: 'nl-NL',
      label: 'Dutch',
    },
    {
      name: 'en-AU',
      label: 'English, Australian',
    },
    {
      name: 'en-GB',
      label: 'English, British',
    },
    {
      name: 'en-IN',
      label: 'English, Indian',
    },
    {
      name: 'en-US',
      label: 'English, US',
    },
    {
      name: 'en-GB-WLS',
      label: 'English, Welsh',
    },
    {
      name: 'fr-FR',
      label: 'French',
    },
    {
      name: 'fr-CA',
      label: 'French, Canadian',
    },
    {
      name: 'hi-IN',
      label: 'Hindi',
    },
    {
      name: 'de-DE',
      label: 'German',
    },
    {
      name: 'is-IS',
      label: 'Icelandic',
    },
    {
      name: 'it-IT',
      label: 'Italian',
    },
    {
      name: 'ja-JP',
      label: 'Japanese',
    },
    {
      name: 'ko-KR',
      label: 'Korean',
    },
    {
      name: 'nb-NO',
      label: 'Norwegian',
    },
    {
      name: 'pl-PL',
      label: 'Polish',
    },
    {
      name: 'pt-BR',
      label: 'Portuguese, Brazilian',
    },
    {
      name: 'pt-PT',
      label: 'Portuguese, European',
    },
    {
      name: 'ro-RO',
      label: 'Romanian',
    },
    {
      name: 'ru-RU',
      label: 'Russian',
    },
    {
      name: 'es-ES',
      label: 'Spanish, European',
    },
    {
      name: 'es-MX',
      label: 'Spanish, Mexican',
    },
    {
      name: 'es-US',
      label: 'Spanish, US',
    },
    {
      name: 'sv-SE',
      label: 'Swedish',
    },
    {
      name: 'tr-TR',
      label: 'Turkish',
    },
    {
      name: 'cy-GB',
      label: 'Welsh',
    },
  ],
  wRoles: [
    {
      name: 'amazon:VB',
      label: 'Verb (Present Simple)',
      description: 'Interprets the word as a verb (present simple).',
    },
    {
      name: 'amazon:VBD',
      label: 'Past tense',
      description: 'Interprets the word as past tense.',
    },
    {
      name: 'amazon:VBD',
      label: 'Past participle',
      description: 'Interprets the word as a past participle.',
    },
  ],
  pauseStrengths: [
    {
      name: 'none',
      label: 'None',
      description: 'No pause. Use none to remove a normally occurring pause, such as after a period.',
    },
    {
      name: 'medium',
      label: 'Medium',
      description: 'Sets a pause of the same duration as the pause after a comma.',
    },
    {
      name: 'strong',
      label: 'Strong',
      description: 'Sets a pause of the same duration as the pause after a sentence.',
    },
    {
      name: 'x-strong',
      label: 'X-strong',
      description: 'Sets a pause of the same duration as the pause after a paragraph.',
    },
  ],
  preContent: '',
};

// getters
export const getters = {};

// mutations
export const mutations = {
  SET_PRE_CONTENT(state, content) {
    state.preContent = content;
  },
};

// actions
export const actions = {
  setPreContent({ commit }, content) {
    commit('SET_PRE_CONTENT', content);
  },
};
