<template>
  <svg :width="width" :height="height" viewBox="0 0 19.291 19.292">
    <path
      d="M7.621,10.267c-0.367,0.404-0.457,0.694-0.938,1.454c0.311,0.217,0.908,0.718,1.322,1.581
      c0.827-0.475,1.213-0.547,1.651-0.916c2.862-2.41,9.87-11.049,9.629-11.299C19.032,0.822,10.141,7.503,7.621,10.267z M5.857,12.392
      c-1.243-0.22-2.47,0.566-3.289,2.53c-0.82,1.964-2.284,2.75-2.568,2.702c1.528,0.553,6.188,1.967,7.346-3.416
      C6.854,12.917,5.857,12.392,5.857,12.392z"
    />
  </svg>
</template>

<script>
export default {
  name: 'brush',

  props: {
    width: {
      type: String,
      default: '22px',
    },
    height: {
      type: String,
      default: '18px',
    },
  },
};
</script>
