<template>
  <svg :width="width" :height="height" viewBox="0 0 490.2 490.2">
    <path
      d="M472.3,227.2h-21.4C442.3,127.4,362.8,47.9,263,39.3V17.9C263,8,255,0,245.1,0s-17.9,8-17.9,17.9v21.4
      c-99.8,8.6-179.3,88.1-187.9,187.9H17.9c-9.9,0-17.9,8-17.9,17.9C0,255,8,263,17.9,263h21.4c8.6,99.8,88.1,179.3,187.9,187.9v21.4
      c0,9.9,8,17.9,17.9,17.9c9.9,0,17.9-8,17.9-17.9v-21.4c99.8-8.6,179.3-88.1,187.9-187.9h21.4c9.9,0,17.9-8,17.9-17.9
      S482.1,227.2,472.3,227.2z M394.8,263h21.4c-8.3,80.7-72.5,144.9-153.2,153.2v-21.4c0-9.9-8-17.9-17.9-17.9s-17.9,8-17.9,17.9
      v21.4C146.5,407.9,82.3,343.7,74,263h21.4c9.9,0,17.9-8,17.9-17.9s-8-17.9-17.9-17.9H74C82.3,146.5,146.5,82.3,227.2,74v21.4
      c0,9.9,8,17.9,17.9,17.9s17.9-8,17.9-17.9V74c80.7,8.3,144.9,72.5,153.2,153.2h-21.4c-9.9,0-17.9,8-17.9,17.9S384.9,263,394.8,263
      z"
    />
    <path
      d="M245.1,142.6c-56.6,0-102.5,45.9-102.5,102.5s45.9,102.5,102.5,102.5s102.5-45.9,102.5-102.5S301.7,142.6,245.1,142.6z
       M274.5,285.5c-4.5,5.4-10.3,9.1-17.1,11c-3,0.8-4.3,2.4-4.2,5.4c0.1,3,0,6.1,0,9.1c0,2.7-1.4,4.1-4,4.2c-3.3,0.1-6.5,0.1-9.8,0
      c-2.9-0.1-4.2-1.7-4.2-4.5c0-2.2,0-4.4-0.1-6.6c0-4.9-0.2-5.1-4.9-5.8c-6-1-11.9-2.3-17.4-5c-4.3-2.1-4.8-3.2-3.5-7.7
      c0.9-3.4,1.8-6.7,2.9-10.1c1.2-3.9,2.3-4.4,5.8-2.5c6.1,3.2,12.5,4.9,19.3,5.8c4.4,0.5,8.6,0.1,12.7-1.7c7.5-3.3,8.7-12,2.3-17.3
      c-2.2-1.8-4.6-3.1-7.2-4.2c-6.6-2.9-13.5-5.1-19.7-8.9c-10.1-6.1-16.5-14.4-15.8-26.7c0.8-13.9,8.7-22.6,21.5-27.3
      c5.3-1.9,5.3-1.8,5.3-7.3c0-1.9,0-3.7,0-5.6c0.1-4.2,0.8-4.9,4.9-5c1.3,0,2.6,0,3.8,0c8.8,0,8.8,0,8.8,8.8c0,6.2,0,6.3,6.2,7.2
      c4.7,0.7,9.3,2.1,13.7,4.1c2.4,1.1,3.3,2.8,2.6,5.3c-1.1,3.8-2.1,7.6-3.4,11.4c-1.2,3.6-2.3,4.1-5.7,2.4
      c-6.9-3.3-14.1-4.7-21.7-4.3c-2,0.1-3.9,0.4-5.8,1.2c-6.6,2.9-7.6,10.1-2,14.6c2.8,2.3,6.1,3.9,9.4,5.3c5.8,2.4,11.7,4.7,17.2,7.8
      C282.2,248.4,286.9,270.5,274.5,285.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'target-icon',

  props: {
    width: {
      type: String,
      default: '22px',
    },
    height: {
      type: String,
      default: '18px',
    },
  },
};
</script>
