// state
export const state = {
  viewing: null,
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_VIEWING(state, viewing) {
    state.viewing = viewing;
  },
};

// actions
export const actions = {
  updateViewing({ commit }, viewing) {
    commit('UPDATE_VIEWING', viewing);
  },
};
