import { apolloClient } from '~/plugins/apollo';
import { USER_DETAILS_QUERY } from '~/graphql/queries';
import { each, has } from 'lodash';
import Vue from 'vue';

// state
export const state = {
  summary: {
    isLoading: false,
    isFailed: false,
    posts: 0,
    comments: 0,
    reactions: 0,
    reposts: 0,
  },
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_STATE(state, data) {
    each(data, (value, key) => {
      if (has(state, key)) {
        Vue.set(state, key, value);
      }
    });
  },
};

// actions
export const actions = {
  async fetchSummary({ commit }, payload) {
    try {
      commit('UPDATE_STATE', {
        'summary.isLoading': true,
        'summary.isFailed': false,
      });

      const { data } = await apolloClient.query({
        query: USER_DETAILS_QUERY,
        fetchPolicy: 'no-cache', // Very Important!
        context: {
          uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
        },
      });

      console.log(data, payload);
      commit('UPDATE_STATE', {
        'summary.isFailed': true,
      });
    } catch (e) {
      commit('UPDATE_STATE', {
        'summary.isFailed': true,
      });
    }

    commit('UPDATE_STATE', {
      'summary.isLoading': false,
    });
  },
};
