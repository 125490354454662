<template>
  <div class="layout">
    <slot> <router-view /> </slot>
  </div>
</template>

<script>
export default {
  name: 'BareLayout',
};
</script>
<style lang="scss">
@import '~@/scss/variables';
.layout {
  background-color: $light;
  min-height: 100vh;
  height: 100%;
}
</style>
