<template>
  <svg width="12" height="12" viewBox="135.1 -517.4 1024 1024">
    <path
      d="M137.3-195c0 17.4 7.4 34.7 19.8 47.1l441.4 409.1c27.3 24.8 71.9 24.8 99.2 0l438.9-429c27.3-24.8 27.3-66.9 0-91.7-27.3-24.8-71.9-24.8-99.2 0L648.1 122.3 256.3-242.2c-27.3-24.8-71.9-24.8-99.2 0-12.4 14.9-19.8 29.8-19.8 47.2z"
      class="c1"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'chevron-down',
};
</script>
