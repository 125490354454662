<template>
  <svg width="28px" height="21px">
    <path :fill="currentColor" fill-rule="evenodd" d="M 23 6C 23 6 33.03 20 23 20 12.9 20 23 6 23 6Z"></path>
    <path
      fill-rule="evenodd"
      d="M 22.96 21C 19.35 21 18 17.95 18 16.03 18 12.98 21.69 7.09 22.12 6.43 22.12 6.43 22.95 5.12 22.95 5.12 22.95 5.12 23.8 6.42 23.8 6.42 24.23 7.09 28 12.97 28 16.03 28 18.09 26.44 21 22.96 21ZM 22.96 6.97C 22.96 6.97 19 13.18 19 16.03 19 17.52 20.02 20 22.96 20 25.89 20 27 17.52 27 16.03 27 13.18 22.96 6.97 22.96 6.97ZM 14 6.01C 14 6.01 15 10.02 15 10.02 15 10.02 12 10.02 12 10.02 12 10.02 13 6.01 13 6.01 13 6.01 14 6.01 14 6.01ZM 18 10.02C 18 10.02 15 4.01 15 4.01 15 4.01 14 3.01 14 3.01 14 3.01 13 3.01 13 3.01 13 3.01 12 4.01 12 4.01 12 4.01 6.63 16.03 6.63 16.03 6.63 16.03 9 16.03 9 16.03 9 16.03 11 12.02 11 12.02 11 12.02 16 12.02 16 12.02 16 12.02 16.79 13 16.79 13 16.32 14.18 16 15.26 16 16.03 16 16.93 16.3 18.07 16.98 19.04 16.98 19.04 6 19.04 6 19.04 4.9 19.04 4 18.14 4 17.03 4 17.03 4 15.03 4 15.03 4 15.03 3 15.03 3 15.03 2.45 15.03 2 14.58 2 14.03 2 13.47 2.45 13.02 3 13.02 3 13.02 4 13.02 4 13.02 4 13.02 4 11.02 4 11.02 4 11.02 3 11.02 3 11.02 2.45 11.02 2 10.57 2 10.02 2 9.47 2.45 9.02 3 9.02 3 9.02 4 9.02 4 9.02 4 9.02 4 7.01 4 7.01 4 7.01 2 7.01 2 7.01 1.45 7.01 1 6.57 1 6.01 1 5.46 1.45 5.01 2 5.01 2 5.01 4 5.01 4 5.01 4 5.01 4 2 4 2 4 2 1 2 1 2 0.45 2 0 1.56 0 1 0 0.45 0.45 0 1 0 1 0 4 0 4 0 4 0 20 0 20 0 21.1 0 22 0.9 22 2 22 2 22 4.01 22 4.01 22 4.01 21 5.01 21 5.01 21 5.01 19.43 7.1 18 10.02Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'text-background',

  props: {
    currentColor: {
      type: String,
      default: 'none',
    },
  },
};
</script>
