// Auth
const Signin = () => import(/* webpackChunkName: "guest" */ '~/views/Signin').then(m => m.default || m);
const Signup = () => import(/* webpackChunkName: "guest" */ '~/views/Signup').then(m => m.default || m);
const Reset = () => import(/* webpackChunkName: "guest" */ '~/views/Reset').then(m => m.default || m);
const PasswordReset = () => import(/* webpackChunkName: "guest" */ '~/views/PasswordReset').then(m => m.default || m);
const Sso = () => import(/* webpackChunkName: "guest" */ '~/views/Sso').then(m => m.default || m);
const Verify = () => import(/* webpackChunkName: "guest" */ '~/views/Verify').then(m => m.default || m);

const Upgrade = () => import(/* webpackChunkName: "guest" */ '~/views/upgrade').then(m => m.default || m);

const ResellerIndex = () => import(/* webpackChunkName: "guest" */ '~/views/Reseller/index').then(m => m.default || m);
const ResellerHome = () => import(/* webpackChunkName: "guest" */ '~/views/Reseller/home').then(m => m.default || m);

const DemoVideoPages = () => import('~/views/Workspaces/demo.vue').then(m => m.default || m);

const JoinTeam = () => import(/* webpackChunkName: "guest" */ '~/views/JoinTeam').then(m => m.default || m);

const Settings = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views/Settings/Account/index.vue').then(m => m.default || m);
const ProfileSettings = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Account/Profile').then(m => m.default || m);
const PlanSettings = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Account/Plan').then(m => m.default || m);
const LeadSettings = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Account/Lead').then(m => m.default || m);
const BusinessSettings = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Account/Business').then(m => m.default || m);
const NotificationSettings = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Account/Notifications').then(m => m.default || m);
const BillingSettings = () =>
  import(/* webpackChunkName: "dashboard" */ '~/views//Settings/Account/Billing').then(m => m.default || m);

const Workspaces = () => import(/* webpackChunkName: "guest" */ '~/views/Workspaces/index').then(m => m.default || m);
const WorkspacesHome = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/home').then(m => m.default || m);
const CreateWorkspace = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/create').then(m => m.default || m);
// const ManageTeamPage = () =>
// import(/* webpackChunkName: "guest" */ '~/views/Workspaces/ManageTeam').then(m => m.default || m);

const Workspace = () => import(/* webpackChunkName: "guest" */ '~/views/Workspace/index').then(m => m.default || m);

const NotFound = () => import(/* webpackChunkName: "guest" */ '~/views/errors/NotFound').then(m => m.default || m);
const SrView = () => import(/* webpackChunkName: "guest" */ '~/views/SrView').then(m => m.default || m);
const PdfView = () => import(/* webpackChunkName: "guest" */ '~/views/PdfView').then(m => m.default || m);

// ================================== Business Setup  ============================================
const Businesses = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Business/index').then(m => m.default || m);
const BusinessHome = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Business/home').then(m => m.default || m);
const CreateBusiness = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Business/create').then(m => m.default || m);

const CreateBrand = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Brand/create').then(m => m.default || m);

const BonusPage = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Others/bonus').then(m => m.default || m);

const ClientFinderPage = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Others/finder').then(m => m.default || m);

const NichesPage = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Others/niches').then(m => m.default || m);

// ================================== Teams Setup  ============================================
const Teams = () => import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Teams/index').then(m => m.default || m);
const TeamsHome = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Teams/home').then(m => m.default || m);
const CreateTeam = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Teams/create').then(m => m.default || m);

const DFYAssets = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Dfy/assets').then(m => m.default || m);

const DFYAgency = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Dfy/agency').then(m => m.default || m);

const DFYWebsites = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Dfy/Agency/index').then(m => m.default || m);

const DFYWebsitesEdit = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Dfy/Agency/edit').then(m => m.default || m);

const DFYWebsitesEditor = () =>
  import(/* webpackChunkName: "guest" */ '~/views/Workspaces/Dfy/Agency/editor').then(m => m.default || m);

import WorkspaceChildren from './WorkspaceChildren';

export default [
  {
    path: '/sr',
    component: SrView,
    meta: {
      layout: 'bare',
    },
  },
  {
    path: '/srpdf',
    component: PdfView,
    meta: {
      layout: 'bare',
    },
  },

  {
    path: '/login',
    name: 'signin',
    component: Signin,
    meta: {
      layout: 'auth',
      guest: true,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      layout: 'auth',
      guest: true,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      layout: 'auth',
      guest: true,
    },
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: PasswordReset,
    meta: {
      layout: 'auth',
      guest: true,
    },
  },
  {
    path: '/sso',
    name: 'sso',
    component: Sso,
    meta: {
      layout: 'bare',
      guest: true,
    },
  },
  {
    path: '/auth/:token',
    name: 'token',
    component: Signin,
    meta: {
      layout: 'auth',
      guest: true,
    },
  },
  {
    path: 'demo-videos',
    name: 'demo.video',
    component: DemoVideoPages,
    meta: {
      layout: 'workspace',
      requiresAuth: true,
    },
  },
  {
    path: '/reseller',
    component: ResellerIndex,
    children: [
      {
        path: '',
        name: 'reseller',
        component: ResellerHome,
        meta: {
          layout: 'reseller',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/workspaces',
    component: Workspaces,
    children: [
      {
        path: '',
        name: 'workspaces',
        component: WorkspacesHome,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'create',
        name: 'workspaces.create',
        component: CreateWorkspace,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/business',
    component: Businesses,
    children: [
      {
        path: '',
        name: 'business',
        component: BusinessHome,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'create',
        name: 'business.create',
        component: CreateBusiness,
        meta: {
          type: 'business-container',
          layout: 'workspace',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/teams',
    component: Teams,
    children: [
      {
        path: '',
        name: 'teams',
        component: TeamsHome,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'create',
        name: 'team.create',
        component: CreateTeam,
        meta: {
          type: 'business-container',
          layout: 'workspace',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/brand/create',
    name: 'brand.create',
    component: CreateBrand,
    meta: {
      type: 'business-container',
      layout: 'workspace',
      requiresAuth: true,
    },
  },
  {
    path: '/bonus',
    name: 'bonus.index',
    component: BonusPage,
    meta: {
      type: 'business-container',
      layout: 'workspace',
      requiresAuth: true,
    },
  },
  {
    path: '/finder',
    name: 'client.finder',
    component: ClientFinderPage,
    meta: {
      type: 'business-container',
      layout: 'workspace',
      requiresAuth: true,
    },
  },
  {
    path: '/niches',
    name: 'niches.index',
    component: NichesPage,
    meta: {
      type: 'business-container',
      layout: 'workspace',
      requiresAuth: true,
    },
  },
  {
    path: '/team/join',
    name: 'join-team',
    component: JoinTeam,
    meta: {
      layout: 'auth',
      requiresAuth: false,
    },
  },
  {
    path: '/dfy',
    name: 'dfy.assets',
    component: DFYAssets,
    meta: {
      type: 'business-container',
      layout: 'workspace',
      requiresAuth: true,
    },
  },
  {
    path: '/dfy/agency',
    name: 'dfy.agency',
    component: DFYAgency,
    redirect: { name: 'agency.websites' },
    children: [
      {
        path: '',
        name: 'agency.websites',
        component: DFYWebsites,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'edit/:id',
        name: 'agency.websites.edit',
        component: DFYWebsitesEdit,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'editor',
        name: 'agency.websites.editor',
        component: DFYWebsitesEditor,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/account',
    name: 'account.settings',
    component: Settings,
    redirect: { name: 'settings.profile' },
    children: [
      {
        path: 'profile',
        name: 'settings.profile',
        component: ProfileSettings,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'business',
        name: 'settings.business',
        component: BusinessSettings,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'notifications',
        name: 'settings.notifications',
        component: NotificationSettings,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'plan',
        name: 'settings.plan',
        component: PlanSettings,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'leads',
        name: 'settings.leads',
        component: LeadSettings,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
      {
        path: 'billing',
        name: 'settings.billing',
        component: BillingSettings,
        meta: {
          layout: 'workspace',
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: '/verify',
    name: 'verify',
    component: Verify,
    meta: {
      layout: 'bare',
      requiresAuth: true,
    },
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: Upgrade,
    meta: {
      layout: 'bare',
      requiresAuth: true,
    },
  },
  {
    path: '/:workspace',
    component: Workspace,
    children: WorkspaceChildren,
    meta: { type: 'workspace-container' },
  },
  {
    path: '/',
    name: 'root',
    redirect: { name: 'workspaces' },
  },
  {
    path: '*',
    component: NotFound,
    name: 'not-found',
    meta: {
      layout: 'error',
    },
  },
];
