import store from '~/store';
import Echo from 'laravel-echo';

window.io = require('socket.io-client');

// Have this in case you stop running your laravel echo server
if (typeof io !== 'undefined') {
  const token = store.state.auth.token;
  const socketHost = process.env.VUE_APP_SOCKET_IO_ENDPOINT || `${process.env.VUE_APP_API_ROOT}:6001`;

  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: socketHost,
    auth: { headers: { Authorization: token ? `Bearer ${token}` : null } },
  });
}
