<template>
  <svg width="22px" height="18px" viewBox="0 0 18 17">
    <path
      fill-rule="evenodd"
      d="M16.32 7l-3.43 3.42a3.976 3.976 0 0 1-5.63 0 .96.96 0 0 1 0-1.38.96.96 0 0 1 1.38 0c.77.76 2.1.76 2.87 0l3.42-3.43c.38-.38.6-.89.6-1.43 0-.54-.22-1.05-.6-1.44a2.04 2.04 0 0 0-2.86 0l-1.7 1.71a.99.99 0 0 1-1.39 0 .99.99 0 0 1 0-1.39l1.7-1.7a4.002 4.002 0 0 1 5.64 0c1.55 1.55 1.55 4.08 0 5.64zm-5.26 1c-.38.39-1 .39-1.39 0-.79-.79-2.07-.79-2.86 0l-3.43 3.43c-.38.38-.59.89-.59 1.43 0 .54.21 1.05.59 1.44.79.78 2.08.78 2.87 0l1.7-1.71c.38-.38 1-.38 1.38 0 .39.39.39 1.01 0 1.39l-1.7 1.7a3.976 3.976 0 0 1-5.63 0 3.964 3.964 0 0 1-1.17-2.82c0-1.06.42-2.06 1.17-2.82l3.42-3.42a3.983 3.983 0 0 1 5.64 0c.38.38.38 1 0 1.38z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'text-link',
};
</script>
