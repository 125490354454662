<template>
  <div class="chat__bar">
    <div class="chat__bar-container" v-if="!showPostComments">
      <div class="chat__bar-header">
        <div class="title">
          Client Message
        </div>
        <div>
          <!-- <span class="search">
            <svg-icon name="search" class="mr-3" />
          </span> -->
          <span class="menu">
            <svg-icon name="h-elipsis" />
          </span>
        </div>
      </div>
      <simplebar class="tabs-scroll">
        <div class="chat__bar-body">
          <spinner v-if="isLoadingUnreadMessages" size="4" style="display: block;margin: 30px auto 0;" />
          <template v-else>
            <b-row
              v-for="lastUnreadPostComment in orderedUnreadLastPostComments"
              :key="lastUnreadPostComment.post_comment_id"
              class="items-list cursor-pointer"
              @click="openChatsForComment(lastUnreadPostComment.post_comment_id)"
            >
              <b-col cols="3" class="m-0">
                <b-avatar
                  class="avatar"
                  size="2.5rem"
                  :src="lastUnreadPostComment.workspace_logo || defaultImage"
                ></b-avatar>
              </b-col>
              <b-col class="m-0">
                <div class="row d-flex justify-content-between align-center">
                  <div class="name">
                    {{ lastUnreadPostComment.name | truncate(8, '...') }}
                    <b-badge
                      v-if="isHomepage"
                      variant="success"
                      class="mx-1 p-1 text-uppercase"
                      @click.stop="openWorkspace(lastUnreadPostComment.workspace_slug)"
                    >
                      {{ lastUnreadPostComment.workspace_name | truncate(8, '...') }}
                    </b-badge>
                  </div>
                  <div class="ml-auto mr-3">
                    <span class="mx-1" v-if="isHomepage">
                      <a @click.stop="openWorkspace(lastUnreadPostComment.workspace_slug)">
                        <svg-icon name="chat-alt" />
                      </a>
                    </span>
                    <span class="mx-1">
                      <a @click.stop="openChatsForComment(lastUnreadPostComment.post_comment_id)">
                        <svg-icon name="reply" />
                      </a>
                    </span>
                    <span
                      @click.stop="
                        lastUnreadPostComment.like_id
                          ? removeCommentInsight(lastUnreadPostComment.like_id)
                          : addCommentInsight(lastUnreadPostComment.post_comment_id, 'like')
                      "
                      class="like-icon"
                      :class="{ isLiked: lastUnreadPostComment.like_id }"
                    >
                      <svg-icon name="heart" />
                    </span>
                    <span
                      @click.stop="
                        lastUnreadPostComment.favourite_id
                          ? removeCommentInsight(lastUnreadPostComment.favourite_id)
                          : addCommentInsight(lastUnreadPostComment.post_comment_id, 'favourite')
                      "
                      class="favourite-icon"
                      :class="{ isFavourite: lastUnreadPostComment.favourite_id }"
                    >
                      <svg-icon name="star" />
                    </span>
                  </div>
                </div>
                <div class="row description">
                  {{ lastUnreadPostComment.message | truncate(50, '...') }}
                </div>
              </b-col>
            </b-row>
          </template>
        </div>
      </simplebar>
    </div>
    <div class="chat_bar-container chat__bar-response" v-if="showPostComments">
      <div class="chat__bar-header w-100">
        <div>
          <a @click="openChatsForComment(0)" style="cursor: pointer;" class="collapseChatEl">
            <svg-icon name="caret-back" size="sm" />
          </a>
        </div>
        <div>
          <div class="title">
            {{ 'Chats' | truncate(10, '...') }}
            <b-badge variant="primary" class="mx-2 p-2 text-uppercase">
              {{ selectedUnreadLastPostComment.workspace_name | truncate(10, '...') }}
            </b-badge>
          </div>
        </div>
        <div>
          <span class="menu">
            <svg-icon name="h-elipsis" />
          </span>
        </div>
      </div>
      <simplebar class="tabs-scroll">
        <spinner v-if="isLoadingChats" size="4" style="display: block;margin: 30px auto 0;" />
        <template v-else>
          <div
            class="chat__response w-100"
            :class="{ right: comment.isCurrentUser }"
            v-for="comment in orderedCurrentPostComments"
            :key="comment.id"
          >
            <div class="chat__response-main m-1" v-if="!comment.isCurrentUser">
              <b-avatar class="avatar" :src="defaultImage" size="2rem" />
              <div class="mx-2">
                <div class="msg-badge bg-white p-1 border rounded border-warning">{{ comment.message }}</div>
                <p class="text-left f-12 p-0 m-0">
                  <small>{{ comment.created_at }}</small>
                </p>
              </div>
              <div v-if="false">
                <span class="like-icon" :class="{ isLiked: response.like }">
                  <svg-icon name="heart" />
                </span>
                <span class="favourite-icon" :class="{ isFavourite: response.favourite }">
                  <svg-icon name="star" />
                </span>
              </div>
            </div>
            <div v-else class="float-right">
              <div class="chat__response-sub m-1">
                <svg-icon name="check" class="mx-2" />
                <div class="msg-badge bg-white p-1 border rounded border-success">
                  {{ comment.message }}
                </div>
              </div>
              <p class="text-right f-12 p-0 m-0">
                <small>{{ comment.created_at }}</small>
              </p>
            </div>
          </div>
        </template>
      </simplebar>
      <div class="chat__response-footer w-100">
        <div class="chat__footer-item">
          <div class="chat_input-container">
            <b-form-input
              v-model="chatMessage"
              class="chat_input"
              ref="textNode"
              placeholder="Your Message"
              autocomplete="off"
            />
          </div>
          <div class="d-inline-flex">
            <div class="mx-1">
              <b-dropdown
                ref="emojiDropdown"
                class="emoji_dropdown p-0 m-0"
                toggle-class="px-0"
                size="sm"
                right
                variant="clear"
                no-caret
                dropup
              >
                <b-button
                  @click="handleInsertEmojiClick"
                  slot="button-content"
                  variant="clear"
                  class="node-btns p-0 m-0"
                >
                  <svg-icon name="wink" />
                </b-button>
                <EmojiList @select="handleSelectEmoji" />
              </b-dropdown>
            </div>
            <div class="mx-1 mt-1 cursor-pointer" @click.prevent="addComment">
              <svg-icon name="paper-plane" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import EmojiList from '~/components/EmojiList';
import { LAST_UNREAD_POST_COMMENTS_QUERY, POST_COMMENTS_WITH_INSIGHTS_AND_ANALYTICS_QUERY } from '~/graphql/queries';
import { createNamespacedHelpers } from 'vuex';
import {
  ADD_POST_COMMENT_MUTATION,
  ADD_POST_COMMENT_INSIGHT_MUTATION,
  DELETE_POST_COMMENT_INSIGHT_MUTATION,
} from '~/graphql/mutations';
import { isArray, forEach, orderBy, findLast } from 'lodash';
const isEdge = /Edge\/\d+/.exec(navigator.userAgent) !== null;

const workspaceNamespace = createNamespacedHelpers('workspace');

export default {
  components: { simplebar, EmojiList },
  savedSelection: null,

  data() {
    return {
      chatMessage: '',
      unreadLastPostComments: [],
      hasMoreUnreadLastPostComments: false,
      hasMoreCurrentPostComments: false,
      selectedUnreadLastPostCommentId: 0,
      currentPostComments: [],
      defaultImage: require('@/assets/icons/new/avatar.png'),
    };
  },

  apollo: {
    unreadLastPostComments: {
      query: LAST_UNREAD_POST_COMMENTS_QUERY,
      variables() {
        return {};
      },
      update({ lastUnreadPostComments }) {
        return lastUnreadPostComments;
      },
    },
    currentPostComments: {
      query: POST_COMMENTS_WITH_INSIGHTS_AND_ANALYTICS_QUERY,
      variables() {
        return {
          postId: parseInt(this.currentPostId, 10),
        };
      },
      skip() {
        return !parseInt(this.currentPostId, 10);
      },
      update({ postCommentsWithAnalytics: { postComments, analytics } }) {
        this.hasMore = !postComments.length;
        this.totalComment = analytics.total;
        return postComments;
      },
    },
  },

  computed: {
    orderedUnreadLastPostComments() {
      return orderBy(this.unreadLastPostComments, ['date'], ['desc']);
    },
    orderedCurrentPostComments() {
      return orderBy(this.currentPostComments, ['date'], ['asc']);
    },
    isHomepage() {
      return this.$route.name === 'workspaces';
    },
    showPostComments() {
      return !!this.currentPostId;
    },
    isLoadingUnreadMessages() {
      return this.$apollo.queries.unreadLastPostComments.loading;
    },
    isLoadingChats() {
      return this.$apollo.queries.currentPostComments.loading;
    },
    ...workspaceNamespace.mapState({
      workspaces: state => state.workspaces,
    }),
    selectedUnreadLastPostComment() {
      return this.unreadLastPostComments.find(
        comment => String(comment.post_comment_id) === String(this.selectedUnreadLastPostCommentId),
      );
    },
    currentPostId() {
      const selectedComment = this.selectedUnreadLastPostComment;
      if (selectedComment) return selectedComment.post_id;
      return 0;
    },
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  watch: {
    currentPostComments(comments) {
      if (this.showPostComments && comments.length) {
        const lastReadComment = findLast(comments, comment => comment.hasRead === true);
        const lastReadCommentId = lastReadComment ? String(lastReadComment.id) : 0;

        const lastUnreadComment = findLast(comments, comment => String(comment.id) > lastReadCommentId);
        const lastUnreadCommentId = lastUnreadComment ? lastReadComment.id : 0;

        if (lastUnreadCommentId) {
          this.addCommentInsight(lastUnreadCommentId, 'read');
        }
      }
    },
  },

  methods: {
    async addComment() {
      const newComment = this.chatMessage;
      this.chatMessage = '';

      try {
        await this.$apollo.mutate({
          mutation: ADD_POST_COMMENT_MUTATION,
          variables: {
            postId: parseInt(this.currentPostId, 10),
            message: newComment,
          },
          refetchQueries: [
            {
              query: POST_COMMENTS_WITH_INSIGHTS_AND_ANALYTICS_QUERY,
              variables: {
                postId: parseInt(this.currentPostId, 10),
              },
            },
          ],
        });
      } catch ({ graphQLErrors: errors }) {
        this.chatMessage = newComment;
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'ERROR_ADDING_POST_COMMENT') {
              errorText = 'A server error occurred adding post comment';
            }

            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error',
          text: errorText || 'An error occurred while saving post comment',
        });
      }
    },
    async addCommentInsight(commentId, action) {
      try {
        await this.$apollo.mutate({
          mutation: ADD_POST_COMMENT_INSIGHT_MUTATION,
          variables: {
            postCommentId: parseInt(commentId, 10),
            action,
          },
          refetchQueries: [
            {
              query: LAST_UNREAD_POST_COMMENTS_QUERY,
              variables: {},
            },
          ],
        });
      } catch (e) {
        return false;
      }
    },
    async removeCommentInsight(postCommentInsightId) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_POST_COMMENT_INSIGHT_MUTATION,
          variables: {
            postCommentInsightId: parseInt(postCommentInsightId, 10),
          },
          refetchQueries: [
            {
              query: LAST_UNREAD_POST_COMMENTS_QUERY,
              variables: {},
            },
          ],
        });
      } catch (e) {
        return false;
      }
    },
    openChatsForComment(commentId) {
      this.selectedUnreadLastPostCommentId = commentId;
    },
    openWorkspace(brandSlug) {
      this.$router.push({ name: 'dashboard', params: { workspace: brandSlug } });
    },
    saveSelection() {
      if (window.getSelection) {
        const selection = window.getSelection();
        if (selection.getRangeAt && selection.rangeCount) {
          let range = selection.getRangeAt(0);

          if (this.isEditorElement(range.startContainer) || this.isEditorElement(range.startContainer.parentNode)) {
            return range;
          }

          this.moveCursorToEnd();

          return selection.getRangeAt(0);
        }
      } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange();
      }

      return null;
    },
    restoreSelection(range) {
      if (range) {
        if (window.getSelection) {
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
        } else if (document.selection && range.select) {
          range.select();
        }
      }

      this.savedSelection = null;
    },
    isEditorElement(element) {
      return element && element.getAttribute && !!element.getAttribute('data-post-editor-element');
    },
    insertHTMLCommand(doc, html) {
      var selection,
        range,
        el,
        fragment,
        node,
        lastNode,
        toReplace,
        res = false,
        ecArgs = ['insertHTML', false, html];

      /* Edge's implementation of insertHTML is just buggy right now:
       * - Doesn't allow leading white space at the beginning of an element
       * - Found a case when a <font size="2"> tag was inserted when calling alignCenter inside a blockquote
       *
       * There are likely other bugs, these are just the ones we found so far.
       * For now, let's just use the same fallback we did for IE
       */
      if (!isEdge && doc.queryCommandSupported('insertHTML')) {
        try {
          return doc.execCommand.apply(doc, ecArgs);
        } catch (ignore) {
          // Do nothing
        }
      }

      selection = doc.getSelection();
      if (selection.rangeCount) {
        range = selection.getRangeAt(0);
        toReplace = range.commonAncestorContainer;

        // If the selection is an empty editor element, create a temporary text node inside of the editor
        // and select it so that we don't delete the editor element
        if (this.isEditorElement(toReplace) && !toReplace.firstChild) {
          range.selectNode(toReplace.appendChild(doc.createTextNode('')));
        } else if (
          (toReplace.nodeType === 3 && range.startOffset === 0 && range.endOffset === toReplace.nodeValue.length) ||
          (toReplace.nodeType !== 3 && toReplace.innerHTML === range.toString())
        ) {
          // Ensure range covers maximum amount of nodes as possible
          // By moving up the DOM and selecting ancestors whose only child is the range
          while (
            !this.isEditorElement(toReplace) &&
            toReplace.parentNode &&
            toReplace.parentNode.childNodes.length === 1 &&
            !this.isEditorElement(toReplace.parentNode)
          ) {
            toReplace = toReplace.parentNode;
          }
          range.selectNode(toReplace);
        }
        range.deleteContents();

        el = doc.createElement('div');
        el.innerHTML = html;
        fragment = doc.createDocumentFragment();
        while (el.firstChild) {
          node = el.firstChild;
          lastNode = fragment.appendChild(node);
        }
        range.insertNode(fragment);

        // Preserve the selection:
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          selection.selectRange(doc, range);
        }
        res = true;
      }

      // If we're monitoring calls to execCommand, notify listeners as if a real call had happened
      if (doc.execCommand.callListeners) {
        doc.execCommand.callListeners(ecArgs, res);
      }
      return res;
    },
    handleSelectEmoji(emoji) {
      this.restoreSelection(this.savedSelection);
      this.insertHTMLCommand(document, emoji);

      this.savedSelection = this.saveSelection();

      // Don't hide the emoji panel yet
      this.$refs.emojiDropdown.show();
    },
    handleInsertEmojiClick() {
      this.savedSelection = this.saveSelection();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.cursor-pointer {
  cursor: pointer;
}

.tabs-scroll {
  min-height: 75vh;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;

  .simplebar-scrollbar {
    width: 5px;
  }

  .simplebar-scrollbar:before {
    border-radius: 5px;
    background: darken($primary, 30);
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
}
.chat__bar {
  position: relative;
  &-response {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  &-container {
    width: 100%;
    height: 100%;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 12px;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
  &-body {
    margin: 8px;
    .items-list {
      margin: 5px 0;
      padding: 2px 0;
      border-bottom: 2px solid #c4c4c4;
      text-align: left;
      .name {
        font-size: 14px;
        font-weight: bold;
      }
      .description {
        font-size: 12px;
        line-height: 26px;
        word-wrap: break-word;
      }
      &:hover {
        background-color: #ffffff;
        border-radius: 15px;
        border-bottom: none;
      }
    }
  }
  .like-icon {
    margin: 0 3px;
    svg {
      fill: transparent;
      stroke: #000;
    }
    &.isLiked,
    &:hover {
      svg {
        fill: #dd5050;
        stroke: #dd5050;
      }
    }
  }
  .favourite-icon {
    margin: 0 3px;
    svg {
      fill: transparent;
      stroke: #000;
    }
    &.isFavourite,
    &:hover {
      svg {
        fill: #f8b95b;
        stroke: transparent;
      }
    }
  }
  .chat__response {
    margin: 5px 0 20px 0;
    padding: 2px 0;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-start;

    &.right {
      justify-content: flex-end;
    }

    .msg-badge {
      width: 180px;
    }
    .msg-badge-1 {
      width: 150px;
    }
    &-main {
      width: 90%;
      display: flex;
      // align-items: center;
    }
    &-sub {
      float: right;
      display: flex;
      align-items: center;
      padding: 0;
    }
    &-footer {
      position: absolute;
      bottom: -6px;
      right: 0;
      left: 0;
      .chat__footer-item {
        background: $white;
        display: flex;
        align-content: center;
        padding: 6px;
        .chat_input-container {
          width: 90%;
        }
        .chat_input,
        .chat_input:focus,
        .chat_input:active {
          border: none;
          outline: none;
          background: transparent;
          width: 100%;
        }
      }
    }
    .node-btns,
    .node-btns:hover,
    .node-btns:focus {
      background: transparent;
      border: none;
      outline: none;
    }
    .emoji_dropdown {
      .btn {
        &:focus {
          box-shadow: none;
        }
      }

      .dropdown-menu {
        min-width: 350px;
        margin-top: 5px;
        border: 1px solid rgba(102, 107, 133, 0.05);
        box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.5);

        &:after,
        &:before {
          position: absolute;
          display: inline-block;
          border-bottom-color: rgba(0, 0, 0, 0.2);
          content: '';
        }

        &:before {
          top: -7px;
          right: 15px;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          border-bottom: 7px solid #ebebeb;
        }

        &:after {
          top: -6px;
          right: 16px;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #fff;
          border-left: 6px solid transparent;
        }
      }
    }
  }
}
</style>
