<template>
  <svg
    :height="height"
    :width="width"
    style="enable-background:new 0 0 447.674 447.674;"
    version="1.1"
    viewBox="0 0 447.674 447.674"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M134.289,138.16h-24.722l67.399,190.521l37.732-107.825l-29.254-82.696H159.36v-18.154h115.508   v18.154h-27.049l67.398,190.521l24.227-69.234c31.781-88.702-26.048-116.333-26.048-136.129s16.048-35.843,35.843-35.843   c1.071,0,2.111,0.058,3.13,0.153c-33.541-31.663-78.768-51.08-128.534-51.08c-65.027,0-122.306,33.146-155.884,83.458h66.336   v18.154L134.289,138.16L134.289,138.16z"
        style="fill:#00769D;"
      />
      <path
        d="M36.548,223.837c0,71.704,40.302,133.986,99.483,165.458l-84.52-238.919   C41.883,172.932,36.548,197.761,36.548,223.837z"
        style="fill:#00769D;"
      />
      <path
        d="M386.833,131.547c2.679,15.774,1.868,33.503-2.243,51.301h0.745l-2.832,8.092l0,0   c-1.678,5.843-3.791,11.82-6.191,17.693l-64.444,180.541c59.057-31.51,99.256-93.725,99.256-165.338   C411.124,190.279,402.29,158.788,386.833,131.547z"
        style="fill:#00769D;"
      />
      <path
        d="M166.075,402.033c18.195,5.894,37.603,9.091,57.762,9.091c19.228,0,37.777-2.902,55.239-8.285   l-54.784-154.862L166.075,402.033z"
        style="fill:#00769D;"
      />
      <path
        d="M382.113,65.56C339.836,23.283,283.625,0,223.836,0S107.837,23.283,65.56,65.56S0,164.047,0,223.837   c0,59.789,23.283,115.999,65.56,158.276s98.488,65.56,158.277,65.56s115.999-23.283,158.277-65.56   c42.277-42.277,65.56-98.488,65.56-158.276C447.673,164.047,424.39,107.837,382.113,65.56z M223.836,431.883   c-114.717,0-208.046-93.329-208.046-208.046S109.119,15.79,223.836,15.79s208.046,93.33,208.046,208.047   S338.554,431.883,223.836,431.883z"
        style="fill:#00769D;"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'wordpress-icon',

  props: {
    width: {
      type: String,
      default: '55px',
    },

    height: {
      type: String,
      default: '55px',
    },
  },
};
</script>
