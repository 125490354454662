<template>
  <svg width="20" height="22" viewBox="0 0 20 22" class="symbol symbol-textIndentLeft">
    <path d="M4 3h13v2H4zM4 15h13v2H4zM10 6h7v2h-7zM10 12h7v2h-7zM10 9h7v2h-7z"></path>
    <g>
      <path
        d="M4.1 12.9c-.3 0-.6-.1-.8-.4-.3-.5-.2-1.1.2-1.4l1.6-1.2-1.6-1.2c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2l3.8 2.8-3.8 2.8c-.1.1-.4.2-.6.2z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'text-indent-left',
};
</script>
