import Vue from 'vue';
import app from '~/main';
import store from '~/store';
import Swal from 'sweetalert2';
import VueApollo from 'vue-apollo';
import { ApolloLink } from 'apollo-link';
import { omitDeep } from '~/utils/helpers';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import introspectionQueryResultData from '../fragmentTypes.json';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from store if it exists
  const token = store.state.auth.token;

  if (token) {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    };
  }
});

const errorLink = onError(errors => {
  const { graphQLErrors, networkError } = errors;
  console.log(errors);
  if (networkError) {
    const { statusCode, result } = networkError;
    if (statusCode === 402 && result.message === 'NoSubscriptionOrExpired') {
      if (app.$route.name !== 'upgrade') {
        app.$router.push({ name: 'upgrade' });
      }

      return false; // Do not retry request
    } else {
      console.log(`[Network error]: ${networkError}`);
    }
  }

  if (graphQLErrors) {
    graphQLErrors.map(async ({ message }) => {
      if (message === 'Unauthorized' && app.$route.name && app.$route.name !== 'signin') {
        await Swal.queue([
          {
            type: 'warning',
            title: 'Session Expired!',
            text: 'Please log in again to continue.',
            confirmButtonText: 'Okay',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return store
                .dispatch('auth/logout')
                .then(() => {
                  app.$router.push({ name: 'signin', query: { next: app.$route.path } }).catch(() => {});
                })
                .catch(() => {
                  Swal.insertQueueStep({
                    type: 'error',
                    title: 'OOPS!',
                    text: 'Something went wrong. Please refresh this page.',
                  });
                });
            },
          },
        ]);
      }
    });
  }
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map(data => {
    return data;
  });
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  }),
  connectToDevTools: true,
  link: ApolloLink.from([
    authLink,
    errorLink,
    cleanTypenameLink,
    createUploadLink({
      // Absolute URL to graphql server
      uri: `${process.env.VUE_APP_API_ROOT}/gql`,
    }),
  ]),
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.use(VueApollo);
