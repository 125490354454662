import './axios';
import './websocket';
import './bootstrap';

import Vue from 'vue';
import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

Vue.use(Notifications, { velocity });
