<template>
  <svg :width="width" :height="height" viewBox="0 0 489.773 489.773">
    <g>
      <path
        d="M244.886,207.017c-20.927,0-37.87,16.958-37.87,37.87c0,20.944,16.942,37.87,37.87,37.87
        s37.87-16.94,37.87-37.87C282.756,223.974,265.797,207.016,244.886,207.017z"
      />
      <path
        d="M489.773,250.299c0-35.909-23.55-69.211-66.396-94.021v-5.498
        c2.198-26.238-0.281-51.391-7.172-72.744c-7.299-22.615-19.259-39.849-34.59-49.839c-13.656-8.875-29.68-13.375-47.624-13.375
        c-26.266,0-56.236,12.117-89.104,36.019c-32.871-23.902-62.842-36.019-89.105-36.019c-17.946,0-33.97,4.501-47.63,13.379
        c-15.326,9.986-27.285,27.22-34.583,49.835c-6.892,21.354-9.371,46.507-7.173,72.745v5.497C23.55,181.091,0,214.393,0,250.299
        c0,37.677,25.725,72.209,72.491,97.391c-0.072,0.642-0.132,1.284-0.17,1.916c-3.858,53.21,11.138,93.324,42.224,112.956
        c13.031,8.221,28.149,12.389,44.934,12.389c25.863,0,55.362-10.074,85.409-29.15c30.045,19.074,59.544,29.148,85.407,29.15h0.004
        c16.783,0,31.898-4.168,44.932-12.39c31.086-19.632,46.081-59.736,42.227-112.891c-0.039-0.66-0.104-1.329-0.174-1.98
        C464.048,322.508,489.773,287.976,489.773,250.299z M271.957,116.629c-15.474-0.76-38.67-0.76-54.144,0
        c8.731-8.726,17.808-16.88,27.073-24.319C254.144,99.743,263.221,107.899,271.957,116.629z M341.077,303.176
        c-12.728,20.373-19.332,27.478-31.32,40.374c-1.164,1.254-2.378,2.559-3.651,3.936l-6.005,6.487
        c-15.686,1.911-31.903,2.88-48.267,2.88h-13.896c-16.358,0-32.572-0.969-48.269-2.88l-6.004-6.488
        c-1.273-1.377-2.489-2.684-3.654-3.938c-11.988-12.896-18.591-20.003-31.315-40.368c-9.737-15.612-17.849-30.22-24.775-44.61
        c9.596-23.298,21.305-45.934,34.817-67.307c9.793-15.595,20.561-30.613,32.008-44.646c31.765-3.694,76.515-3.694,108.28,0
        c11.446,14.033,22.214,29.052,32.016,44.659c13.505,21.36,25.211,43.994,34.807,67.294
        C358.929,272.951,350.817,287.559,341.077,303.176z M378.903,295.337c3.622,12.382,6.375,24.505,8.205,36.142
        c-13.536,5.826-28.086,10.691-43.407,14.519c7.29-8.831,9.872-12.537,19.241-25.987l1.22-1.751
        C369.589,310.456,374.447,302.907,378.903,295.337z M146.069,345.994c-15.334-3.832-29.878-8.693-43.404-14.516
        c1.831-11.637,4.583-23.759,8.206-36.14c4.455,7.565,9.311,15.115,14.744,22.925l1.235,1.771
        C136.064,333.262,138.777,337.156,146.069,345.994z M76.609,318.193c-31.652-19.18-49.023-43.185-49.023-67.894
        c0-23.358,15.704-46.306,44.353-65.027l0.349,2.15c4.227,26.066,11.08,49.926,20.941,72.897
        C85.78,280.08,80.197,299.523,76.609,318.193z M93.603,141.837c-2.054-41.961,9.563-77.474,29.603-90.478
        c9.078-5.93,20.038-8.937,32.576-8.937c19.832,0,43.478,9.799,70.318,29.132c-17.079,13.903-33.804,30.362-49.773,48.986
        c-30.5,4.41-58.269,11.881-82.678,22.233L93.603,141.837z M98.011,171.089c15.669-7.133,33.289-13.001,52.549-17.501
        c-5.263,7.392-10.396,15.045-15.316,22.83c-9.623,15.314-18.29,30.861-25.818,46.306c-5.085-15.323-8.572-30.337-10.623-45.702
        L98.011,171.089z M160.544,446.602c-11.664,0.002-22.001-2.786-30.724-8.287c-20.377-12.839-31.039-40.529-30.077-78.041
        c20.053,7.902,41.884,13.938,65.078,17.998l8.576,8.941c14.903,15.535,30.626,29.273,46.789,40.886
        C198.632,440.217,178.085,446.602,160.544,446.602z M208.869,383.506c10.075,0.665,19.627,0.991,29.071,0.991h13.893
        c9.445,0,18.998-0.326,29.071-0.991c-11.795,10.826-23.899,20.343-36.017,28.322C232.771,403.852,220.665,394.333,208.869,383.506
        z M359.953,438.316c-8.722,5.499-19.059,8.286-30.725,8.286c-17.541,0-38.087-6.385-59.643-18.503
        c16.165-11.613,31.887-25.351,46.789-40.886l8.577-8.941c23.198-4.062,45.025-10.097,65.076-17.998
        C390.99,397.784,380.329,425.476,359.953,438.316z M390.969,177.022c-2.05,15.363-5.537,30.377-10.622,45.702
        c-7.528-15.448-16.198-30.999-25.824-46.317c-4.92-7.784-10.052-15.433-15.31-22.818c19.255,4.499,36.876,10.368,52.546,17.5
        L390.969,177.022z M396.17,141.836l-0.047,0.938c-24.423-10.355-52.191-17.825-82.678-22.233
        c-15.966-18.618-32.691-35.08-49.775-48.986c26.843-19.333,50.489-29.132,70.32-29.132c12.539,0,23.501,3.008,32.59,8.945
        C386.609,64.364,398.224,99.875,396.17,141.836z M413.163,318.193c-3.587-18.67-9.17-38.112-16.62-57.873
        c9.859-22.967,16.714-46.826,20.94-72.897l0.35-2.149c28.653,18.727,44.354,41.672,44.354,65.025
        C462.187,275.008,444.816,299.013,413.163,318.193z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'orbitals-icon',

  props: {
    width: {
      type: String,
      default: '22px',
    },
    height: {
      type: String,
      default: '18px',
    },
  },
};
</script>
