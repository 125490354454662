import Vue from 'vue';

import { apolloClient } from '~/plugins/apollo';

import { GET_ARTICLE_QUERY } from '~/graphql/queries';

// state
export const state = {
  invoked: null,
  busy: false,
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_BUSY(state, busy) {
    state.busy = busy;
  },
  UPDATE_INVOKED(state, invoked) {
    state.invoked = invoked;
  },
};

// actions
export const actions = {
  invoke({ commit }, article) {
    commit('UPDATE_INVOKED', article);
  },

  fetchText({ commit, state }, articleId) {
    commit('UPDATE_BUSY', true);

    apolloClient
      .query({
        query: GET_ARTICLE_QUERY,
        variables: {
          id: articleId,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_BUSY', false);

        commit('UPDATE_INVOKED', {
          ...state.invoked,
          content: data.getArticle,
        });
      })
      .catch(() => {
        commit('UPDATE_BUSY', false);

        commit('UPDATE_INVOKED', null);

        Vue.notify({
          group: 'main',
          type: 'native-error',
          text: 'An error occurred while processing your request.',
        });
      });
  },
};
