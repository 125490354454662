<template>
  <div class="layout">
    <slot> <router-view /> </slot>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
const accessControl = createNamespacedHelpers('accessControl');

export default {
  name: 'WorkspaceLayout',

  computed: {
    ...mapState('auth', {
      user: state => state.user,
      authBusy: state => state.busy,
    }),

    ...accessControl.mapGetters({
      isReseller: 'isUserReseller',
    }),
  },

  methods: {},
};
</script>

<style lang="scss">
@import '~@/scss/variables';
* {
  padding: 0;
  margin: 0;
}
.layout {
  background: $light;
}
</style>
