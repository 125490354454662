<template>
  <svg width="22px" height="18px" viewBox="0 0 75.999 75.999">
    <path
      d="M14.579,5C6.527,5,0,11.716,0,20c0,8.285,6.527,15,14.579,15C29.157,35,19.438,64,0,64v7
      C34.69,71,48.286,5,14.579,5z M56.579,5C48.527,5,42,11.716,42,20c0,8.285,6.527,15,14.579,15C71.157,35,61.438,64,42,64v7
      C76.69,71,90.286,5,56.579,5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'quote',
};
</script>
