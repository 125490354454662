<template>
  <simplebar class="emoji-picker">
    <div v-for="(emojiGroup, category) in emojis" :key="category" class="emoji-category">
      <h5>{{ category }}</h5>
      <div class="emoji-category-list">
        <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="select(emoji)" :title="emojiName">{{
          emoji
        }}</span>
      </div>
    </div>
  </simplebar>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import emojis from '~/utils/emojis';

export default {
  components: { simplebar },

  props: {
    emojis: {
      default: () => emojis,
    },
  },

  methods: {
    select(emoji) {
      this.$emit('select', emoji);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.emoji-picker {
  min-width: 300px;
  max-width: 350px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 15px;
  user-select: none;

  .simplebar-scrollbar {
    width: 5px;
  }

  .simplebar-scrollbar:before {
    border-radius: 5px;
    background: darken($primary, 17);
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }

  .emoji-category {
    margin-bottom: 1rem;

    h5 {
      font-size: 1.1rem;
    }

    .emoji-category-list {
      margin-right: -15px;

      span {
        display: inline-block;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
