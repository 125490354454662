<template>
  <svg-icon class="sentiment-icon" :class="sentiment" :name="icon" />
</template>

<script>
export default {
  props: {
    sentiment: {
      type: String,
      required: true,
    },
  },

  computed: {
    icon() {
      switch (this.sentiment) {
        case 'positive':
          return 'smile';
        case 'negative':
          return 'sad';
        case 'neutral':
        default:
          return 'neutral';
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.sentiment-icon {
  &.neutral {
    color: darkorange;
  }
  &.positive {
    color: $success;
  }
  &.negative {
    color: $danger;
  }
}
</style>
