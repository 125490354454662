// state
export const state = {
  current: null,
  business: [],
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_CURRENT(state, current) {
    state.current = current;
  },
  UPDATE_BUSINESS(state, business) {
    state.business = business;
  },
};

// actions
export const actions = {
  updateCurrent({ commit }, current) {
    commit('UPDATE_CURRENT', current);
  },
  updateBusiness({ commit }, business) {
    commit('UPDATE_BUSINESS', business);
  },
};
