<template>
  <div class="layout main-app">
    <b-row class="auth-wrap">
      <b-col class="auth-left">
        <slot> <router-view /> </slot>
      </b-col>
      <b-col class="p-2">
        <div class="auth-right d-flex justify-content-center align-items-center">
          <img src="@/assets/images/new/auth/bg-img-1.png" height="90%" alt="logo" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Auth',
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.auth-wrap {
  background-color: $light;
  min-height: 100vh;

  .auth-right {
    background-image: url('../assets/images/new/auth/bg-img-0.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    padding: 10px 5px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #efefef;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: rgba($gray-500, 0.3);
      cursor: pointer;
      height: 20px;
    }
  }
}
</style>
