<template>
  <div class="layout main-app">
    <div class="main-layout">
      <Navbar fluid />

      <b-row no-gutters class="position-relative">
        <Sidebar
          v-show="showSidebar"
          v-if="showDefaultSideBar"
          key="default"
          @toggleChatBar="showChat"
          id="chatContainerEl"
        />
        <ReportSidebar v-else-if="isAReportPage" key="report" />
        <b-col :cols="colsLength">
          <slot>
            <router-view />
          </slot>
        </b-col>
        <div v-clicked-outside="clickedOutsideChatContainer" class="floating-chat" v-if="showChatBar">
          <ChatBar />
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import ReportSidebar from '~/components/Reports/SideBar';
import ChatBar from '~/components/General/Menus/ChatBar';

const accessControl = createNamespacedHelpers('accessControl');

export default {
  name: 'MainLayout',

  components: { Navbar, Sidebar, ReportSidebar, ChatBar },

  data() {
    return {
      showChatBar: false,
    };
  },

  computed: {
    ...mapState('layout', {
      showSidebar: state => state.showSidebar,
    }),

    ...accessControl.mapGetters(['isUserClientOrApprover']),

    colsLength() {
      if (
        (this.isUserClientOrApprover && String(this.$route.name).startsWith('custom-dashboard')) ||
        !this.showSidebar
      ) {
        return 12;
      }
      return 10;
    },

    isAReportPage() {
      return String(this.$route.name).startsWith('report.');
    },

    showDefaultSideBar() {
      if (this.isAReportPage) return false;
      return !this.isUserClientOrApprover;
    },
  },

  async mounted() {
    await this.$nextTick();
    const refs = this.$refs;
    console.log(refs, 'monitor');
  },

  methods: {
    showChat() {
      if (!this.showChatBar) {
        this.showChatBar = true;
      } else {
        this.showChatBar = false;
      }
    },
    clickedOutsideChatContainer(e) {
      const toggle = document.getElementById('toggleChatBtn');
      if (toggle && toggle.contains(e.target)) {
        return;
      }

      let isFromChat = false;
      let node = e.target;
      while (!!node && node.nodeName.toLowerCase() !== 'body' && !isFromChat) {
        console.log('node is ===> ', node);
        if (
          node.classList.contains('simplebar-wrapper') ||
          node.classList.contains('chat__bar-body') ||
          node.classList.contains('collapseChatEl')
        ) {
          isFromChat = true;
        }

        node = node.parentNode;
      }

      if (isFromChat) {
        return;
      }

      this.showChatBar = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.main-layout {
  padding-top: $navbar-height + 20px;
  min-height: calc(100vh - 20px);
  position: relative;
  background: $light;

  @media (max-width: 1310px) {
    padding-top: 0;
  }
}

.sidebar-slide-enter-active {
  animation: sidebar-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.sidebar-slide-leave-active {
  animation: sidebar-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.floating-chat {
  position: absolute;
  height: 500px;
  right: 0;
  z-index: 9999;
  min-width: 25%;
  max-width: 25%;
}

@keyframes sidebar-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sidebar-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
