<template>
  <svg width="20" height="22" viewBox="0 0 20 22">
    <path d="M4 3h14v2H4zM4 15h14v2H4zM8 6h10v2H8zM8 12h10v2H8zM5 9h13v2H5z"></path>
  </svg>
</template>

<script>
export default {
  name: 'text-align-right',
};
</script>
