<template>
  <b-modal
    id="ArticlePrevivewModal"
    modal-class="article-preview-modal persist-panel"
    static
    hide-header
    hide-footer
    @hide="onHide"
  >
    <template v-slot:default="{ hide }">
      <div v-if="loading" class="loading-wrap p-5"><b-spinner variant="primary" /></div>
      <div v-if="!loading && article" class="d-flex">
        <div class="modal_left">
          <div class="brand_preview">
            <lazy-background-image :src="article.image" class="img">
              <div class="tags_block">
                <b-badge variant="primary" v-for="category in article.categories" :key="category" pill>
                  {{ capitalize(category) }}
                </b-badge>
              </div>
            </lazy-background-image>
          </div>
          <div class="article_detail_left">
            <div class="article_detail_left_inner">
              <div class="tags_block">
                <span v-for="topic in article.topics" :key="topic" class="tag">{{ topic }}</span>
              </div>
              <div class="social_stats">
                <div class="social_stats_inner d-flex ">
                  <div :id="`read-${article.id}`" class="stat_item custom_tooltip ">
                    <div class="name "><p>Engagement</p></div>
                    <div class="value">
                      <h3>{{ numFormat(article.engagement) }}</h3>
                    </div>
                  </div>
                  <SharesPopover :target="`read-${article.id}`" :article="article" />

                  <div class="stat_item">
                    <div class="name"><p>Trend Score</p></div>
                    <div class="value">
                      <h3>{{ numFormat(article.score) }}</h3>
                    </div>
                  </div>
                  <div v-b-tooltip :title="capitalize(article.sentiment)" class="stat_item">
                    <div class="name"><p>Sentiment</p></div>
                    <div class="value"><SentimentIcon :sentiment="article.sentiment" /></div>
                  </div>
                </div>
              </div>
              <div class="preview-btns text-center">
                <b-button
                  v-b-tooltip.hover
                  title="Convert to Audio/Podcast"
                  variant="clear"
                  size="sm"
                  class="px-4 mr-2"
                  @click="handleShareClick(article, 'voice')"
                >
                  <svg-icon name="play"></svg-icon>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  title="Share on Blog"
                  variant="clear"
                  size="sm"
                  class="px-4 mr-2"
                  @click="handleShareClick(article, 'blog')"
                >
                  <svg-icon name="wordpress"></svg-icon>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  title="Share on Social Media"
                  variant="clear"
                  size="sm"
                  class="px-4"
                  @click="handleShareClick(article, 'social')"
                >
                  <svg-icon name="social"></svg-icon>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal_right">
          <div class="modal_head">
            <div class="d-flex align-items-center">
              <div class="left_content"><span class="title">Article Preview</span></div>
              <div class="right_content">
                <b-button :href="article.url" variant="clear" size="sm" class="transparent-btn" target="_blank">
                  View Original Article
                </b-button>
                <b-button variant="outline-primary" size="sm" @click="hide">
                  <svg-icon name="close" class="mt-0" />
                </b-button>
              </div>
            </div>
          </div>
          <div class="article_content">
            <div id="article_wrapper" class="article_wrapper">
              <h2 class="title" v-html="descape(article.title)"></h2>
              <div class="author_detail">
                <p>
                  {{ article.domain }} - {{ article.publishDate | timeAgo }} on {{ article.publishDate | dateFormat }}
                </p>
                <p>
                  <template v-if="article.author">
                    By <span>{{ article.author }}</span>
                  </template>
                  <a
                    v-if="article.twitterUsername"
                    :href="`https://twitter.com/${article.twitterUsername}`"
                    class="d-inline-block ml-1"
                    target="_blank"
                    >{{ article.twitterUsername }}</a
                  >
                </p>
              </div>
              <div class="article_content_inner" v-html="article.content"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { decode } from 'he';
import moment from 'moment';
import { mapState } from 'vuex';
import { capitalize } from 'lodash';

import { numFormat } from '~/utils/helpers';

import SharesPopover from '~/components/Articles/SharesPopover';
import SentimentIcon from '~/components/Articles/SentimentIcon';
import LazyBackgroundImage from '~/components/LazyBackgroundImage';

export default {
  components: { LazyBackgroundImage, SentimentIcon, SharesPopover },

  computed: {
    ...mapState({
      loading: state => state.article.busy,
      article: state => state.article.invoked,
    }),

    featuredImage() {
      return this.article.image || '~@/assets/images/image-unavailable.png';
    },
  },

  filters: {
    timeAgo: function(date) {
      return moment(date).fromNow();
    },

    dateFormat: function(date) {
      return moment(date).format('MMMM Do YYYY');
    },
  },

  methods: {
    numFormat,
    capitalize,

    handleShareClick(article, type) {
      this.$store.dispatch('createPost/updatePlatformType', type);
      if (type === 'social') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          ...article,
          body: `${article.title} - <a href="${article.url}">${article.url}</a>`,
          postLink: article.url,
        });

        this.$store.dispatch('createPost/fetchLinkPreview', article.url);

        this.navigateToCompose(type);
      } else if (type === 'blog') {
        const post = this.article;

        const credits = `<div>
          <br />
          <p><em>This Post was originally published on <a href="${post.url}">${post.domain}</a></em></p>
        </div>`;

        const postContent = `
          <img src="${post.image}" alt="" />
          <hr />
          ${post.content} ${credits}
        `;

        this.$store.dispatch('createPost/updateSelectedContent', {
          ...post,
          body: postContent,
          title: post.title,
          postLink: post.url,
        });

        this.navigateToCompose(type);
      } else if (type === 'voice') {
        const post = this.article;

        const postContent = `
          <p>${post.title}</p>
          ${post.content}
        `;

        this.$store.dispatch('voiceEditor/setPreContent', postContent);

        this.$bvModal.hide('ArticlePrevivewModal');
        this.$router.push({ name: 'compose-audio', params: { type, id: 0 } });
      }
    },

    navigateToCompose(type) {
      this.$bvModal.hide('ArticlePrevivewModal');

      this.$router.push({ name: 'compose', params: { type, id: 0 } }, () => {});
    },

    descape(text) {
      return decode(text);
    },

    onHide() {
      // eslint-disable-next-line
      const { read, ...rest } = this.$route.query;
      this.$router.push({ query: rest }, () => {
        this.$store.dispatch('article/invoke', null);
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.article-preview-modal {
  padding: 0 !important;
  overflow-y: hidden !important;
  overflow-x: hidden;
  display: block;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .modal-dialog {
    max-width: 1150px;
    margin: 0;
    margin-left: auto;
    height: 100%;

    .modal-content {
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      border: 0;
      background: $light;

      .loading-wrap {
        height: 100vh;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .modal_left {
        overflow: auto;
        padding-bottom: 20px;
        width: 100%;
        background: #fff;
        box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.16);

        .brand_preview {
          padding: 15px 15px 0 15px;

          .tags_block {
            right: 0;
            left: 0;
            position: absolute;
            z-index: 1;
            padding: 10px;
            text-align: right;

            .badge {
              padding: 5px 22px;
              margin-left: 5px;
              margin-bottom: 5px;
            }
          }

          .img {
            position: relative;
            -webkit-background-size: cover !important;
            background-size: cover !important;
            background-position: 50% !important;
            background-repeat: no-repeat !important;
            height: 270px;
            width: 100%;
            border-radius: 10px;
            background-clip: padding-box;
          }
        }

        .article_detail_left {
          text-align: center;
          padding: 0 15px;

          .article_detail_left_inner {
            border-top: 1px solid rgba(152, 158, 181, 0.1);
            padding-top: 20px;
            margin-top: 20px;
          }

          .tags_block {
            text-align: center;

            .tag {
              display: inline-block;
              color: #989eb5;
              font-size: 12px;
              padding: 3px 22px;
              border-radius: 20px;
              background-clip: padding-box;
              margin-left: 5px;
              margin-bottom: 5px;
              padding: 2px 16px;
              border: 1px solid rgba(152, 158, 181, 0.2);
              background: #fff;
              box-shadow: 0 1px 2px 0 rgba(184, 189, 209, 0.3);
            }
          }

          .social_stats {
            margin-top: 15px;

            .social_stats_inner {
              border-top: 1px solid rgba(152, 158, 181, 0.15);
              padding: 13px 0;
              border-bottom: 1px solid rgba(152, 158, 181, 0.15);
            }

            .stat_item {
              width: 100%;
              text-align: center;
              border-right: 1px solid rgba(152, 158, 181, 0.15);

              &:last-child {
                border-right: 0;
              }

              .name p {
                margin: 0;
                color: #989eb5;
                font-size: 12px;
              }

              .value {
                margin-top: 10px;

                h3 {
                  font-weight: 700;
                  font-size: 16px;
                  color: #3a4557;
                }
              }
            }
          }

          .preview-btns {
            padding-top: 15px;

            .btn {
              color: #989eb5;
              background-color: #f2f7fa;
              box-shadow: 0 1px 0 0 rgba(184, 189, 209, 0.3);

              &:hover {
                background-color: #e6f2fa;
              }
            }
          }
        }
      }

      .modal_right {
        width: 720px;
        min-width: 720px;

        .modal_head {
          padding: 20px 20px;
          border-bottom: 1px solid #e5e9f7;

          .left_content .title {
            font-size: 18px;
            font-weight: 600;
            color: #3a4557;
          }

          .right_content {
            margin-left: auto;

            .btn {
              margin-left: 5px;
            }

            .transparent-btn {
              border: 1px solid #b8bdd1;
              background: transparent;
              // color: #b8bdd1;
            }
          }
        }

        .article_content {
          padding: 20px;
          height: calc(100vh - 74px);
          overflow: auto;

          .article_wrapper {
            background: #fff;
            padding: 20px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.14);
            position: relative;
          }

          .title {
            font-size: 26px;
            font-weight: 700;
            color: #3a4557;
            font-family: Merriweather, serif;
          }

          .author_detail {
            margin-top: 15px;
            margin-bottom: 15px;

            p {
              margin: 0;
              color: #989eb5;
              font-weight: 500;
              margin-bottom: 4px;
              font-size: 13px;
            }
          }

          .article_content_inner {
            img {
              max-width: 100%;
              margin-bottom: 10px;
              margin-top: 10px;
            }

            h1,
            h2 {
              font-size: 1.5rem;
            }
          }
        }
      }

      .modal-body {
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        pointer-events: auto;
        background-clip: padding-box;
        outline: 0;
      }
    }
  }
}
</style>
