// state
export const state = {
  curated: [],
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_CURATED(state, curated) {
    state.curated = curated;
  },
};

// actions
export const actions = {
  updateCurated({ commit }, isModified) {
    commit('UPDATE_CURATED', isModified);
  },
};
