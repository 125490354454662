<script>
export default {
  name: 'svg-icon',

  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: null,
    },
  },

  render(createElement) {
    const name = String(this.name).replace('.', '/');
    let component = require(`~/assets/icons/${name}.svg?inline`);

    component = component.default || component;

    const sizing = this.size !== null ? `icon-${this.size}` : '';
    const data = {
      class: ['icon', sizing],
      on: {
        click: e => this.$emit('click', e),
        mouseover: e => this.$emit('mouseover', e),
        mouseleave: e => this.$emit('mouseleave', e),
      },
    };

    return createElement(component, data);
  },
};
</script>

<style lang="scss">
.icon {
  width: 1em;
  height: 1em;
  margin-top: -4px;
  fill: currentColor;

  &.icon-sm {
    width: 0.9em;
    height: 0.9em;
  }

  &.icon-md {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.icon-lg {
    width: 2.5rem;
    height: 2.5rem;
  }

  &.icon-xl {
    width: 3.5rem;
    height: 3.5rem;
  }
}
</style>
