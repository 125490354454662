<template>
  <b-modal size="lg" id="WordpressModal" centered hide-header hide-footer>
    <div class="px-4 py-3">
      <h4 class="mb-3 font-weight-bold">Connect A Wordpress Site</h4>
      <h6>Please follow the following instructions to connect your wordpress site.</h6>

      <!-- TODO: update wordpress urls -->
      <ol>
        <li>
          <!-- Install and activate the eslint-disable-next-line prettier/prettier -->
          Add <a
            target="_blank"
            :href="wp_plugin"
          >
            SocialMediaAgency360 wordpress plugin
          </a>
          in your wordpress installation
        </li>
        <li>
          Enter your wordpress site URL and the username of the user for which you intend to post as with the user
          password and then click the "Connect" button.
        </li>
      </ol>
      <p class="font-weight-bold">
        <svg-icon name="video-player" class="mr-1 text-secondary" />
        <a href="https://www.youtube.com/watch?v=qZahgnv2HgGz7TAg" target="_blank">Watch a step-by-step video</a>
      </p>

      <alert-errors :form="form" message="Some thing's not right" />
      <b-row>
        <b-col md="12" class="mb-3">
          <b-form-group>
            <template slot="label">
              Your Wordpress URL
            </template>
            <b-form-input
              v-model="form.url"
              name="url"
              type="text"
              placeholder="E.g http://example.com"
              autocomplete="off"
            />
            <has-error :form="form" field="url"></has-error>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <template slot="label">
              Your Wordpress Username
            </template>
            <b-form-input
              v-model="form.username"
              name="username"
              type="text"
              placeholder="E.g admin"
              autocomplete="off"
            />
            <has-error :form="form" field="username" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <template slot="label">
              Account Password
            </template>
            <b-form-input
              v-model="form.password"
              name="password"
              type="password"
              placeholder="************"
              autocomplete="off"
            />
            <has-error :form="form" field="password" />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="mt-4 text-right">
        <b-button variant="clear" class="px-3 mr-2" :disabled="form.busy" size="sm" @click="hide"> Cancel </b-button>
        <b-button @click="connectWordpress" variant="primary" :disabled="form.busy" size="sm" class="px-3">
          <b-spinner v-if="form.busy" small />
          Connect
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Form from 'vform';
import { mapState } from 'vuex';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { CONNECT_WORDPRESS_SITE_MUTATION } from '~/graphql/mutations';

export default {
  props: {
    queryType: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      wp_plugin: process.env.VUE_APP_WP_PLUGIN_URL,
      form: new Form({
        url: '',
        username: '',
        password: '',
      }),
    };
  },

  computed: mapState({
    workspace: state => state.workspace.current,
  }),

  methods: {
    connectWordpress() {
      this.form.errors.clear();

      this.form.busy = true;

      return this.$apollo
        .mutate({
          mutation: CONNECT_WORDPRESS_SITE_MUTATION,
          variables: {
            workspace: this.workspace.id,
            ...this.form.data(),
          },
          update: (store, { data: { savedAccount } }) => {
            if (savedAccount) {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: { workspace: this.workspace.id, type: this.queryType },
              });

              data.publishAccounts.push(savedAccount);

              // Write our data back to the cache.
              store.writeQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: { workspace: this.workspace.id, type: this.queryType },
                data,
              });
            }
          },
        })
        .then(() => {
          const count = parseInt(this.workspace.limitsUsage.blogs);
          this.$store.dispatch('workspace/updateCurrent', {
            ...this.workspace,
            limitsUsage: {
              ...this.workspace.limitsUsage,
              blogs: count + 1,
            },
          });

          this.form.busy = false;
          this.hide();

          this.$emit('done');
        })
        .catch(({ graphQLErrors: errors }) => {
          errors.forEach(error => {
            if (error.message == 'InvalidURL') {
              this.form.errors.set('url', 'Invalid web address. Be sure to include http:// or https://');
            }

            if (error.message == 'WordpressAccountExists') {
              this.form.errors.set('username', 'You have already added this account.');
            }

            if (error.message == 'NoWordpressAPI') {
              this.form.errors.set(
                'noApi',
                `We are unable to detect Wordpress API on ${this.form.url}. Make sure your wordpress version is 4.4 or later.`,
              );
            }

            if (error.message == 'CouldNotConnect') {
              this.form.errors.set('timeout', `We could not connect to ${this.form.url}`);
            }

            if (error.message == 'WordpressUnauthorized') {
              this.form.errors.set(
                'unauthorized',
                `We could not connect to ${this.form.url} using the provided credentials`,
              );
            }

            if (error.message == 'TechnicalError') {
              this.form.errors.set(
                'technical',
                'A technical error has occurred. Please try again. If this error persists, please contact support.',
              );
            }
          });

          this.form.busy = false;
        });
    },

    hide() {
      this.$root.$emit('bv::hide::modal', 'WordpressModal');
    },
  },
};
</script>
