import Vue from 'vue';
import breakpoint from './breakpoint';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.prototype.$bootstrap = new Vue({
  mixins: [breakpoint({})],
});
