<template>
  <div class="compose-menu" :class="{ 'compose-menu--active': showComposeMenu }">
    <div id="compose-dropDown" class="compose-menu__button" @click="showComposeMenu = !showComposeMenu">
      <span class="compose-menu__btn">
        <svg-icon name="edit-alt" size="md" />
        <span class="title mx-1">Composer</span>
        <div class="pl-2">
          <svg-icon name="chevron-down" size="sm" />
        </div>
      </span>
    </div>

    <div class="compose-menu__menu-box">
      <div class="compose-menu__menu-box-title">
        what would you like to compose?
      </div>
      <b-row>
        <b-col class="compose-menu__menu-box-left">
          <ul class="compose-menu__menu">
            <li data-name="profile" class="compose-menu__item">
              <router-link
                class="item-link"
                title="Quotes"
                @click.native="hide()"
                :to="{ name: 'compose', params: { type: 'social', id: 0 } }"
              >
                <div class="item-icon">
                  <svg-icon name="post-alt" size="md" />
                </div>
                Social Post
              </router-link>
            </li>
            <li data-name="profile" class="compose-menu__item">
              <router-link class="item-link" title="Quotes" @click.native="hide()" :to="{ name: 'designer' }">
                <div class="item-icon">
                  <svg-icon name="image-alt" size="md" />
                </div>
                Image Designer
              </router-link>
            </li>
          </ul>
        </b-col>
        <b-col class="compose-menu__menu-box-right">
          <ul class="compose-menu__menu">
            <li data-name="profile" class="compose-menu__item">
              <router-link
                class="item-link"
                title="Quotes"
                @click.native="hide()"
                :to="{ name: 'compose', params: { type: 'blog', id: 0 } }"
              >
                <div class="item-icon">
                  <svg-icon name="globe" size="md" />
                </div>
                Blog Post
              </router-link>
            </li>
            <li data-name="profile" class="compose-menu__item">
              <router-link
                class="item-link"
                title="Quotes"
                @click.native="hide()"
                :to="{ name: 'compose-audio', params: { id: 0 } }"
              >
                <div class="item-icon">
                  <svg-icon name="headphone" size="md" />
                </div>
                Text to Audio
              </router-link>
            </li>
            <li data-name="profile" class="compose-menu__item">
              <router-link
                class="item-link"
                title="Quotes"
                @click.native="hide()"
                :to="{ name: 'compose-audio', params: { id: 0 } }"
              >
                <div class="item-icon">
                  <svg-icon name="headphone" size="md" />
                </div>
                AI Videos
              </router-link>
            </li>
          </ul>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showComposeMenu: false,
    };
  },

  created() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.showComposeMenu = false;
      }
    });
  },

  methods: {
    hide() {
      this.showComposeMenu = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.compose-menu {
  z-index: 9999;
  display: inline-block;
  position: relative;
  &__btn {
    // display: flex;
    // align-items: center;
    & span ~ svg {
      margin-top: 2px;
    }
  }
  &__button {
    display: inline-block;
    color: inherit;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  &__menu-box {
    position: absolute;
    width: 100%;
    min-width: 350px;
    max-width: 350px;
    top: 40px;
    left: -100%;
    background-color: $primary;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    margin-top: 5px;
    padding: 10px;
    &:before {
      content: '';
      background-color: transparent;
      position: absolute;
      border-bottom: 12px solid $primary;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-top: 12px solid transparent;
      top: -24px;
      left: 45%;
    }
    &::after {
      content: '';
      background-color: transparent;
    }
    &-left {
      border-right: 1px solid #e0e2e9;
    }
    &-title {
      color: #b7e4c7;
      text-transform: uppercase;
      text-align: center;
      padding: 10px;
      font-size: 14px;
    }
  }
  &__menu {
    margin: 0;
    padding: 0 13px;
    list-style: none;
  }
  &__item {
    font-size: 15px;
    padding: 8px;
    margin: 10px 0;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;
    &:hover {
      background-color: #2d6a4f;
      border: 1px solid #d8f3dc;
      border-radius: 5px;
    }
    .item-link {
      color: #ffffff;
      &:hover,
      &:focus {
        color: #ffffff;
        outline: none;
        text-decoration: none;
      }
    }
    .item-icon {
      text-align: center;
      margin-bottom: 8px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      -ms-transition: all 0.4s;
      -o-transition: all 0.4s;
    }
  }
}

.compose-menu--active .compose-menu__menu-box {
  visibility: visible;
  opacity: 1;
  margin-top: 15px;
}
</style>
