import Vue from 'vue';
import moment from 'moment-timezone';
import { apolloClient } from '~/plugins/apollo';
import { LINK_PREVIEW_QUERY } from '~/graphql/queries';
import { SAVE_POST_MUTATION, SHORTEN_URL_MUTATION } from '~/graphql/mutations';

// state
export const state = {
  currentStep: 1,
  postSaving: false,
  postStatus: 'publish',
  scheduleDate: moment()
    .add(1, 'day')
    .format('YYYY-MM-DDTHH:mm:ss'),
  scheduleTimezone: moment.tz.guess(true),
  selectedContent: {},
  accountPostContents: [],
  selectedImages: [],
  parentType: null,
  platformType: '',
  linkPreview: {},
  linkPreviewLoading: false,
  campaignId: null,
  featuredImageLink: null,
};

const initialStateCopy = JSON.parse(JSON.stringify(state));

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_CURRENT_STEP(state, step) {
    state.currentStep = step;
  },

  UPDATE_POST_SAVING(state, isSaving) {
    state.postSaving = isSaving;
  },

  UPDATE_SELECTED_CONTENT(state, selectedContent) {
    state.selectedContent = selectedContent;
  },

  UPDATE_ACCOUNT_POST_CONTENTS(state, accountPostContents) {
    state.accountPostContents = accountPostContents;
  },

  UPDATE_POST_STATUS(state, postStatus) {
    state.postStatus = postStatus;
  },

  UPDATE_SCHEDULE_DATE(state, scheduleDate) {
    state.scheduleDate = scheduleDate;
  },

  UPDATE_SCHEDULE_TIMEZONE(state, timezone) {
    state.scheduleTimezone = timezone;
  },

  UPDATE_SELECTED_IMAGES(state, selectedImages) {
    state.selectedImages = selectedImages;
  },

  UPDATE_PARENT_TYPE(state, parentType) {
    state.parentType = parentType;
  },

  UPDATE_PLATFORM_TYPE(state, platformType) {
    state.platformType = platformType;
  },

  UPDATE_CAMPAIGN_ID(state, campaignId) {
    state.campaignId = campaignId;
  },

  UPDATE_LINK_PREVIEW_LOADING(state, isLoading) {
    state.linkPreviewLoading = isLoading;
  },

  UPDATE_LINK_PREVIEW(state, linkPreview) {
    state.linkPreview = linkPreview;
  },

  UPDATE_FEATURED_IMAGE_LINK(state, link) {
    state.featuredImageLink = link;
  },

  RESET(state) {
    for (let prop in state) {
      state[prop] = initialStateCopy[prop];
    }
  },
};

// actions
export const actions = {
  updateCurrentStep({ commit }, step) {
    commit('UPDATE_CURRENT_STEP', step);
  },

  clearSelectedContent({ commit }) {
    commit('UPDATE_SELECTED_CONTENT', null);
  },

  updateSelectedContent({ commit }, selectedContent) {
    commit('UPDATE_SELECTED_CONTENT', selectedContent);
  },

  updateSelectedAccounts({ commit }, selectedAccounts) {
    commit('UPDATE_SELECTED_ACCOUNTS', selectedAccounts);
  },

  updateAccountPostContents({ commit }, accountPostContents) {
    commit('UPDATE_ACCOUNT_POST_CONTENTS', accountPostContents);
  },
  updatePostStatus({ commit }, postStatus) {
    commit('UPDATE_POST_STATUS', postStatus);
  },
  updateScheduleDate({ commit }, scheduleDate) {
    commit('UPDATE_SCHEDULE_DATE', scheduleDate);
  },
  updateScheduleTimezone({ commit }, timezone) {
    commit('UPDATE_SCHEDULE_TIMEZONE', timezone);
  },
  updateSelectedImages({ commit }, selectedImages) {
    commit('UPDATE_SELECTED_IMAGES', selectedImages);
  },
  updatePlatformType({ commit }, platformType) {
    commit('UPDATE_PLATFORM_TYPE', platformType);
  },
  updateParentType({ commit }, parentType) {
    commit('UPDATE_PARENT_TYPE', parentType);
  },
  updateCampaignId({ commit }, campaignId) {
    commit('UPDATE_CAMPAIGN_ID', campaignId);
  },
  updateLinkPreview({ commit }, linkPreview) {
    commit('UPDATE_LINK_PREVIEW', linkPreview);
  },
  updateFeaturedImageLink({ commit }, link) {
    commit('UPDATE_FEATURED_IMAGE_LINK', link);
  },
  savePost({ commit, dispatch, state }, { workspace, id, type, status, publishNow = false }) {
    commit('UPDATE_POST_SAVING', true);
    dispatch('layout/updateRestless', true, { root: true });

    // Let's manually add the CORRECT UTC offset.
    // This is super important
    const offset = moment(state.scheduleDate)
      .tz(state.scheduleTimezone)
      .format('Z');
    const momentTime = moment(`${state.scheduleDate}${offset}`).tz(state.scheduleTimezone);

    return apolloClient
      .mutate({
        mutation: SAVE_POST_MUTATION,
        variables: {
          workspace,
          id,
          type,
          status: status,
          campaignId: state.campaignId,
          masterContent: state.selectedContent.body,
          scheduleTime: momentTime.format(),
          scheduleTimezone: state.scheduleTimezone,
          accounts: state.accountPostContents,
          attachments: state.selectedImages,
          publishNow: publishNow,
          featuredImageLink: state.featuredImageLink,
        },
      })
      .then(({ data }) => {
        const { attachments, accountPostContents } = data.saved;
        commit('UPDATE_POST_SAVING', false);
        dispatch('layout/updateRestless', false, { root: true });

        commit(
          'UPDATE_SELECTED_IMAGES',
          attachments.map(attachment => ({
            ...attachment,
            saved: true,
          })),
        );

        dispatch('synPostContents', accountPostContents);

        return data.saved;
      })
      .catch(() => {
        commit('UPDATE_POST_SAVING', false);
        dispatch('layout/updateRestless', false, { root: true });

        return null;
      });
  },

  sync({ commit, dispatch }, savedPost) {
    if (savedPost) {
      const { attachments, accountPostContents, postLink, ...post } = savedPost;
      commit('UPDATE_SELECTED_CONTENT', {
        body: post.masterContent,
      });

      if (attachments && attachments.length > 0) {
        attachments.forEach(attachment => {
          dispatch('addAttachment', { ...attachment, saved: true });
        });
      }

      if (postLink) {
        dispatch('fetchLinkPreview', postLink);
      }

      dispatch('synPostContents', accountPostContents);

      commit('UPDATE_POST_STATUS', post.status);

      commit('UPDATE_PARENT_TYPE', post.parentType);

      commit('UPDATE_SCHEDULE_TIMEZONE', post.scheduleTimezone || moment.tz.guess());
      commit('UPDATE_SCHEDULE_DATE', post.scheduleDate);

      commit('UPDATE_FEATURED_IMAGE_LINK', post.featuredImageUrl);
    }
  },

  synPostContents({ commit, dispatch }, accountPostContents) {
    if (accountPostContents && accountPostContents.length > 0) {
      let postContents = [];

      accountPostContents.forEach(postContent => {
        const attachments = postContent.attachments.map(attachment => ({ ...attachment, saved: true }));
        // We need to cherry-pick the properties I need so I don't send
        // invalid argument to GraphQL
        const {
          publishAccount,
          title,
          content,
          postLink,
          platform,
          publishVariant,
          publishAuthorId,
          publishCategoryIds,
          publishStatus,
        } = postContent;

        const contentItem = {
          accountId: publishAccount.id,
          title,
          content,
          postLink,
          platform,
          attachments,
          publishVariant,
          publishAuthorId,
          publishCategoryIds,
          publishStatus,
        };
        postContents.push(contentItem);

        const account = postContent.publishAccount;
        account &&
          dispatch(
            'validation/validate',
            {
              account,
              postContent: contentItem,
            },
            { root: true },
          );
      });
      commit('UPDATE_ACCOUNT_POST_CONTENTS', postContents);
    }
  },

  addAttachment({ commit, state }, attachment) {
    if (!state.selectedImages.find(img => img.url == attachment.url)) {
      switch (attachment.platform) {
        case 'imgur':
          commit('UPDATE_SELECTED_IMAGES', [
            ...state.selectedImages,
            {
              ...attachment,
            },
          ]);
          break;
        case 'giphy':
          commit('UPDATE_SELECTED_IMAGES', [
            ...state.selectedImages,
            {
              ...attachment,
              type: 'gif',
              platform: 'giphy',
            },
          ]);
          break;
        case 'unsplash':
          commit('UPDATE_SELECTED_IMAGES', [
            ...state.selectedImages,
            {
              ...attachment,
              type: 'image',
              platform: 'unsplash',
            },
          ]);
          break;
        case 'youzign':
          commit('UPDATE_SELECTED_IMAGES', [
            ...state.selectedImages,
            {
              ...attachment,
              type: 'image',
              platform: 'youzign',
            },
          ]);
          break;
        case 'self':
          commit('UPDATE_SELECTED_IMAGES', [
            ...state.selectedImages,
            {
              ...attachment,
              platform: 'self',
            },
          ]);
          break;
        case 'link':
          commit('UPDATE_SELECTED_IMAGES', [
            ...state.selectedImages,
            {
              ...attachment,
              type: 'image',
              platform: 'link',
            },
          ]);
          break;
      }
    }
  },

  fetchLinkPreview({ dispatch, commit, state }, url) {
    commit('UPDATE_LINK_PREVIEW_LOADING', true);

    if (url.length > 100 && state.platformType === 'social') {
      dispatch('shortenUrl', url);
    }

    apolloClient
      .query({
        query: LINK_PREVIEW_QUERY,
        variables: {
          url,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        commit('UPDATE_LINK_PREVIEW', data.linkPreview);
        commit('UPDATE_LINK_PREVIEW_LOADING', false);
      });
  },

  shortenUrl({ commit, state }, url) {
    const workspace = this.state.workspace.current;

    if (workspace.linkShortener != 'none') {
      apolloClient
        .mutate({
          mutation: SHORTEN_URL_MUTATION,
          variables: {
            workspace: workspace.id,
            url: url,
          },
        })
        .then(({ data: shorten }) => {
          const content = state.selectedContent.body;
          const updated = content.replace(new RegExp(url, 'g'), shorten.shortenUrl);

          commit('UPDATE_SELECTED_CONTENT', {
            body: updated,
            postLink: shorten.shortenUrl,
          });

          Vue.notify({
            group: 'main',
            type: 'native',
            text: 'Link has been shortened. You can disable or update link shortening parameters in the settings page.',
            duration: 5000,
          });
        })
        .catch(() => {
          Vue.notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while shortening url',
          });
        });
    }
  },

  reset({ commit }) {
    commit('RESET');
  },
};
