<template>
  <svg width="20" height="22" viewBox="0 0 20 22">
    <path d="M7 15h10v2H7zM7 9h10v2H7zM7 3h10v2H7zM2 2h3v3H2zM2 8h3v3H2zM2 14h3v3H2z"></path>
  </svg>
</template>

<script>
export default {
  name: 'text-list-bullets',
};
</script>
