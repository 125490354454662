<template>
  <svg width="20" height="22" viewBox="0 0 20 22">
    <path d="M3 3h14v2H3zM3 15h14v2H3zM6 6h8v2H6zM6 12h8v2H6zM4 9h12v2H4z"></path>
  </svg>
</template>

<script>
export default {
  name: 'text-align-center',
};
</script>
