<template>
  <svg
    :height="height"
    :width="width"
    style="enable-background:new 0 0 56.6934 56.6934;"
    version="1.1"
    viewBox="0 0 56.6934 56.6934"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M28.6253,4.165c-13.5996,0-24.625,11.0234-24.625,24.623c0,13.5997,11.0254,24.625,24.625,24.625  c13.5986,0,24.624-11.0253,24.624-24.625C53.2493,15.1883,42.2239,4.165,28.6253,4.165z M23.0709,40.5321  c0,0.0171-0.0009,0.0322-0.0024,0.0446c-0.0118-0.0038-0.0256-0.0104-0.0412-0.018l-8.5216-4.2608  c-0.1193-0.0597-0.2505-0.2719-0.2505-0.4055l0.0005-19.5355l8.8152,4.4077V40.5321z M24.1465,31.2272v-8.8948l7.9069,12.8477  L24.1465,31.2272z M27.6718,26.0087l6.0596-9.8042l8.8934,4.4465l-9.1255,14.8285L27.6718,26.0087z M42.8517,40.5256  c0,0.017-0.001,0.0322-0.0024,0.0445c-0.0118-0.0047-0.0256-0.0104-0.0407-0.018l-8.5401-4.2693l8.5832-13.9485V40.5256z"
    />
  </svg>
</template>

<script>
export default {
  name: 'medium-icon',

  props: {
    width: {
      type: String,
      default: '55px',
    },

    height: {
      type: String,
      default: '55px',
    },
  },
};
</script>
