<template>
  <svg @click="$emit('click', $event)" class="icon" :transform="`rotate(${rotate})`" :width="width" :height="height">
    <use :xlink:href="`${staticPublicPrefix}assets/images/sprite.svg#${icon}`" />
  </svg>
</template>

<script>
import { staticPublicPrefix } from '~/utils/helpers.js';

export default {
  name: 'sprite-icon',
  data() {
    return {
      staticPublicPrefix,
    };
  },
  props: {
    icon: {
      type: String,
      required: true,
    },

    width: {
      type: String,
    },

    height: {
      type: String,
    },

    rotate: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style>
.icon {
  fill: currentColor;
  height: 1em;
  margin-top: -4px;
  vertical-align: middle;
  width: 1em;
}
</style>
