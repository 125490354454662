<template>
  <svg width="20" height="22" viewBox="0 0 20 22">
    <path d="M4 3h13v2H4zM4 15h13v2H4zM10 6h7v2h-7zM10 12h7v2h-7zM10 9h7v2h-7z"></path>
    <g>
      <path
        d="M7.1 12.9c-.2 0-.4-.1-.6-.2L2.7 9.9l3.8-2.8c.5-.4 1.1-.3 1.4.2.3.4.2 1.1-.2 1.4L6.1 9.9l1.6 1.2c.4.3.5 1 .2 1.4-.1.2-.5.4-.8.4z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'text-outdent-left',
};
</script>
