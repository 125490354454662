<template>
  <div class="skeleton-screens">
    <skeleton-screen
      v-for="(item, i) in items"
      :key="i + 1"
      :height="height"
      :width="width"
      :class="itemClass"
      :shade="shade"
    />
  </div>
</template>

<script>
export default {
  name: 'skeleton-screens',
  props: {
    times: {
      type: [String, Number],
      default: 1,
    },
    itemClass: {
      type: String,
      default: '',
    },
    height: {
      // Make lines the same height as text.
      default: '1em',
      type: String,
    },
    width: {
      // Make it possible to define a fixed
      // width instead of using a random one.
      default: null,
      type: String,
    },
    shade: {
      default: 'light',
      type: String,
    },
  },
  computed: {
    items() {
      return [...Array(Number(this.times)).keys()];
    },
  },
};
</script>
