<template>
  <div class="chat-menu" :class="{ 'chat-menu--active': showChatMenu }">
    <div id="chat-dropDown" class="chat-menu__button" @click="showChatMenu = !showChatMenu">
      <span class="chat-menu__btn">
        <svg-icon name="chat" class="mr-1 text-primary" size="md" />
      </span>
    </div>

    <div class="chat-menu__menu-box">
      <Chat />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Chat from '~/components/General/Menus/partials/Chat';

export default {
  components: { Chat },

  data() {
    return {
      showChatMenu: false,
    };
  },

  created() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.showChatMenu = false;
      }
    });
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.chat-menu {
  z-index: 9999;
  display: inline-block;
  position: relative;
  &__button {
    background: transparent;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  &__menu-box {
    border: 1px solid $gray;
    position: absolute;
    left: -195px;
    height: 85vh;
    width: 400px;
    background-color: $gray;
    color: #000000;
    border-radius: 30px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    margin-top: 5px;
    padding: 10px;
    &:before {
      content: '';
      background-color: transparent;
      position: absolute;
      border-bottom: 12px solid $gray;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-top: 12px solid transparent;
      top: -24px;
      right: 46%;
    }
    &::after {
      content: '';
      background-color: transparent;
    }
    .chat__bar-header {
      border-radius: 25px 25px 0 0;
    }
    .chat__footer-item {
      border-radius: 2px 2px 30px 30px;
    }
  }
}

.chat-menu--active .chat-menu__menu-box {
  visibility: visible;
  opacity: 1;
  margin-top: 15px;
}
</style>
