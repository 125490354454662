<template>
  <svg :width="width" :height="height" viewBox="0 0 60 64.9">
    <path
      d="M25,64.9h19.5c8.3,0,15.2,6.7,15.5,14.9V0c-0.3,8.2-7.2,14.9-15.5,14.9H25c-13.8,0-25,11.2-25,25S11.2,64.9,25,64.9z"
    />
  </svg>
</template>

<script>
export default {
  name: 'fab-curve-icon',

  props: {
    width: {
      type: String,
      default: '22px',
    },
    height: {
      type: String,
      default: '18px',
    },
  },
};
</script>
