<template>
  <transition name="left-sidebar-slide">
    <div class="left__sidebar position-static">
      <div class="chat">
        <Chat />
      </div>
    </div>
  </transition>
</template>

<script>
import Chat from '~/components/General/Menus/partials/Chat';

export default {
  components: { Chat },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.left__sidebar {
  width: 100%;
  height: 80vh;
  background-color: $gray;
  color: #000000;
  border: 1px solid $gray;
  border-radius: 12px;
  padding: 5px;
}
.chat__bar-header {
  border-radius: 10px;
  width: 100%;
}
.chat__footer-item {
  border-radius: 2px 2px 2px 30px;
}
.left-sidebar-slide-enter-active {
  animation: left-sidebar-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.left-sidebar-slide-leave-active {
  animation: left-sidebar-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes left-sidebar-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes left-sidebar-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
