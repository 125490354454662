<template>
  <svg width="20" height="22" viewBox="0 0 20 22">
    <path d="M4 3h13v2H4zM4 15h13v2H4zM4 6h13v2H4zM4 12h13v2H4zM4 9h13v2H4z"></path>
  </svg>
</template>

<script>
export default {
  name: 'text-align-full',
};
</script>
