<template>
  <div id="main-wrapper">
    <div v-if="restless" class="restless" />
    <notifications
      group="main"
      classes="main-notification"
      animation-type="velocity"
      :animation="notificationAnimation"
    />

    <transition name="page" mode="out-in">
      <component :is="currentLayoutComponent"> <router-view /> </component>
    </transition>

    <b-modal id="PinterestModal" centered hide-header hide-footer content-class="text-center p-5">
      <template v-slot:default="{ hide }">
        <svg class="timer__clock mb-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178 203">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(59)" stroke="#000" stroke-width="4">
              <rect x="2" y="2" width="56" height="14" rx="6" />
              <path d="M12.25 2.5v13" stroke-linecap="square" />
              <path d="M23 2.929V27.07" stroke-linecap="square" />
              <path d="M48.25 2.5v13" stroke-linecap="square" />
              <path d="M37 2.929V27.07" stroke-linecap="square" />
            </g>
            <g transform="translate(2 27)" style="&#10;">
              <circle stroke="#000" stroke-width="4" cx="87" cy="87" r="87" />
              <circle stroke="#000" stroke-width="4" fill="#FFF" cx="87" cy="87" r="80" />
              <circle class="c-timer__chart" cx="87" cy="87" r="42" />
              <path fill="#000" d="M85 7h4v16h-4z" />
              <path fill="#000" d="M85 151h4v16h-4z" />
              <path fill="#000" d="M23 85v4H7v-4z" />
              <path fill="#000" d="M167 85v4h-16v-4z" />
              <path fill="#000" d="M142.314 29l2.828 2.828-11.314 11.314L131 40.314z" />
              <path fill="#000" d="M29 31.828L31.828 29l11.314 11.314-2.828 2.828z" />
              <path fill="#000" d="M131 133.828l2.828-2.828 11.314 11.314-2.828 2.828z" />
              <path fill="#000" d="M40.314 131l2.828 2.828-11.314 11.314L29 142.314z" />
              <circle stroke="#000" stroke-width="4" fill="#FFF" cx="87" cy="87" r="5" />
            </g>
          </g>
        </svg>
        <h2>Coming Soon!</h2>
        <p>Pinterest Integrations will be live in the coming weeks. Keep an eye out.</p>

        <b-button variant="secondary" class="px-5 mt-2" pill @click="hide()">Okay</b-button>
      </template>
    </b-modal>

    <b-modal id="PodbeanModal" centered hide-header hide-footer content-class="text-center p-5">
      <template v-slot:default="{ hide }">
        <svg class="timer__clock mb-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178 203">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(59)" stroke="#000" stroke-width="4">
              <rect x="2" y="2" width="56" height="14" rx="6" />
              <path d="M12.25 2.5v13" stroke-linecap="square" />
              <path d="M23 2.929V27.07" stroke-linecap="square" />
              <path d="M48.25 2.5v13" stroke-linecap="square" />
              <path d="M37 2.929V27.07" stroke-linecap="square" />
            </g>
            <g transform="translate(2 27)" style="&#10;">
              <circle stroke="#000" stroke-width="4" cx="87" cy="87" r="87" />
              <circle stroke="#000" stroke-width="4" fill="#FFF" cx="87" cy="87" r="80" />
              <circle class="c-timer__chart" cx="87" cy="87" r="42" />
              <path fill="#000" d="M85 7h4v16h-4z" />
              <path fill="#000" d="M85 151h4v16h-4z" />
              <path fill="#000" d="M23 85v4H7v-4z" />
              <path fill="#000" d="M167 85v4h-16v-4z" />
              <path fill="#000" d="M142.314 29l2.828 2.828-11.314 11.314L131 40.314z" />
              <path fill="#000" d="M29 31.828L31.828 29l11.314 11.314-2.828 2.828z" />
              <path fill="#000" d="M131 133.828l2.828-2.828 11.314 11.314-2.828 2.828z" />
              <path fill="#000" d="M40.314 131l2.828 2.828-11.314 11.314L29 142.314z" />
              <circle stroke="#000" stroke-width="4" fill="#FFF" cx="87" cy="87" r="5" />
            </g>
          </g>
        </svg>
        <h2>Coming Soon!</h2>
        <p>Podbean Integrations will be live in the coming weeks. Keep an eye out.</p>

        <b-button variant="secondary" class="px-5 mt-2" pill @click="hide()">Okay</b-button>
      </template>
    </b-modal>

    <b-modal id="SpotifyModal" centered hide-header hide-footer content-class="text-center p-5">
      <template v-slot:default="{ hide }">
        <svg class="timer__clock mb-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178 203">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(59)" stroke="#000" stroke-width="4">
              <rect x="2" y="2" width="56" height="14" rx="6" />
              <path d="M12.25 2.5v13" stroke-linecap="square" />
              <path d="M23 2.929V27.07" stroke-linecap="square" />
              <path d="M48.25 2.5v13" stroke-linecap="square" />
              <path d="M37 2.929V27.07" stroke-linecap="square" />
            </g>
            <g transform="translate(2 27)" style="&#10;">
              <circle stroke="#000" stroke-width="4" cx="87" cy="87" r="87" />
              <circle stroke="#000" stroke-width="4" fill="#FFF" cx="87" cy="87" r="80" />
              <circle class="c-timer__chart" cx="87" cy="87" r="42" />
              <path fill="#000" d="M85 7h4v16h-4z" />
              <path fill="#000" d="M85 151h4v16h-4z" />
              <path fill="#000" d="M23 85v4H7v-4z" />
              <path fill="#000" d="M167 85v4h-16v-4z" />
              <path fill="#000" d="M142.314 29l2.828 2.828-11.314 11.314L131 40.314z" />
              <path fill="#000" d="M29 31.828L31.828 29l11.314 11.314-2.828 2.828z" />
              <path fill="#000" d="M131 133.828l2.828-2.828 11.314 11.314-2.828 2.828z" />
              <path fill="#000" d="M40.314 131l2.828 2.828-11.314 11.314L29 142.314z" />
              <circle stroke="#000" stroke-width="4" fill="#FFF" cx="87" cy="87" r="5" />
            </g>
          </g>
        </svg>
        <h2>Coming Soon!</h2>
        <p>
          Spotify Integrations will be live in the coming weeks. In meantime you can
          <!-- <strong>publish to Spotify through Podbean</strong>. -->
        </p>

        <b-button variant="secondary" class="px-5 mt-2" pill @click="hide()">Okay</b-button>
      </template>
    </b-modal>

    <ConnectAccountsModal :type="platformTypeToAdd" />
    <ConnectFacebookAccountsModal
      :loading="$apollo.queries.facebookAccounts && $apollo.queries.facebookAccounts.loading"
      :accountId="facebookAccountId"
      :accounts="facebookAccounts"
      :queryType="queryType"
      @done="handleDone"
      @cancel="handleCancel"
    />
    <ConnectInstagramAccountsModal
      :loading="$apollo.queries.facebookPages && $apollo.queries.facebookPages.loading"
      :facebookPages="facebookPages"
      :queryType="queryType"
      @done="handleDone"
      @cancel="handleCancel"
    />
    <ConnectPinterestBoardsModal
      :loading="$apollo.queries.pinterestBoards && $apollo.queries.pinterestBoards.loading"
      :accountId="pinterestAccountId"
      :boards="pinterestBoards"
      :queryType="queryType"
      @done="handleDone"
      @cancel="handleCancel"
    />
    <ConnectLinkedinOrgsModal
      :loading="$apollo.queries.linkedinOrganisations && $apollo.queries.linkedinOrganisations.loading"
      :accountId="linkedinAccountId"
      :orgs="linkedinOrganisations"
      :queryType="queryType"
      @done="handleDone"
      @cancel="handleCancel"
    />
    <ConnectWordpressModal @done="handleDone" @cancel="handleCancel" :queryType="queryType" />
    <ConnectMediumModal @done="handleDone" @cancel="handleCancel" :queryType="queryType" />
    <ConnectMediumPublicationsModal
      :loading="$apollo.queries.mediumPublications && $apollo.queries.mediumPublications.loading"
      :accountId="mediumAccountId"
      :publications="mediumPublications"
      :queryType="queryType"
      @done="handleDone"
      @cancel="handleCancel"
    />

    <ConnectTumblrBlogsModal
      :loading="$apollo.queries.tumblrBlogs && $apollo.queries.tumblrBlogs.loading"
      :accountId="tumblrAccountId"
      :blogs="tumblrBlogs"
      :queryType="queryType"
      @done="handleDone"
      @cancel="handleCancel"
    />

    <ArticlePreview />
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState, mapGetters } from 'vuex';
import { get, isArray, isString } from 'lodash';
import ConnectAccountsModal from '~/components/ConnectAccountsModal';
import ConnectWordpressModal from '~/components/ConnectWordpressModal';
import ConnectMediumModal from '~/components/ConnectMediumModal';
import ConnectLinkedinOrgsModal from '~/components/ConnectLinkedinOrgsModal';
import ConnectPinterestBoardsModal from '~/components/ConnectPinterestBoardsModal';
import ConnectFacebookAccountsModal from '~/components/ConnectFacebookAccountsModal';
import ConnectInstagramAccountsModal from '~/components/ConnectInstagramAccountsModal';
import ConnectMediumPublicationsModal from '~/components/ConnectMediumPublicationsModal';
import ConnectTumblrBlogsModal from '~/components/ConnectTumblrBlogsModal';
import ArticlePreview from '~/components/Articles/ArticlePreview';

import {
  LINKEDIN_ORGS_QUERY,
  PINTEREST_BOARDS_QUERY,
  MEDIUM_PUBLICATIONS_QUERY,
  FACEBOOK_PAGES_GROUPS_QUERY,
  FACEBOOK_PAGES_QUERY,
  TUMBLR_BLOGS_QUERY,
} from '~/graphql/queries';

export default {
  metaInfo() {
    const appName = process.env.VUE_APP_NAME;

    return {
      title: appName,
      titleTemplate: `%s`,
    };
  },

  components: {
    ArticlePreview,
    ConnectAccountsModal,
    ConnectWordpressModal,
    ConnectMediumModal,
    ConnectLinkedinOrgsModal,
    ConnectPinterestBoardsModal,
    ConnectFacebookAccountsModal,
    ConnectInstagramAccountsModal,
    ConnectMediumPublicationsModal,
    ConnectTumblrBlogsModal,
  },

  data() {
    return {
      facebookAccounts: [],
      linkedinOrganisations: [],
      pinterestBoards: [],
      facebookPages: [],
      mediumPublications: [],
      tumblrBlogs: [],

      facebookAccountId: null,
      pinterestAccountId: null,
      linkedinAccountId: null,
      mediumAccountId: null,
      tumblrAccountId: null,
    };
  },

  computed: {
    ...mapState({
      ready: state => state.layout.ready,
      restless: state => state.layout.restless,
      currentLayout: state => state.layout.current,

      queryType: state => state.publishAccount.queryType,
      platformTypeToAdd: state => state.publishAccount.platformTypeToAdd,

      workspace: state => state.workspace.current,

      previewArticle: state => state.article.invoked,
    }),

    ...mapGetters({
      currentLayoutComponent: 'layout/currentLayoutComponentName',
    }),
    notificationAnimation() {
      return {
        enter(/*element*/) {
          /*
           *  "element" - is a notification element
           *    (before animation, meaning that you can take it's initial height, width, color, etc)
           */
          // let width = element.clientWidth;

          return { translateX: ['0%', '100%'], display: 'block' };
        },
        leave: { translateX: ['100%', '0%'], display: 'none' },
      };
    },
  },

  watch: {
    async ready(isReady) {
      if (isReady) {
        if (this.$route.query.fb) {
          this.facebookAccountId = parseInt(this.$route.query.fb, 10);
          this.$bvModal.show('FBModal');

          if (!this.$apollo.queries.facebookAccounts) {
            this.$apollo.addSmartQuery('facebookAccounts', {
              query: FACEBOOK_PAGES_GROUPS_QUERY,
              variables() {
                return {
                  id: this.facebookAccountId,
                  workspace: get(this.workspace, 'id'),
                };
              },
            });
          } else {
            await this.$apollo.queries.facebookAccounts.refetch({
              id: this.facebookAccountId,
              workspace: get(this.workspace, 'id'),
            });
          }

        }

        if (this.$route.query.ig) {
          this.$bvModal.show('InstagramModal');

          if (!this.$apollo.queries.facebookPages) {
            this.$apollo.addSmartQuery('facebookPages', {
              query: FACEBOOK_PAGES_QUERY,
              variables() {
                return {
                  workspace: get(this.workspace, 'id'),
                };
              },
            });
          } else {
            await this.$apollo.queries.facebookPages.refetch({
              workspace: get(this.workspace, 'id'),
            });
          }

        }

        if (this.$route.query.pin) {
          this.pinterestAccountId = parseInt(this.$route.query.pin, 10);
          this.$bvModal.show('PinterestBoardsModal');

          if (!this.$apollo.queries.pinterestBoards) {
            this.$apollo.addSmartQuery('pinterestBoards', {
              query: PINTEREST_BOARDS_QUERY,
              variables() {
                return { id: this.pinterestAccountId, workspace: get(this.workspace, 'id') };
              },
            });
          } else {
            await this.$apollo.queries.pinterestBoards.refetch({
              id: this.pinterestAccountId,
              workspace: get(this.workspace, 'id'),
            });
          }
        }

        if (this.$route.query.in) {
          this.linkedinAccountId = parseInt(this.$route.query.in, 10);
          this.$bvModal.show('LinkedinOrgsModal');

          if (!this.$apollo.queries.linkedinOrganisations) {
            this.$apollo.addSmartQuery('linkedinOrganisations', {
              query: LINKEDIN_ORGS_QUERY,
              variables() {
                return { id: this.linkedinAccountId, workspace: get(this.workspace, 'id') };
              },
            });
          } else {
            await this.$apollo.queries.linkedinOrganisations.refetch({
              id: this.linkedinAccountId,
              workspace: get(this.workspace, 'id'),
            });
          }
        }

        if (this.$route.query.medium) {
          this.mediumAccountId = parseInt(this.$route.query.medium, 10);
          this.$bvModal.show('MediumPublicationsModal');

          if (!this.$apollo.queries.mediumPublications) {
            this.$apollo.addSmartQuery('mediumPublications', {
              query: MEDIUM_PUBLICATIONS_QUERY,
              variables() {
                return { id: this.mediumAccountId, workspace: get(this.workspace, 'id') };
              },
            });
          } else {
            await this.$apollo.queries.mediumPublications.refetch({
              id: this.mediumAccountId,
              workspace: get(this.workspace, 'id'),
            });
          }
        }

        if (this.$route.query.tumblr) {
          this.tumblrAccountId = parseInt(this.$route.query.tumblr, 10);
          this.$bvModal.show('TumblrBlogsModal');

          if (!this.$apollo.queries.tumblrBlogs) {
            this.$apollo.addSmartQuery('tumblrBlogs', {
              query: TUMBLR_BLOGS_QUERY,
              variables() {
                return { workspace: this.workspace.id, id: this.tumblrAccountId };
              },
            });
          } else {
            await this.$apollo.queries.tumblrBlogs.refetch({
              id: this.tumblrAccountId,
              workspace: get(this.workspace, 'id'),
            });
          }
        }
      }
    },

    '$route.query.read'(value) {
      if (value && this.previewArticle && this.previewArticle.id) {
        this.$bvModal.show('ArticlePrevivewModal');

        this.$store.dispatch('article/fetchText', value);
      } else {
        // eslint-disable-next-line
        const { read, ...rest } = this.$route.query;
        this.$router.push({ query: rest }, () => {
          this.$store.dispatch('article/invoke', null);
        });
      }
    },

    $route: {
      async handler(route) {
        const workspaceSlug = get(route, 'params.workspace');
        const store = this.$store;

        if (isString(workspaceSlug)) {
          let workspaces = get(store, 'state.workspace.workspaces', []);
          if (!isArray(workspaces)) {
            workspaces = [];
          }

          const currentWorkspace = workspaces.find(workspace => get(workspace, 'slug') === workspaceSlug);

          if (currentWorkspace) {
            if (currentWorkspace.id !== get(store.state.workspace, 'current.id')) {
              await store.dispatch('workspace/updateCurrent', currentWorkspace);
            }
          } else {
            await store.dispatch('workspace/updateCurrent', null);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    handleDone() {
      this.$bvModal.hide('ConnectAccountsModal');

      Swal.fire({
        type: 'success',
        title: 'Success',
        text: 'Account(s) connected successfully',
      }).then(() => {
        const query = this.removeModalQueries();

        this.$router.push({ query }, () => {});
      });
    },

    handleCancel() {
      const query = this.removeModalQueries();

      this.$router.push({ query });
    },

    removeModalQueries() {
      let query = {};

      for (let key in this.$route.query) {
        if (key != 'fb' && key != 'in' && key != 'pin' && key != 'medium' && key != 'tumblr' && key != 'ig') {
          query[key] = this.$route.query[key];
        }
      }

      return query;
    },
  },
};
</script>

<style lang="scss">
.timer__clock {
  max-width: 10rem;
}

.sidebar-slide-enter-active {
  animation: sidebar-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.sidebar-slide-leave-active {
  animation: sidebar-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes sidebar-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sidebar-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
