<template>
  <b-modal size="md" id="InstagramModal" centered no-close-on-backdrop no-close-on-esc hide-header hide-footer>
    <!-- fb page view -->
    <div v-if="view === 'fb-page'">
      <div class="p-3" >
        <strong>TIP:</strong>
        <p>To connect an instagram account, you'll need access to the following</p>
        <ol class="px-4">
          <li>
            An Instagram Business Account
            <a
              href="https://help.instagram.com/502981923235522?fbclid=IwAR0h-A5DaVO2kEeXRqF5Dz04y-mPrtmLluFizW7PXXZrRaEPMoI8PbnjFLw"
              title="How to setup Instagram Business Account"
              target="_blank"
            >
              <b-icon icon="question-circle-fill" aria-label="Help" animation="fade" />
            </a>
            or Instagram Creator Account
            <a
              href="https://help.instagram.com/1158274571010880?fbclid=IwAR1xPb5v6lQUgSUbLTfLNO7174KQo5lMmmr6AUYDdc0Awb85CSTwk7scbaQ"
              title="How to setup Instagram Creator Account"
              target="_blank"
            >
              <b-icon icon="question-circle-fill" aria-label="Help" animation="fade" />
            </a>
          </li>
          <li>
            A Facebook Page connected to that account
            <a
              href="https://help.instagram.com/570895513091465"
              title="How to connect Facebook page to Instagram Creator/Business Account"
              target="_blank"
            >
              <b-icon icon="question-circle-fill" aria-label="Help" animation="fade" />
            </a>
          </li>
        </ol>
      </div>

      <div v-if="loading" class="bg-white text-center p-5"><spinner /></div>
      <template v-else>
        <div v-if="facebookPages && facebookPages.length > 0">
          <h5>Connected Facebook Pages</h5>
          <table class="table table-borderless striped ">
            <thead>
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="fbPage in facebookPages" :key="fbPage.id" >
                <td class="d-flex align-items-center">
                  <img class="mr-2 rounded" :src="fbPage.imageUrl" alt="" style="width: 40px; height: 40px;" />
                  {{ fbPage.name }}
                </td>
                <td class="">
                  <div v-if="!fbPage.connected" class="">
                    <b-button variant="primary" size="sm" class="px-3 mr-4" @click="fetchInstaAccounts(fbPage.id)">
                      Connect
                    </b-button>
                  </div>
                  <b-badge v-else variant="success" class="px-2">Connected</b-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <div class="p-3 text-center text-danger">
            Connect a Facebook Page that is linked to an Instagram Business or Creator Account
          </div>
        </div>
      </template>
    </div>

    <!-- instagram view -->
    <div v-if="view === 'insta-accounts'">
      <div v-if="$apollo.queries.instagramAccounts && $apollo.queries.instagramAccounts.loading" class="bg-white text-center p-5"><spinner /></div>
      <div v-else class="">
        <div v-if="instagramAccounts && instagramAccounts.length > 0">
          
          <h5>Instagram Accounts</h5>
          <table class="table table-borderless striped ">
            <thead>
              <tr>
                <th>Account</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="account in instagramAccounts" :key="account.id">
                <td class="d-flex align-items-center">
                  <img class="mr-2 rounded" :src="account.imageUrl" :alt="account.username" style="width: 40px; height: 40px;" />
                  {{ account.name }}
                </td>
                <td class="">
                  <div v-if="!account.connected" class="">
                    <b-button variant="primary" size="sm" class="px-2 mr-4" :disabled="savingInstaAccounts" @click="saveSelectedInstagramAccounts(account.id)">
                      Connect
                    </b-button>
                  </div>
                  <b-badge v-else variant="success" class="px-2">Connected</b-badge>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-center m2">
            <b-spinner v-if="savingInstaAccounts" small />
          </div>
        </div>
        <div v-else>
          <div class="p-5 text-center">
            <p>We could not find any instagram accounts connected to selected Facebook Page</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 float-right">
      <b-button variant="outline-primary" v-if="view !== 'fb-page'"  size="sm" class="px-2 mr-2" @click="setView('fb-page')">
        <b-icon icon="arrow-left" class="mr-1" />
        Go Back
      </b-button>
      <b-button variant="outline-danger" size="sm" class="px-2" @click="cancel">
        <b-icon icon="x" aria-label="Close" class="mr-1" />

        Close
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { SAVE_SELECTED_INSTAGRAM_ACCOUNTS_MUTATION } from '~/graphql/mutations';
import { INSTAGRAM_ACCOUNTS_QUERY, PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { get } from 'lodash';

export default {
  name: 'connect-instagram-accounts-modal',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    facebookPages: {
      type: Array,
      default: () => [],
    },

    queryType: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      view: 'fb-page',
      instagramAccounts: {},
      savingInstaAccounts: false,
      facebookPageId: null,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  methods: {
    setView(view) {
      this.view = view;
    },
    async fetchInstaAccounts(fbPageId) {
      // fetch instagram accounts
      this.setView('insta-accounts');

      this.facebookPageId = fbPageId;

      if (!this.$apollo.queries.instagramAccounts) {
          this.$apollo.addSmartQuery('instagramAccounts', {
            query: INSTAGRAM_ACCOUNTS_QUERY,
            variables: () => ({
              id: fbPageId,
              workspace: get(this.workspace, 'id'),
            }),
          });
        } else {
          await this.$apollo.queries.instagramAccounts.refetch({
            id: fbPageId,
            workspace: get(this.workspace, 'id'),
          });
        }

    },

    saveSelectedInstagramAccounts(instagramId) {
      const limit = parseInt(this.workspace.limits.socialAccounts || '');
      const limitCount = isNaN(limit) ? Infinity : limit;
      const usedCount = parseInt(this.workspace.limitsUsage.socialAccounts || '');
      const remainder = limitCount - usedCount;
      const total = this.instagramAccounts.length;

      if (total > remainder) {
        return this.$notify({
          group: 'main',
          title: 'Please Upgrade',
          type: 'native-error',
          text: `You have reached the maximum number of social accounts allowed in your current plan. You can only add ${remainder} more social accounts.`,
        });
      }

      this.savingInstaAccounts = true;
      return this.$apollo
        .mutate({
          mutation: SAVE_SELECTED_INSTAGRAM_ACCOUNTS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            accountId: this.facebookPageId,
            instagramId: instagramId,
          },
          update: (store, { data: { savedAccounts } }) => {
            if (savedAccounts) {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  type: this.queryType,
                },
              });

              data.publishAccounts.push( savedAccounts );

              // Write our data back to the cache.
              store.writeQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  type: this.queryType,
                },
                data,
              });
            }
          },
        })
        .then(() => {
          const count = parseInt(this.workspace.limitsUsage.socialAccounts);
          this.$store.dispatch('workspace/updateCurrent', {
            ...this.workspace,
            limitsUsage: {
              ...this.workspace.limitsUsage,
              socialAccounts: count + total,
            },
          });

          this.savingInstaAccounts = false;
          this.hide();

          this.$emit('done');
        })
        .catch(($e) => {
          this.savingInstaAccounts = false;

          Swal.fire({
            type: 'error',
            title: 'OOPS!',
            text: 'An error occurred. Please try again.',
          });
        });
    },

    cancel() {
      this.$emit('cancel');
      this.$root.$emit('bv::hide::modal', 'InstagramModal');
    },

    hide() {
      this.$root.$emit('bv::hide::modal', 'InstagramModal');
    },
  },
};
</script>
