// state
export const state = {
  postToDelete: null,
};

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_POST_TO_DELETE(state, postToDelete) {
    state.postToDelete = postToDelete;
  },
};

// actions
export const actions = {
  updatePostToDelete({ commit }, postToDelete) {
    commit('UPDATE_POST_TO_DELETE', postToDelete);
  },
};
