import moment from 'moment';
import { apolloClient } from '~/plugins/apollo';
import { AUTOMATIONS_QUERY } from '~/graphql/queries';
import { SAVE_AUTOMATION_MUTATION, AI_AUTOMATED_POST_MESSAGE_MUTATION } from '~/graphql/mutations';
import * as Comlink from 'comlink';

// state
export const state = {
  currentStep: 1,
  id: 0,
  campaignName: '',
  selectedAccounts: [],
  source: null,
  keywords: [],
  excludeKeywords: [],
  region: 'world',
  language: 'en',
  postAge: 'any',
  fromAfterDate: {
    startDate: moment()
      .subtract(2, 'days')
      .format('YYYY-MM-DD'),
  },
  aiPostPrompt: '',
  sorting: 'date',
  selectedHashtagPreset: 0,
  recurringValue: 1,
  recurringUnit: 'hour',
  scheduleType: 'continuous',
  startDate: {
    startDate: moment().format('YYYY-MM-DD'),
  },
  endDate: {
    startDate: moment()
      .add(2, 'days')
      .format('YYYY-MM-DD'),
  },
  reviewPosts: false,
  maxPostCount: 1,
  prefetchDaysLimit: null,
  removeExistingPosts: false,
  status: 'active',
  saveBusy: false,
  listSearchInput: '',
  listSelectedAccountIds: [],
  count_per_request: 1,
  previewContents: {},
  isPreviewContentsLoading: false,
  ai: {
    context: [],
    data: null,
  },
  csvFile: null,
  csvDateFormat: 'm/d/Y H:i',
};

const initialStateCopy = JSON.parse(JSON.stringify(state));

// getters
export const getters = {};

// mutations
export const mutations = {
  UPDATE_CURRENT_STEP(state, step) {
    state.currentStep = step;
  },

  UPDATE_CAMPAIGN_NAME(state, name) {
    state.campaignName = name;
  },

  UPDATE_SELECTED_ACCOUNTS(state, accounts) {
    state.selectedAccounts = accounts;
  },

  UPDATE_SOURCE(state, source) {
    state.source = source;
  },

  UPDATE_KEYWORDS(state, keywords) {
    state.keywords = keywords;
  },

  UPDATE_AI_POST_PROMPT(state, prompt) {
    state.aiPostPrompt = prompt;
  },

  UPDATE_AI_POST_COUNT(state, count) {
    state.count_per_request = count;
  },

  UPDATE_INCLUDE_KEYWORDS(state, includeKeywords) {
    state.includeKeywords = includeKeywords;
  },

  UPDATE_EXCLUDE_KEYWORDS(state, excludeKeywords) {
    state.excludeKeywords = excludeKeywords;
  },

  UPDATE_EXCLUDE_DOMAINS(state, excludeDomains) {
    state.excludeDomains = excludeDomains;
  },

  UPDATE_LIMIT_DOMAINS(state, limitDomains) {
    state.limitDomains = limitDomains;
  },

  UPDATE_REGION(state, region) {
    state.region = region;
  },

  UPDATE_LANGUAGE(state, language) {
    state.language = language;
  },

  UPDATE_POST_AGE(state, postAge) {
    state.postAge = postAge;
  },

  UPDATE_SORTING(state, sorting) {
    state.sorting = sorting;
  },

  UPDATE_FROM_AFTER_DATE(state, fromAfterDate) {
    state.fromAfterDate = fromAfterDate;
  },

  UPDATE_SELECTED_HASHTAG_PRESET(state, selectedHashtagPreset) {
    state.selectedHashtagPreset = selectedHashtagPreset;
  },

  UPDATE_RECURRING_VALUE(state, recurringValue) {
    state.recurringValue = recurringValue;
  },

  UPDATE_RECURRING_UNIT(state, recurringUnit) {
    state.recurringUnit = recurringUnit;
  },

  UPDATE_SCHEDULE_TYPE(state, scheduleType) {
    state.scheduleType = scheduleType;
  },

  UPDATE_START_DATE(state, startDate) {
    state.startDate = startDate;
  },

  UPDATE_END_DATE(state, endDate) {
    state.endDate = endDate;
  },

  UPDATE_REVIEW_POSTS(state, isReviewPosts) {
    state.reviewPosts = isReviewPosts;
  },

  UPDATE_MAX_POST_COUNT(state, maxPostCount) {
    state.maxPostCount = maxPostCount;
  },

  UPDATE_PREFETCH_DAYS_LIMIT(state, prefetchDaysLimit) {
    state.prefetchDaysLimit = prefetchDaysLimit;
  },

  UPDATE_REMOVE_EXISTING_POSTS(state, isRemoveExistingPost) {
    state.removeExistingPosts = isRemoveExistingPost;
  },

  UPDATE_SAVE_BUSY(state, isBusy) {
    state.saveBusy = isBusy;
  },

  UPDATE_LIST_SEARCH_INPUT(state, input) {
    state.listSearchInput = input;
  },

  UPDATE_LIST_SELECTED_ACCOUNT_IDS(state, accountIds) {
    state.listSelectedAccountIds = accountIds;
  },

  UPDATE_IS_PREVIEW_CONTENTS_LOADING(state, isLoading) {
    state.isPreviewContentsLoading = isLoading;
  },

  UPDATE_PREVIEW_CONTENTS(state, contents) {
    state.previewContents = contents;
  },

  UPDATE_CSV_FILE(state, csvFile) {
    state.csvFile = csvFile;
  },

  UPDATE_CSV_DATE_FORMAT(state, csvDateFormat) {
    state.csvDateFormat = csvDateFormat;
  },

  UPDATE_AI_POST(state, data) {
    const $data = data.aiFullPost.response[0];
    state.ai.context = [...(state.ai.context || []), $data.title];
    state.ai.data = $data;
  },

  SYNC(state, automation) {
    state.id = automation.id;
    state.campaignName = automation.name;
    state.selectedAccounts = automation.selectedAccounts;
    state.source = automation.source;

    state.keywords = automation.filters.keywords.map(text => ({ text }));
    state.excludeKeywords = automation.filters.excludeKeywords.map(text => ({ text }));
    state.region = automation.filters.region;
    state.language = automation.filters.language;
    state.postAge = automation.filters.postAge;
    state.fromAfterDate = moment(automation.filters.fromAfterDate).format('YYYY-MM-DD');

    state.selectedHashtagPreset = automation.hashtagPresetId;
    state.recurringValue = automation.recurringValue;
    state.recurringUnit = automation.recurringUnit;
    state.scheduleType = automation.scheduleType;

    state.startDate = {
      startDate: moment(automation.startDate).format('YYYY-MM-DD'),
    };
    state.endDate = {
      startDate: moment(automation.endDate).format('YYYY-MM-DD'),
    };

    state.reviewPosts = automation.reviewPosts;
    state.maxPostCount = automation.maxPostCount;
    state.prefetchDaysLimit = automation.prefetchDaysLimit;
    state.status = automation.status;
  },

  RESET(state) {
    for (let prop in state) {
      state[prop] = initialStateCopy[prop];
    }
  },
};

// actions
export const actions = {
  updateCurrentStep({ commit }, step) {
    commit('UPDATE_CURRENT_STEP', step);
  },

  updateAiPostPrompt({ commit }, prompt) {
    commit('UPDATE_AI_POST_PROMPT', prompt);
  },

  updateAiPostCount({ commit }, count) {
    commit('UPDATE_AI_POST_COUNT', count);
  },

  updateCampaignName({ commit }, name) {
    commit('UPDATE_CAMPAIGN_NAME', name);
  },

  updateSelectedAccounts({ commit }, accounts) {
    commit('UPDATE_SELECTED_ACCOUNTS', accounts);
  },

  updateSource({ commit }, source) {
    commit('UPDATE_SOURCE', source);
  },

  updateKeywords({ commit }, keywords) {
    commit('UPDATE_KEYWORDS', keywords);
  },

  updateExcludeKeywords({ commit }, excludeKeywords) {
    commit('UPDATE_EXCLUDE_KEYWORDS', excludeKeywords);
  },

  updateRegion({ commit }, region) {
    commit('UPDATE_REGION', region);
  },

  updateLanuage({ commit }, language) {
    commit('UPDATE_LANGUAGE', language);
  },

  updatePostAge({ commit }, postAge) {
    commit('UPDATE_POST_AGE', postAge);
  },

  updateSorting({ commit }, sorting) {
    commit('UPDATE_SORTING', sorting);
  },

  updateFromAfterDate({ commit }, fromAfterDate) {
    commit('UPDATE_FROM_AFTER_DATE', fromAfterDate);
  },

  updateSelectedHashtagPreset({ commit }, selectedHashtagPreset) {
    commit('UPDATE_SELECTED_HASHTAG_PRESET', selectedHashtagPreset);
  },

  updateRecurringValue({ commit }, recurringValue) {
    commit('UPDATE_RECURRING_VALUE', recurringValue);
  },

  updateRecurringUnit({ commit }, recurringUnit) {
    commit('UPDATE_RECURRING_UNIT', recurringUnit);
  },

  updateScheduleType({ commit }, scheduleType) {
    commit('UPDATE_SCHEDULE_TYPE', scheduleType);
  },

  updateStartDate({ commit }, startDate) {
    commit('UPDATE_START_DATE', startDate);
  },

  updateEndDate({ commit }, endDate) {
    commit('UPDATE_END_DATE', endDate);
  },

  updateReviewPosts({ commit }, isReviewPosts) {
    commit('UPDATE_REVIEW_POSTS', isReviewPosts);
  },

  updateMaxPostCount({ commit }, maxPostCount) {
    commit('UPDATE_MAX_POST_COUNT', maxPostCount);
  },

  updatePrefetchDaysLimit({ commit }, prefetchDaysLimit) {
    commit('UPDATE_PREFETCH_DAYS_LIMIT', prefetchDaysLimit);
  },

  updateRemoveExistingPosts({ commit }, isRemoveExistingPost) {
    commit('UPDATE_REMOVE_EXISTING_POSTS', isRemoveExistingPost);
  },

  async generatePost({ commit, state }, { message, postType }) {
    commit('UPDATE_SAVE_BUSY', true);

    return apolloClient
      .mutate({
        mutation: AI_AUTOMATED_POST_MESSAGE_MUTATION,
        variables: {
          message: message,
          postType: postType,
          context: state.ai.context,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_AI_POST', data);
      })
      .catch(() => {
        commit('UPDATE_SAVE_BUSY', false);
      });
  },

  async generatePostX({ commit, state }, { message, postType }) {
    const worker = new Worker('/worker.js');
    const proxy = Comlink.wrap(worker);
    // WebWorkers use `postMessage` and therefore work with Comlink.
    const API = await new proxy.Fetch();

    API.setBaseUrl(`${process.env.VUE_APP_API_ROOT}`);
    API.setDefaultHeaders({
      'Content-Type': 'application/json',
      authorization: this.token ? `Bearer ${this.token}` : null,
    });

    API.setDefaultBody({
      query: `
      mutation AiPost($message: String!, $postType: String!, $context: [AiPostContextInput]) {
        aiPost(message: $message, postType: $postType, context: $context) {
          response
        }
      }
      `,
      variables: {
        message,
        postType,
        context: state.ai.context || [],
      },
    });

    // API.get('users/1');
    const response = await API.post('/gql');
    commit('UPDATE_AI_POST', response.aiFullPost);
  },

  saveAutomation({ commit, state }, { workspace, type, recipe }) {
    commit('UPDATE_SAVE_BUSY', true);

    return apolloClient
      .mutate({
        mutation: SAVE_AUTOMATION_MUTATION,
        variables: {
          workspace,
          id: state.id,
          type,
          recipe,
          name: state.campaignName,
          accounts: state.selectedAccounts,
          source: state.source,
          hashtagPreset: state.selectedHashtagPreset,
          aiPostPrompt: state.aiPostPrompt,
          recurringValue: state.recurringValue,
          recurringUnit: state.recurringUnit,
          scheduleType: state.scheduleType,
          startDate: state.startDate.startDate,
          endDate: state.endDate.startDate,
          reviewPosts: state.reviewPosts,
          removePosts: state.removeExistingPosts,
          maxPostCount: state.maxPostCount,
          prefetchDaysLimit: state.prefetchDaysLimit,
          filters: {
            keywords: state.keywords.map(t => t.text),
            excludeKeywords: state.excludeKeywords.map(t => t.text),
            sort: state.sorting,
            region: state.region,
            language: state.language,
            postAge: state.postAge,
            fromAfterDate: state.fromAfterDate.startDate,
          },
          status: state.status,
          csvFile: state.csvFile,
          csvDateFormat: state.csvDateFormat,
          count_per_request: state.count_per_request,
        },

        update: (store, { data: { saved } }) => {
          try {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: AUTOMATIONS_QUERY,
              variables: {
                workspace,
                type,
                recipe,
                search: state.listSearchInput,
                accountIds: state.listSelectedAccountIds,
              },
            });
            if (state.id === 0) {
              // Add saved automation from the mutation to the beginning.
              data.automations.unshift(saved);
            } else {
              const index = data.automations.findIndex(auto => auto.id === saved.id);
              if (index !== -1) {
                data.automations.splice(index, 1, saved);
              }
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: AUTOMATIONS_QUERY,
              variables: {
                workspace,
                type,
                recipe,
                search: state.listSearchInput,
                accountIds: state.listSelectedAccountIds,
              },
              data,
            });
          } catch (error) {
            // Query is probably not initialized,
            // and therefore not in the cache
          }
        },
      })
      .then(({ data }) => {
        commit('UPDATE_SAVE_BUSY', false);

        return data.saved;
      })
      .catch(error => {
        commit('UPDATE_SAVE_BUSY', false);

        throw new Error(error.message);
      });
  },

  sync({ commit }, post) {
    commit('SYNC', post);
  },

  updateListSearchInput({ commit }, input) {
    commit('UPDATE_LIST_SEARCH_INPUT', input);
  },

  updateListSelectedAccounts({ commit }, ids) {
    commit('UPDATE_LIST_SELECTED_ACCOUNT_IDS', ids);
  },

  updatePreviewContents({ commit }, contents) {
    commit('UPDATE_PREVIEW_CONTENTS', contents);
  },

  updateIsPreviewContentsLoading({ commit }, isLoading) {
    commit('UPDATE_IS_PREVIEW_CONTENTS_LOADING', isLoading);
  },

  updateCsvFile({ commit }, csvFile) {
    commit('UPDATE_CSV_FILE', csvFile);
  },

  updateCsvDateFormat({ commit }, csvDateFormat) {
    commit('UPDATE_CSV_DATE_FORMAT', csvDateFormat);
  },

  reset({ commit }) {
    commit('RESET');
  },
};
