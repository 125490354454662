const plans = [
  {
    id: 'pro_yearly',
    name: 'Professional',
    nickname: 'Professional Yearly',
    amount: 29 * 12,
    amount_per_month: 29,
    interval: 'year',
    limits: {
      workspaces: 1,
      socialAccounts: 10,
      blogs: 1,
      members: 0,
      automations: 10,
      topics: '*',
      socialPostsPerDay: '*',
      blogPostsPerDay: '*',
      searchesPerDay: '*',
    },
  },
  {
    id: 'pro_monthly',
    name: 'Professional',
    nickname: 'Professional Monthly',
    amount: 39,
    amount_per_month: 39,
    interval: 'month',
    limits: {
      workspaces: 1,
      socialAccounts: 10,
      blogs: 1,
      members: 0,
      automations: 10,
      topics: '*',
      socialPostsPerDay: '*',
      blogPostsPerDay: '*',
      searchesPerDay: '*',
    },
  },
  {
    id: 'small_biz_yearly',
    name: 'Small Business & Agency',
    nickname: 'Small Business Yearly',
    amount: 69 * 12,
    amount_per_month: 69,
    interval: 'year',
    limits: {
      workspaces: 5,
      socialAccounts: 25,
      blogs: 10,
      members: 5,
      automations: '*',
      topics: '*',
      socialPostsPerDay: '*',
      blogPostsPerDay: '*',
      searchesPerDay: '*',
    },
  },
  {
    id: 'small_biz_monthly',
    name: 'Small Business & Agency',
    nickname: 'Small Business Monthly',
    amount: 79,
    amount_per_month: 79,
    interval: 'month',
    limits: {
      workspaces: 5,
      socialAccounts: 25,
      blogs: 10,
      members: 5,
      automations: '*',
      topics: '*',
      socialPostsPerDay: '*',
      blogPostsPerDay: '*',
      searchesPerDay: '*',
    },
  },
  {
    id: 'medium_biz_yearly',
    name: 'Medium Business & Agency',
    nickname: 'Medium Business Yearly',
    amount: 129 * 12,
    amount_per_month: 129,
    interval: 'year',
    limits: {
      workspaces: 10,
      socialAccounts: 50,
      blogs: 20,
      members: 10,
      automations: '*',
      topics: '*',
      socialPostsPerDay: '*',
      blogPostsPerDay: '*',
      searchesPerDay: '*',
    },
  },
  {
    id: 'medium_biz_monthly',
    name: 'Medium Business & Agency',
    nickname: 'Medium Business Monthly',
    amount: 159,
    amount_per_month: 159,
    interval: 'month',
    limits: {
      workspaces: 10,
      socialAccounts: 50,
      blogs: 20,
      members: 10,
      automations: '*',
      topics: '*',
      socialPostsPerDay: '*',
      blogPostsPerDay: '*',
      searchesPerDay: '*',
    },
  },
  {
    id: 'large_biz_yearly',
    name: 'Large Business & Agency',
    nickname: 'Large Business Yearly',
    amount: 219 * 12,
    amount_per_month: 219,
    interval: 'year',
    limits: {
      workspaces: 20,
      socialAccounts: 150,
      blogs: 50,
      members: 20,
      automations: '*',
      topics: '*',
      socialPostsPerDay: '*',
      blogPostsPerDay: '*',
      searchesPerDay: '*',
    },
  },
  {
    id: 'large_biz_monthly',
    name: 'Large Business & Agency',
    nickname: 'Large Business Monthly',
    amount: 239,
    amount_per_month: 239,
    interval: 'month',
    limits: {
      workspaces: 20,
      socialAccounts: 150,
      blogs: 50,
      members: 20,
      automations: '*',
      topics: '*',
      socialPostsPerDay: '*',
      blogPostsPerDay: '*',
      searchesPerDay: '*',
    },
  },
];

// state
export const state = {
  plans,
  stripeLoaded: null,
};

// getters
export const getters = {};

// mutations
export const mutations = {
  SET_STRIPE_LOADED(state, isLoaded) {
    state.stripeLoaded = isLoaded;
  },
};

// actions
export const actions = {
  updateStripeLoaded({ commit }, isLoaded) {
    commit('SET_STRIPE_LOADED', isLoaded);
  },
};
