// state
export const state = {
  modified: false,
  template: null,
  invokedCategory: null,
  quote: null,
  preset: null,
};

// getters
export const getters = {
  getPreset: state => state.preset,
};

// mutations
export const mutations = {
  UPDATE_MODIFIED(state, modified) {
    state.modified = modified;
  },
  UPDATE_TEMPLATE(state, template) {
    state.template = template;
  },
  INVOKE_CATEGORY(state, category) {
    state.invokedCategory = category;
  },
  UPDATE_QUOTE(state, quote) {
    state.quote = quote;
  },
  UPDATE_PRESET(state, preset) {
    state.preset = preset;
  },
};

// actions
export const actions = {
  updateIsModified({ commit }, isModified) {
    commit('UPDATE_MODIFIED', isModified);
  },
  updateTemplate({ commit }, template) {
    commit('UPDATE_TEMPLATE', template);
  },
  invokeCategory({ commit }, category) {
    commit('INVOKE_CATEGORY', category);
  },
  updateQuote({ commit }, quote) {
    commit('UPDATE_QUOTE', quote);
  },
  updatePreset({ commit }, preset) {
    commit('UPDATE_PRESET', preset);
  },
};
