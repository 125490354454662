<template>
  <b-modal id="LinkedinOrgsModal" centered no-close-on-backdrop no-close-on-esc hide-header hide-footer>
    <div v-if="loading" class="bg-white text-center p-5"><spinner /></div>
    <template v-else>
      <h4>Select Linkedin Organisations</h4>
      <table v-if="orgs && orgs.length > 0" class="table table-borderless striped ">
        <thead>
          <tr>
            <th>Name</th>
            <th>Connect</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="org in orgs" :key="org.id">
            <td class="d-flex align-items-center">
              <img class="mr-2" :src="org.imageUrl" alt="" style="width: 40px; height: 40px;" /> {{ org.name }}
            </td>
            <td class="text-right">
              <div v-if="!org.connected" class="custom-control custom-switch">
                <input
                  v-model="selectedOrgs"
                  :value="org.id"
                  type="checkbox"
                  class="custom-control-input"
                  :id="`ocheck#${org.id}`"
                />
                <label class="custom-control-label" :for="`ocheck#${org.id}`"></label>
              </div>
              <b-badge v-else variant="success" class="px-2">Connected</b-badge>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-3 text-right">
        <b-button variant="clear" size="sm" @click="cancel" class="px-3 mr-2"> Cancel </b-button>
        <b-button variant="primary" size="sm" @click="saveSelectedLinkedinOrgs" class="px-3 mr-4">
          <b-spinner v-if="savingOrgs" small />
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { SAVE_SELECTED_LINKEDIN_ORGS_MUTATION } from '~/graphql/mutations';

export default {
  name: 'connect-linkedin-orgs-modal',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    orgs: {
      type: Array,
      default: () => [],
    },

    accountId: {
      required: true,
    },

    queryType: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      selectedOrgs: [],
      savingOrgs: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  methods: {
    saveSelectedLinkedinOrgs() {
      const limit = parseInt(this.workspace.limits.socialAccounts || '');
      const limitCount = isNaN(limit) ? Infinity : limit;
      const usedCount = parseInt(this.workspace.limitsUsage.socialAccounts || '');
      const remainder = limitCount - usedCount;
      const total = this.selectedOrgs.length;

      if (total > remainder) {
        return this.$notify({
          group: 'main',
          title: 'Please Upgrade',
          type: 'native-error',
          text: `You have reached the maximum number of social accounts allowed in your current plan. You can only add ${remainder} more social accounts.`,
        });
      }

      this.savingOrgs = true;
      return this.$apollo
        .mutate({
          mutation: SAVE_SELECTED_LINKEDIN_ORGS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            accountId: String(this.accountId),
            orgIds: this.selectedOrgs.map(id => String(id)),
          },
          update: (store, { data: { savedAccounts } }) => {
            if (savedAccounts) {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  type: this.queryType,
                },
              });
              data.publishAccounts.push(...savedAccounts);

              // Write our data back to the cache.
              store.writeQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  type: this.queryType,
                },
                data,
              });
            }
          },
        })
        .then(() => {
          const count = parseInt(this.workspace.limitsUsage.socialAccounts);
          this.$store.dispatch('workspace/updateCurrent', {
            ...this.workspace,
            limitsUsage: {
              ...this.workspace.limitsUsage,
              socialAccounts: count + total,
            },
          });

          this.savingOrgs = false;
          this.hide();

          this.$emit('done');
        })
        .catch(() => {
          this.savingOrgs = false;

          Swal.fire({
            type: 'error',
            title: 'OOPS',
            text: 'An error occurred. Please try again.',
          });
        });
    },

    cancel() {
      this.$emit('cancel');
      this.$root.$emit('bv::hide::modal', 'LinkedinOrgsModal');
    },

    hide() {
      this.$root.$emit('bv::hide::modal', 'LinkedinOrgsModal');
    },
  },
};
</script>
