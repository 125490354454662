<template>
  <b-modal id="TumblrBlogsModal" centered no-close-on-backdrop no-close-on-esc hide-header hide-footer>
    <div v-if="loading" class="bg-white text-center p-5"><spinner /></div>
    <div v-else-if="blogs && blogs.length > 0" class="p-3">
      <h5 class="font-weight-bold mb-4">Connect Tumblr</h5>
      <h6 class="font-weight-bold">Profile</h6>
      <table class="table table-borderless striped">
        <tbody>
          <tr v-for="profile in profiles" :key="profile.id">
            <td class="d-flex align-items-center">
              <img class="mr-2" :src="profile.imageUrl" alt="" style="width: 40px; height: 40px;" /> {{ profile.name }}
            </td>
            <td class="text-right">
              <div v-if="!profile.connected" class="custom-control custom-switch">
                <input
                  v-model="selectedBlogs"
                  :value="profile.id"
                  type="checkbox"
                  class="custom-control-input"
                  :id="`pcheck#${profile.id}`"
                />
                <label class="custom-control-label" :for="`pcheck#${profile.id}`"></label>
              </div>
              <b-badge v-else variant="success" class="px-2">Connected</b-badge>
            </td>
          </tr>
        </tbody>
      </table>

      <h6 class="font-weight-bold">Blogs</h6>
      <table class="table table-borderless striped">
        <tbody>
          <tr v-for="blog in computedBlogs" :key="blog.id">
            <td class="d-flex align-items-center">
              <img class="mr-2" :src="blog.imageUrl" alt="" style="width: 40px; height: 40px;" /> {{ blog.name }}
            </td>
            <td class="text-right">
              <div v-if="!blog.connected" class="custom-control custom-switch">
                <input
                  v-model="selectedBlogs"
                  :value="blog.id"
                  type="checkbox"
                  class="custom-control-input"
                  :id="`pcheck#${blog.id}`"
                />
                <label class="custom-control-label" :for="`pcheck#${blog.id}`"></label>
              </div>
              <b-badge v-else variant="success" class="px-2">Connected</b-badge>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-3 text-right">
        <b-button variant="clear" size="sm" @click="cancel" class="px-3 mr-2"> Cancel </b-button>
        <b-button variant="primary" size="sm" @click="saveSelectedTumblrBlogs" class="px-3">
          <b-spinner v-if="savingBlogs" small />
          Save
        </b-button>
      </div>
    </div>
    <div v-else class="p-5 pb-2">
      <h3>Your Tumblr account has been connected successfully!</h3>
      <h5>But you don't seem to have any Tumblr "Blogs".</h5>
      <p>
        For now SocialMediaAgency360 can post directly to your Tumblr account. When you add blogs, click on the "Tumblr"
        button again to connect them to SocialMediaAgency360.
      </p>
      <div class="text-right mt-3">
        <b-button variant="primary" size="sm" @click="finish" class="px-4 mr-2"> Ok </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { SAVE_SELECTED_TUMBLR_BLOGS_MUTATION } from '~/graphql/mutations';

export default {
  name: 'connect-medium-blogs-modal',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    blogs: {
      type: Array,
      default: () => [],
    },

    accountId: {
      required: true,
    },

    queryType: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      selectedBlogs: [],
      savingBlogs: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    profiles() {
      return this.blogs.filter(b => b.profile === true);
    },

    computedBlogs() {
      return this.blogs.filter(b => b.profile === false);
    },
  },

  methods: {
    saveSelectedTumblrBlogs() {
      this.savingBlogs = true;

      return this.$apollo
        .mutate({
          mutation: SAVE_SELECTED_TUMBLR_BLOGS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            accountId: String(this.accountId),
            blogIds: this.selectedBlogs.map(id => String(id)),
          },
          update: (store, { data: { savedAccounts } }) => {
            if (savedAccounts) {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: { workspace: this.workspace.id, type: this.queryType },
              });

              // So we don't push a blog account to the list of social accounts
              const filteredAccounts = savedAccounts.filter(a => a.type.platformType === this.queryType);

              data.publishAccounts.push(...filteredAccounts);

              // Write our data back to the cache.
              store.writeQuery({
                query: PUBLISH_ACCOUNTS_QUERY,
                variables: { workspace: this.workspace.id, type: this.queryType },
                data,
              });
            }
          },
        })
        .then(() => {
          this.savingBlogs = false;

          this.finish();
        })
        .catch(() => {
          this.savingBlogs = false;

          Swal.fire({
            type: 'error',
            title: 'OOPS',
            text: 'An error occurred. Please try again.',
          });
        });
    },

    cancel() {
      this.$emit('cancel');
      this.$root.$emit('bv::hide::modal', 'TumblrBlogsModal');
    },

    hide() {
      this.$root.$emit('bv::hide::modal', 'TumblrBlogsModal');
    },

    finish() {
      this.hide();
      this.$emit('done');
    },
  },
};
</script>
