<template>
  <section class="error-page">
    <slot> <router-view /> </slot>
  </section>
</template>

<script>
export default {
  name: 'ErrorLayout',
};
</script>
