import { stripTags } from '~/utils/helpers';
import { isEmpty, omit, capitalize, isString, isArray } from 'lodash';

// state
export const state = {
  errors: {},
  warnings: {},
};

const initialStateCopy = JSON.parse(JSON.stringify(state));

// getters
export const getters = {
  accountErrors: state => accountId => {
    return state.errors[accountId].map(er => er.message);
  },
  hasError: state => (accountId = false) => {
    if (accountId) {
      return state.errors[accountId] && state.errors[accountId].length;
    }

    const truth = Object.keys(state.errors).map(id => {
      return Boolean(state.errors[id].length);
    });
    return truth.find(isTrue => isTrue === true);
  },
  accountWarnings: state => accountId => {
    return state.warnings[accountId].map(er => er.message);
  },
  hasWarning: state => (accountId = false) => {
    if (accountId) {
      return state.warnings[accountId] && state.warnings[accountId].length;
    }

    const truth = Object.keys(state.warnings).map(id => {
      return Boolean(state.warnings[id].length);
    });
    return truth.find(isTrue => isTrue === true);
  },
};

// mutations
export const mutations = {
  ADD_OR_DROP_ERROR(state, { validation, accountId, field, key, message }) {
    const errorEntry = state.errors[accountId];
    const keyExists = errorEntry && errorEntry.find(er => er.field == field && er.key == key);

    if ((validation && !keyExists) || (validation && !errorEntry)) {
      const errorItem = { field, key, message };

      state.errors = {
        ...state.errors,
        [accountId]: errorEntry ? [...errorEntry, errorItem] : [errorItem],
      };
    } else if (!validation && keyExists) {
      const index = errorEntry.findIndex(er => er.field == field && er.key == key);
      const errors = [...errorEntry.slice(0, index), ...errorEntry.slice(index + 1)];

      if (errors.length) {
        state.errors = {
          ...state.errors,
          [accountId]: errors,
        };
      } else {
        // Remove the key from errors
        state.errors = omit(state.errors, accountId);
      }
    }
  },

  ADD_OR_DROP_WARNING(state, { validation, accountId, field, key, message }) {
    const errorEntry = state.warnings[accountId];
    const keyExists = errorEntry && errorEntry.find(er => er.field == field && er.key == key);

    if ((validation && !keyExists) || (validation && !errorEntry)) {
      const errorItem = { field, key, message };

      state.warnings = {
        ...state.warnings,
        [accountId]: errorEntry ? [...errorEntry, errorItem] : [errorItem],
      };
    } else if (!validation && keyExists) {
      const index = errorEntry.findIndex(er => er.field == field && er.key == key);
      const warnings = [...errorEntry.slice(0, index), ...errorEntry.slice(index + 1)];

      if (warnings.length) {
        state.warnings = {
          ...state.warnings,
          [accountId]: warnings,
        };
      } else {
        // Remove the key from warnings
        state.warnings = omit(state.warnings, accountId);
      }
    }
  },

  DROP_ERROR(state, accountId) {
    // Remove the key from errors
    state.errors = omit(state.errors, accountId);
  },

  DROP_WARNING(state, accountId) {
    // Remove the key from warnings
    state.warnings = omit(state.warnings, accountId);
  },

  RESET(state) {
    for (let prop in state) {
      state[prop] = initialStateCopy[prop];
    }
  },
};

// actions
export const actions = {
  validate({ commit }, { account, postContent }) {
    const accountRules = account.type.rules;

    if (accountRules['__typename']) {
      delete accountRules['__typename'];
    }

    Object.keys(accountRules).forEach(field => {
      if (field == 'id') return; // Continue

      const accountRule = accountRules[field];

      if (!accountRule) {
        return; // Continue from next iteration
      }
      const fieldRules = accountRule.split('|');

      fieldRules.forEach(feildRule => {
        const ruleParts = feildRule.split(':');
        const ruleName = ruleParts[0];
        const ruleValue = ruleParts[1];

        switch (ruleName) {
          case 'required': {
            commit('ADD_OR_DROP_ERROR', {
              validation: isEmpty(postContent[field]) || isEmpty(stripTags(postContent[field])),
              accountId: account.id,
              field,
              key: 'required',
              message: `${capitalize(field)} is required`,
            });
            break;
          }
          case 'max': {
            const isValidType = isString(postContent[field]) || isArray(postContent[field]);

            commit('ADD_OR_DROP_ERROR', {
              validation: isValidType && postContent[field].length > Number(ruleValue),
              accountId: account.id,
              field,
              key: 'max',
              message: `${capitalize(field)} must not exceed ${ruleValue}${
                isString(postContent[field])
                  ? ' characters'
                  : ` item(s) on a ${capitalize(account.type.platform)} post.`
              }`,
            });
            break;
          }
          case 'twitter_attachment': {
            const hasAttachments = postContent.attachments && postContent.attachments.length;
            const hasGif = hasAttachments && postContent.attachments.find(attachment => attachment.type === 'gif');
            const hasVideo = hasAttachments && postContent.attachments.find(attachment => attachment.type === 'video');

            commit('ADD_OR_DROP_ERROR', {
              validation:
                (hasGif && postContent.attachments.length > 1) || (hasVideo && postContent.attachments.length > 1),
              accountId: account.id,
              field,
              key: 'twitter_attachment',
              message: `Twitter only allows adding up to 4 photos OR 1 animated GIF Or 1 video in a Tweet.`,
            });
            break;
          }
          case 'facebook_attachment': {
            const hasAttachments = postContent.attachments && postContent.attachments.length;
            const hasVideo = hasAttachments && postContent.attachments.find(attachment => attachment.type === 'video');

            commit('ADD_OR_DROP_ERROR', {
              validation: hasVideo && postContent.attachments.length > 1,
              accountId: account.id,
              field,
              key: 'facebook_attachment',
              message: `You can only add 1 video in a Facebook post, and you cannot add a video along with photos.`,
            });
            break;
          }
          case 'linkedin_attachment':
          case 'pinterest_attachment': {
            const hasAttachments = postContent.attachments && postContent.attachments.length;
            const hasVideo = hasAttachments && postContent.attachments.find(attachment => attachment.type === 'video');

            commit('ADD_OR_DROP_ERROR', {
              validation: hasVideo,
              accountId: account.id,
              field,
              key: 'video_not_supported',
              message: `Videos posts are not supported for ${capitalize(account.type.platform)}.`,
            });
            break;
          }
          case 'gifs_not_animated': {
            const hasAttachments = postContent.attachments && postContent.attachments.length;
            const hasGif = hasAttachments && postContent.attachments.find(attachment => attachment.type === 'gif');

            commit('ADD_OR_DROP_WARNING', {
              validation: hasGif,
              accountId: account.id,
              field,
              key: 'facebook_attachment',
              message: `Note that ${capitalize(
                account.type.platform,
              )} does not support animated GIFs. Your GIF(s) will loose its animations when published on ${capitalize(
                account.type.platform,
              )}.`,
            });
            break;
          }
        }
      });
    });
  },

  dropError({ commit }, accountId) {
    commit('DROP_ERROR', accountId);
  },
  dropWarning({ commit }, accountId) {
    commit('DROP_WARNING', accountId);
  },
  reset({ commit }) {
    commit('RESET');
  },
};
