<template>
  <svg width="20" height="22" viewBox="0 0 20 22">
    <path
      d="M8 15h10v2H8zM8 9h10v2H8zM8 3h10v2H8zM3 2v1h1v3h1V2zM6 12H3.6v-1l2-2H3V8h3v1l-1.4 2H6v1zM5.8 17l-1.2-1 1.2-1H3v-1h3v1l-1.2 1L6 17v1H3v-1h2.8z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'text-list-numbers',
};
</script>
